import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from '../shared/services/alert.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class CustomErrorHandler implements ErrorHandler {

  constructor(
    private router: Router,
    private alertService: AlertService
  ) { }

  handleError(error: any): void {
    // Log the error to the console.
    console.error(error);

    
    this.alertService.error((error.Message || 'An unexpected error occurred. Please try again later.'));
  }
}
