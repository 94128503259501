import {
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth';
import {
  PatientModel,
  PatientProgramModel,
  UserModel,
} from '../core/api/models';
import { HealthCheckApiService } from '../core/api/services/health-check-api.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { StaffApiService, SupervisorApiService } from '../core/api/services';
import { AlertService } from '../shared/services/alert.service';
import { Utils } from '../core/utils';
import { PatientSessionService } from '../staff/home/patient-session.service';
import { SettingsHelperService } from './settings.helper.service';

@Component({
  selector: 'settings-main',
  templateUrl: './settings-main.component.html',
  styleUrl: './settings-main.component.css',
})
export class SettingsMainComponent implements OnInit {

  userInfo: UserModel | null = null;
  pageTitle: string | null = null;
  loading: boolean = true;
  patientId: number | null = null;
  openMenu: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    protected settingsHelperService: SettingsHelperService,
    protected patientSessionService: PatientSessionService,
  ) { 
    console.log('SettingsMain PatientSessionService instance:', this.patientSessionService);
  }

  ngOnInit() {
    this.authService.user().subscribe((user) => (this.userInfo = user));
    if (!this.patientSessionService.patientID()) {
      this.patientSessionService.initializePatientSession();
    }

    this.patientId = this.patientSessionService.patientID();
    this.loading = false;
  }

  isRouteActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  switchToStaff() {
    const patientSession = this.patientSessionService.patientSession();
    this.router.navigate(['/staff/home/'+this.patientId+'/'+patientSession?.ID]);
  }

  switchToGraphs() {
    this.router.navigate(['/graphs', this.patientId]);
  }

  activated(comp: any) {
    this.pageTitle = comp.pageTitle || '&nbsp;';
    if (comp instanceof SettingsMainComponent) {
      let tComp = comp as SettingsMainComponent;
      tComp.userInfo = this.userInfo;
    }
  }

  backToOpto() {
    window.location.href = this.authService.getOptoURL()??'/';
  }
  
  switchToSupervisor() {
    this.router.navigate(['/supervisor/' + this.patientId]);
  }


}
