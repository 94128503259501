
<button (click)="addSkillAreaButtonClicked()" class="side-menu-button2 w-100">
  + Skill Area
</button>
<ul id="skillAreas">
  @if (loadingSkillAreas) {
  <app-loading-indicator />
  } @else { @for (skillArea of skillAreas; track skillArea.ID) {
  <app-skill-area [skillArea]="skillArea" />
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no Skill Areas yet.
  </p>
  } }
</ul>
