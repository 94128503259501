import { Component, Input } from '@angular/core';
import { PromptCodeModel, TargetType } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SettingsHelperService } from '../../settings.helper.service';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-prompt-code',
  templateUrl: './prompt-code.component.html',
})
export class PromptCodeComponent {
  @Input() promptCode: PromptCodeModel | undefined;
  targetTypes: string = "";

  constructor(
    private supervisorApiService: SupervisorApiService,
    private settingsHelperService: SettingsHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    if (this.promptCode != null) {
      this.targetTypes = this.promptCode?.TargetTypeIDs?.map((id) => TargetType[id]).join(', ')??"";
    }
  }
  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editPromptCode() {
    console.log('editPromptCode clicked!');
    this.settingsHelperService.addPromptCodeButtonClicked(
      this.promptCode ?? null
    );
  }

  deletePromptCode() {
    console.log('deletePromptCode clicked!');

    this.alertService
      .confirm(
        'Delete Prompt Code',
        'Are you sure you want to delete this prompt code?'
      )
      .subscribe((result) => {
        if (result) {
          this.deletePromptCodeConfirmed();
        }
      });
  }

  deletePromptCodeConfirmed() {
    if (this.promptCode?.ID != null) {
      this.supervisorApiService
        .DeletePromptCode({ promptCodeID: this.promptCode.ID })
        .subscribe(
          () => {
            this.alertService.success('Prompt code deleted successfully');
            this.settingsHelperService.onCloseAddPromptCode();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }


}
