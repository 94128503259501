import {
  RouterModule,
  Routes,
  withComponentInputBinding,
} from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { NgModule } from '@angular/core';

import { SupervisorMainComponent } from './supervisor-main.component';
import { ProgramsComponent } from './programs/programs.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { TargetsComponent } from './targets/targets.component';
import { SkillAreasComponent } from './skill-areas/skill-areas.component';
import { RemindersComponent } from './reminders/reminders.component';
import { BehaviorsComponent } from './behaviors/behaviors.component';
import { PromptCodesPatientComponent } from './prompt-codes/prompt-codes-patient.component';
import { PromptSchedulesPatientComponent } from './prompt-schedules/prompt-schedules-patient.component';
import { MaintananceCriteriaPatientComponent } from './maintanance-criteria/maintanance-criteria-patient.component';
import { MasteryCriteriaComponent } from '../settings/mastery-criteria/mastery-criteria.component';
import { ProbeCriteriaComponent } from '../settings/probe-criteria/probe-criteria.component';
import { AntecedentsComponent } from './abc/antecendents/antecedents.component';
import { ConsequencesComponent } from './abc/consequences/consequences.component';
const routes: Routes = [
  {
    path: 'supervisor',
    redirectTo: 'supervisor/:patientId',
    pathMatch: 'full',
  },

  {
    path: 'supervisor/:patientId',
    redirectTo: 'supervisor/:patientId/programs',
    pathMatch: 'full',
  },
  {
    path: 'supervisor/:patientId',
    component: SupervisorMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'programs',
        component: ProgramsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'playlists',
        component: PlaylistsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'targets',
        component: TargetsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reminders',
        component: RemindersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'behaviors',
        component: BehaviorsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'skillAreas',
        component: SkillAreasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'prompt-codes',
        component: PromptCodesPatientComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'prompt-schedules',
        component: PromptSchedulesPatientComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'maintanance-criteria',
        component: MaintananceCriteriaPatientComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mastery-criteria',
        component: MasteryCriteriaComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'probe-criteria',
        component: ProbeCriteriaComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'antecedents',
        component: AntecedentsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'consequences',
        component: ConsequencesComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class SupervisorRoutingModule {}
