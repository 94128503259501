import { NgModule, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth.guard';
import { StaffHomeComponent } from './staff';

const routes: Routes = [

  { path: '', redirectTo: 'staff/home', pathMatch: 'full' },  
  //{ path: '', redirectTo: 'signin', pathMatch: 'full' }
  /*
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  */

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {

    initialNavigation: 'enabledNonBlocking',
    //enableTracing: isDevMode()
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
