import { PatientTargetStatus, TargetType } from '../../core/api/models';

export function getPatientTargetStatuses() {
  const targetStatuses = [
    { value: PatientTargetStatus.Unspecified, text: 'Unspecified' },
    { value: PatientTargetStatus.Ready, text: 'Ready' },
    { value: PatientTargetStatus.Probe, text: 'Probe' },
    { value: PatientTargetStatus.Failed_Probe, text: 'Failed Probe' },
    { value: PatientTargetStatus.Aquisition, text: 'Aquisition' },
    { value: PatientTargetStatus.Mastered, text: 'Mastered' },
    { value: PatientTargetStatus.Maintenance, text: 'Maintenance' },
    { value: PatientTargetStatus.Failed_Maintenance, text: 'Failed Maintenance' },
    { value: PatientTargetStatus.Closed, text: 'Closed' },
    
  ];
  return targetStatuses;
}

export function getPatientTargetStatusLabel(targetStatus: PatientTargetStatus) {
  const targetStatuses = getPatientTargetStatuses();
  const targetStatusResult = targetStatuses.find(ts => ts.value === targetStatus);
  return targetStatusResult?.text ?? 'Unspecified';
}

export function getPatientTargetStatusColor(targetStatus: PatientTargetStatus) {
    switch (targetStatus) {
        case PatientTargetStatus.Mastered:
            return '#8AC379';
        case PatientTargetStatus.Failed_Probe:
            return '#C15757';
        case PatientTargetStatus.Failed_Maintenance:
            return '#C15757';
        case PatientTargetStatus.Ready:
            return '#A7B8D6';
        case PatientTargetStatus.Probe:
            return '#DFE2AF';
        case PatientTargetStatus.Aquisition:
            return '#CC9E7F';
        case PatientTargetStatus.Maintenance:
            return '#a7d6d1';
        case PatientTargetStatus.Closed:
            return '#808080';
        case PatientTargetStatus.Unspecified:
          return '#CCCCCC';
    }
    return '#FFFFFF';
}

// Extend the Date prototype
declare global {
  interface Date {
    toServerString(): string;
  }
}

Date.prototype.toServerString = function (): string {
  //Convert the date to the server timezone (which is mountain time) and then to iso string
  const date = new Date(this);
  const serverTimezoneOffset = -7 * 60;
  const localTimezoneOffset = date.getTimezoneOffset();
  const diffTimezoneOffset = serverTimezoneOffset - localTimezoneOffset;
  date.setMinutes(date.getMinutes() + diffTimezoneOffset);
  return date.toISOString().replace('Z', '');
};
