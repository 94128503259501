@if (isCollapsed) {
<div class="container" (click)="toggleCollapsed()">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ consequence?.PatientBehaviorConsequence }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
      >
        {{ consequence?.PatientBehaviorConsequence }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    <div class="col-8">
      <div class="side-menu-buttons-horizontal">
        <button (click)="editConsequence()" class="side-menu-button2">
          Edit Consequence
        </button>
        <button (click)="deleteConsequence()" class="switch-mode-button">
          Delete Consequence
        </button>
      </div>
    </div>
  </div>

</div>
}
