
<button (click)="addAntecedentButtonClicked()" class="side-menu-button2 w-100 ">
  + Antecedent
</button>
<ul id="skillAreas">
  @if (loadingAntecedents) {
  <app-loading-indicator />
  } @else { @for (antecedent of antecedents; track antecedent.ID) {
  <app-antecedent [antecedent]="antecedent"></app-antecedent>
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no Antecedents yet.
  </p>
  } }
</ul>
@if (showAddAntecedent()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddAntecedent()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-antecedent [patientId]="patientId()" (close)="onCloseAddAntecedent()" />
  </dialog>
  }