import { Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import {
  PatientBehaviorDataModel,
  PatientModel,
  PatientSessionModel,
  PatientTargetDataModel,
  PatientTargetModel,
  UserModel,
} from '../../core/api/models';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth';
import {
  HealthCheckApiService,
  SupervisorApiService,
  StaffApiService,
} from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SupervisorHelperService } from '../../supervisor/supervisor.helper.service';
import { PatientSessionService } from '../home/patient-session.service';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrl: './sessions.component.css',
})
export class SessionsComponent implements OnInit {
  patientId = input.required<string>();

  private destroyRef = inject(DestroyRef);

  selectedPatient: PatientModel | null = null;

  sessions: PatientSessionModel[] = [];

  targets: PatientTargetModel[] = [];

  userInfo: UserModel | null = null;

  isLoading: boolean = true;
  openMenu: boolean = false;

  clonedSessions: { [s: number]: PatientSessionModel } = {};
  clonedSessionData: {
    [s: number]: PatientTargetDataModel | PatientBehaviorDataModel;
  } = {};

  sessionBeingEdited: PatientSessionModel | null = null;
  sessionIndexBeingEdited: number | null = null;

  constructor(
    private authService: AuthService,
    private healthCheckApiService: HealthCheckApiService,
    private router: Router,
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService,
    private activatedRoute: ActivatedRoute,
    protected patientSessionService: PatientSessionService,
    private staffApiService: StaffApiService,
    private datePipe: DatePipe
  ) {
    console.log(
      'SupervisorMain PatientSessionService instance:',
      this.patientSessionService
    );
  }

  ngOnInit() {
    this.isLoading = true;

    this.authService.user().subscribe((user) => (this.userInfo = user));

    const subscription = this.activatedRoute.params.subscribe((params) => {
      this.patientId = params['patientId'];

      const getPatientObservable = this.staffApiService.GetPatientInfo({
        id: Number(this.patientId),
      });

      const getSessionsObservable = this.staffApiService.GetPatientSessions({
        patientID: Number(this.patientId),
      });

      const getTargetsObservable = this.supervisorApiService.GetPatientTargets({
        patientID: Number(this.patientId),
      });

      const getDataObservables: Observable<
        PatientModel | PatientSessionModel[] | PatientTargetModel[]
      >[] = [getPatientObservable, getSessionsObservable, getTargetsObservable];

      const wrappedCalls = getDataObservables.map((call) =>
        call.pipe(catchError((error) => of({ error: true, details: error })))
      );

      forkJoin(wrappedCalls).subscribe(([patient, sessions, targets]) => {
        if ('error' in patient) {
          console.log('Error fetching patient:', patient.details);
        } else {
          this.selectedPatient = patient as PatientModel;
        }
        if ('error' in sessions) {
          console.log('Error fetching sessions:', sessions.details);
        } else {
          this.sessions = sessions as PatientSessionModel[];
        }
        if ('error' in targets) {
          console.log('Error fetching targets:', targets.details);
        } else {
          this.targets = targets as PatientTargetModel[];
        }

        this.isLoading = false;
      });
    });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  onRowExpand(event: TableRowExpandEvent) {
    console.log('Row expanded:', event);
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    console.log('Row collapsed:', event);
  }

  dataFromSession(session: PatientSessionModel) {
    return [...(session.TargetData ?? []), ...(session.BehaviorData ?? [])];
  }

  getTargetName(targetId: number) {
    const target = this.targets.find((t) => t.ID === targetId);
    return target?.TargetName ?? 'Unknown';
  }

  getTargetType(targetId: number) {
    const target = this.targets.find((t) => t.ID === targetId);
    const targetName = Utils.patientTargetTypeLabel(target?.TargetType!);
    return targetName;
  }

  switchToStaff() {
    const patientSession = this.patientSessionService.patientSession();
    this.router.navigate([
      '/staff/home/' + this.selectedPatient?.ID + '/' + patientSession?.ID,
    ]);
  }

  backToOpto() {
    window.location.href = this.authService.getOptoURL() ?? '/';
  }

  onSessionEditInit(session: PatientSessionModel) {
    console.log('Editing session:', session);
    this.clonedSessions[session.ID as number] = {
      ...session,
    } as PatientSessionModel;
  }

  onSessionEditSave(session: PatientSessionModel) {
    console.log('Updated Session:', session);
    this.saveSessionData(session);
  }

  onSessionEditCancel(session: PatientSessionModel, index: number) {
    console.log('Cancelled editing session:', session);
    this.sessions[index] = this.clonedSessions[session.ID as number];
    delete this.clonedSessions[session.ID as number];
  }

  onSessionDataEditInit(
    sessionData: PatientTargetDataModel | PatientBehaviorDataModel
  ) {
    console.log('Editing session data:', sessionData);

    if (this.isPatientTargetDataModel(sessionData)) {
      // save which session is being edited;
      this.sessionBeingEdited =
        this.sessions.find((session) =>
          session.TargetData?.some((t) => t.ID === sessionData.ID)
        ) ?? null;
      this.sessionIndexBeingEdited = this.sessions.findIndex((session) =>
        session.TargetData?.some((t) => t.ID === sessionData.ID)
      );

      this.onSessionEditInit(this.sessionBeingEdited!);

      this.clonedSessionData[sessionData.ID as number] = {
        ...sessionData,
      } as PatientTargetDataModel | PatientBehaviorDataModel;
    } else {
      // save which session is being edited;
      this.sessionBeingEdited =
        this.sessions.find((session) =>
          session.BehaviorData?.some((t) => t.ID === sessionData.ID)
        ) ?? null;
      this.sessionIndexBeingEdited = this.sessions.findIndex((session) =>
        session.TargetData?.some((t) => t.ID === sessionData.ID)
      );

      this.onSessionEditInit(this.sessionBeingEdited!);

      this.clonedSessionData[sessionData.ID as number] = {
        ...sessionData,
      } as PatientTargetDataModel | PatientBehaviorDataModel;
    }
  }

  isPatientTargetDataModel(obj: any): obj is PatientTargetDataModel {
    return 'PatientSessionID' in obj && 'PatientTargetID' in obj;
  }

  onSessionDataEditSave(
    sessionData: PatientTargetDataModel | PatientBehaviorDataModel
  ) {
    console.log('Updated Session data:', sessionData);
    this.saveSessionData(this.sessionBeingEdited!);
  }

  onSessionDataEditCancel(
    sessionData: PatientTargetDataModel | PatientBehaviorDataModel
  ) {
    console.log('Cancelled editing session:', sessionData);

    if (this.isPatientTargetDataModel(sessionData)) {
      const index = this.sessionBeingEdited?.TargetData?.findIndex(
        (t) => t.ID === sessionData.ID
      );
      if (index === undefined) {
        console.error('Could not find index for session data:', sessionData);
        return;
      }

      this.sessionBeingEdited!.TargetData![index] = this.clonedSessionData[
        sessionData.ID as number
      ] as PatientTargetDataModel;
      delete this.clonedSessionData[sessionData.ID as number];
    } else {
      const index = this.sessionBeingEdited?.BehaviorData?.findIndex(
        (t) => t.ID === sessionData.ID
      );
      if (index === undefined) {
        console.error('Could not find index for session data:', sessionData);
        return;
      }

      this.sessionBeingEdited!.BehaviorData![index] = this.clonedSessionData[
        sessionData.ID as number
      ] as PatientBehaviorDataModel;
      delete this.clonedSessionData[sessionData.ID as number];
    }

    this.onSessionEditCancel(
      this.sessionBeingEdited!,
      this.sessionIndexBeingEdited!
    );
  }

  saveSessionData(newSessionData: PatientSessionModel) {
    this.isLoading = true;
    console.log('Saving session data:', newSessionData);
    this.staffApiService
      .SavePatientSession({
        body: newSessionData,
      })
      .subscribe(
        (response) => {
          console.log('Saved session data:', response);
          this.alertService.success('Session data saved successfully');
          this.isLoading = false;
        },
        (error) => {
          console.error('Error saving session data:', error);
          this.alertService.error(
            'Error saving session data - ' + error.Message
          );
          this.isLoading = false;
        }
      );
  }
}
