import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  effect,
  inject,
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import {
  HealthCheckApiService,
  StaffApiService,
  SupervisorApiService,
} from '../../core/api/services';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MaintenanceCriteriaModel,
  PatientBehaviorModel,
  PatientBehaviorType,
  PatientModel,
  PatientPlaylistModel,
  PatientProgramModel,
  PatientReminderModel,
  PatientSessionModel,
  PatientTargetModel,
  PatientTargetStatus,
  PromptCodeModel,
  PromptScheduleModel,
  TargetType,
} from '../../core/api/models';
import {
  PatientPlaylistWithItemsModel,
  PatientProgramWithCountersModel,
  PatientReminderWithLastTimeModel,
} from '../../shared/models/models';
import { getPatientTargetStatusColor } from '../../shared/utils/utils';

import { catchError, forkJoin, Observable, of } from 'rxjs';
import { PatientSessionService } from './patient-session.service';

@Component({
  selector: 'staff-home',
  templateUrl: './staff-home.component.html',
  styleUrls: ['./staff-home.component.css'],
})
export class StaffHomeComponent implements OnInit {
  loading: boolean = true;
  isTimerRunning: boolean = false;
  timerValue: number = 0;
  patientSession: PatientSessionModel | null = null;
  programs: PatientProgramWithCountersModel[] = [];
  playlists: PatientPlaylistWithItemsModel[] = [];
  targets: PatientTargetModel[] = [];
  subTargetsDictionary: { [parentTargetID: number]: PatientTargetModel[] } = {};
  favoriteTargets: PatientTargetModel[] = [];
  specialFavoriteTargets: PatientTargetModel[] = [];
  patientID?: number;
  sessionID?: number;
  showingItems: {
    Title: string;
    Name: string;
    Targets: PatientTargetModel[];
    ProgramID?: number;
    ProgramNotes?: string;
  }[] = [];
  groupedShowingItems: {
    Title: string;
    items: {
      Title: string;
      Name: string;
      Targets: PatientTargetModel[];
      ProgramID?: number;
      ProgramNotes?: string;
    }[];
  }[] = [];
  patient: PatientModel | null = null;
  reminders: PatientReminderWithLastTimeModel[] = [];
  showingReminders: PatientReminderWithLastTimeModel[] = [];
  behaviors: PatientBehaviorModel[] = [];
  programNotesTimeouts: { [programID: number]: any } = {};

  listedItems: (
    | PatientProgramModel
    | PatientTargetModel
    | PatientPlaylistModel
  )[] = [];
  selectedItemsIndexes: number[] = [];

  selectedProgramIndex: number = -1;
  selectedPlaylistIndex: number = -1;
  selectedTargetIndex: number = -1;


  showEndSession: boolean = false;

  showingProgressNote: boolean = false;
  presentAtSession: string[] = [];
  progressNoteStep: number = 1;
  promptSchedules: PromptScheduleModel[] = [];
  
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private supervisorApiService: SupervisorApiService,
    private router: Router,
    private staffApiService: StaffApiService,
    private patientSessionService: PatientSessionService,
    private activatedRoute: ActivatedRoute
  ) {
    effect(() => {
      this.patientSession = this.patientSessionService.patientSession();
      if (this.isStarted() && !this.isTimerRunning) {
        this.updateTimer();
      }

      if (!this.isStarted()) {
        this.isTimerRunning = false;
        this.timerValue = 0;
      }
    });

    effect(() => {
      //if the patient session ID changes, update it here and also change the URL
      if (this.patientSessionService.patientSession()?.ID !== this.sessionID) {
        this.sessionID = this.patientSessionService.patientSession()?.ID ?? 0;
        window.history.replaceState(
          {},
          '',
          `/staff/home/${this.patientID}/${this.sessionID}`
        );
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientID = params['patientID'] ?? null;
      this.sessionID = params['sessionID'] ?? null;
      this.patientSessionService.initializePatientSession(
        this.patientID,
        this.sessionID
      );
      this.getData();
    });
  }

  getTargetPromptCodes(
    target: PatientTargetModel
  ): PromptCodeModel[] {
    return (
      target.PromptCodes ??
      []
    );
  }

  getData(): void {
    const getProgramsObservable = this.supervisorApiService.GetPatientPrograms({
      patientID: this.patientID,
    });
    const getPlaylistsObservable =
      this.supervisorApiService.GetPatientPlaylists({
        patientID: this.patientID,
      });
    const getTargetsObservable = this.supervisorApiService.GetPatientTargets({
      patientID: this.patientID,
    });
    const getBehaviorsObservable = this.staffApiService.GetPatientBehaviors({
      patientID: this.patientID,
    });
    const getPromptSchedulesObservable = this.supervisorApiService.GetPromptSchedules({
      patientID: this.patientID,
    });
    const getMaintenanceCriteriaObservable = this.supervisorApiService.GetMaintenanceCriteriaFull({
      patientID: this.patientID,
    });

    const getDataObservables: Observable<
      | PatientProgramModel[]
      | PatientPlaylistWithItemsModel[]
      | PatientTargetModel[]
      | PatientBehaviorModel[]
      | PromptScheduleModel[]
    >[] = [
      getProgramsObservable,
      getPlaylistsObservable,
      getTargetsObservable,
      getBehaviorsObservable,
      getPromptSchedulesObservable
    ];

    // Wrap each API call with catchError to handle failure individually
    const wrappedCalls = getDataObservables.map((call) =>
      call.pipe(catchError((error) => of({ error: true, details: error })))
    );

    forkJoin(wrappedCalls).subscribe(
      ([programs, playlists, targets, behaviors, promptSchedules]) => {
        if ('error' in programs) {
          console.log('Error fetching programs:', programs.details);
          if (localStorage.getItem('programs')) {
            this.programs = JSON.parse(
              localStorage.getItem('programs')!
            ) as PatientProgramWithCountersModel[];
            console.log('Getting programs from local storage');
          }
        } else {
          this.programs = programs as PatientProgramWithCountersModel[];
          localStorage.setItem('programs', JSON.stringify(this.programs));
        }

        if ('error' in playlists) {
          console.log('Error fetching playlists:', playlists.details);
          if (localStorage.getItem('playlists')) {
            this.playlists = JSON.parse(
              localStorage.getItem('playlists')!
            ) as PatientPlaylistWithItemsModel[];
            console.log('Getting playlists from local storage');
          }
        } else {
          this.playlists = playlists as PatientPlaylistWithItemsModel[];
          localStorage.setItem('playlists', JSON.stringify(this.playlists));
        }

        if ('error' in targets) {
          console.log('Error fetching targets:', targets.details);
          if (localStorage.getItem('targets')) {
            this.targets = JSON.parse(
              localStorage.getItem('targets')!
            ) as PatientTargetModel[];
            console.log('Getting targets from local storage');
          }
        } else {
          let targetsFiltered = targets as PatientTargetModel[];
          targetsFiltered = targetsFiltered.filter(t => {
            if (t.TargetStatus != PatientTargetStatus.Unspecified 
            && t.TargetStatus != PatientTargetStatus.Closed
            && t.TargetStatus != PatientTargetStatus.Ready) {
              return false;
            }

            return true;
          });

          this.targets = targetsFiltered as PatientTargetModel[];
          localStorage.setItem('targets', JSON.stringify(this.targets));
        }


        if ('error' in behaviors) {
          console.log('Error fetching behaviors:', behaviors.details);
          if (localStorage.getItem('behaviors')) {
            this.behaviors = JSON.parse(
              localStorage.getItem('behaviors')!
            ) as PatientBehaviorModel[];
            console.log('Getting behaviors from local storage');
          }
        } else {
          this.behaviors = behaviors as PatientBehaviorModel[];
          localStorage.setItem('behaviors', JSON.stringify(this.behaviors));
        }
        this.patientSessionService.patientBehaviors.set(this.behaviors);

        if ('error' in promptSchedules) {
          console.log('Error fetching prompt schedules:', promptSchedules.details);
        } else {
          this.promptSchedules = promptSchedules as PromptScheduleModel[];
          localStorage.setItem('promptSchedules', JSON.stringify(this.promptSchedules));
        }


        this.targets.forEach((target) => {
          this.subTargetsDictionary[target.ID ?? 0] = target.SubTargetIDs?.map(
            (id) => this.targets.find((t) => t.ID === id)
          ).filter((t) => !!t) as PatientTargetModel[];
        });

        this.programs.forEach((program) => {
          const counters: {
            targetStatus: PatientTargetStatus;
            count: number;
            color: string;
          }[] = [];
          program.Targets?.forEach((target) => {
            if (
              target.TargetStatus ||
              target.TargetStatus == PatientTargetStatus.Unspecified
            ) {
              if (
                !counters.find((x) => x.targetStatus == target.TargetStatus)
              ) {
                counters.push({
                  targetStatus: target.TargetStatus,
                  count: 1,
                  color: getPatientTargetStatusColor(target.TargetStatus),
                });
              } else {
                //increment the count
                const index = counters.findIndex(
                  (x) => x.targetStatus == target.TargetStatus
                );
                counters[index].count++;
              }
            }
          });
          program.Counters = counters;
        });
        this.listedItems = [...this.programs];

        this.playlists.forEach((playlist) => {
          playlist.FilledItems = [];
          playlist.Items?.forEach((item) => {
            const program = this.programs.find(
              (p) => p.ID === item.PatientProgramID
            );
            if (program) {
              playlist.FilledItems?.push(program);
            } else {
              const target = this.targets.find(
                (t) => t.ID === item.PatientTargetID
              );
              if (target) {
                playlist.FilledItems?.push(target);
              }
            }
          });
        });

        this.listedItems = [...this.playlists];

        this.favoriteTargets = this.targets.filter((t) => t.IsFavorite);
        this.specialFavoriteTargets = this.targets.filter(
          (t) => t.IsSpecialFavorite
        );
        this.listedItems = [...this.targets];

        if (this.programs.length > 0) {
          this.selectProgram(this.programs[0]);
        } else if (this.playlists.length > 0) {
          this.selectPlaylist(this.playlists[0]);
        } else if (this.targets.length > 0) {
          this.selectTarget(this.targets[0]);
        }

        this.loading = false;
      }
    );

    this.staffApiService
      .GetPatientInfo({ id: this.patientID })
      .subscribe((patient) => {
        this.patient = patient;
      });
    this.supervisorApiService
      .GetPatientReminders({ patientID: this.patientID })
      .subscribe((reminders) => {
        //Get reminders from local storage if they exist
        let remindersFromLocalStorage: PatientReminderWithLastTimeModel[] = [];
        if (localStorage.getItem('reminders')) {
          remindersFromLocalStorage = JSON.parse(
            localStorage.getItem('reminders')!
          ) as PatientReminderWithLastTimeModel[];
          console.log('Getting reminders from local storage');
        }

        //Add the lastTimeClosed to the reminders from local storage
        remindersFromLocalStorage.forEach((r) => {
          const reminder = reminders.find((x) => x.ID === r.ID);
          if (reminder) {
            (reminder as PatientReminderWithLastTimeModel).lastTimeClosed =
              r.lastTimeClosed;
          }
        });

        this.reminders = reminders as PatientReminderWithLastTimeModel[];

        localStorage.setItem('reminders', JSON.stringify(this.reminders));
      });

    this.patientSessionService.loadPatientBehaviorLocations();
    this.patientSessionService.loadPatientBehaviorResponses();
    this.patientSessionService.loadPatientBehaviorSeverities();
  }

  countMasteredTargets(program: PatientProgramModel): number {
    return (
      program.Targets?.filter((target) => !!target.DateMastered).length || 0
    );
  }

  startSession() {
    this.patientSessionService.startSession();
  }

  isStarted(): boolean {
    return !!(
      this.patientSession?.StartedDate && !this.patientSession?.FinishedDate
    );
  }

  private updateTimer(): void {
    if (this.isStarted()) {
      this.isTimerRunning = true;
      const currentTime = new Date(new Date().toServerString()).getTime();
      const startTime = new Date(this.patientSession?.StartedDate!).getTime();
      this.timerValue = Math.floor((currentTime - startTime) / 1000);

      //check if the reminder should be shown based on its interval and the sesstion time
      this.checkReminders();
      setTimeout(() => this.updateTimer(), 1000);
    }
  }

  private checkReminders(): void {
    this.showingReminders = this.reminders.filter(
      (r) =>
        (!r.Interval && !r.lastTimeClosed) ||
        (r.Interval && this.timerValue - (r.lastTimeClosed ?? 0) >= r.Interval)
    );
  }

  closeReminder(reminder: PatientReminderWithLastTimeModel) {
    //increase the reminder counter
    if (reminder.Interval) {
      reminder.lastTimeClosed =
        Math.floor(this.timerValue / reminder.Interval) * reminder.Interval;
    } else {
      reminder.lastTimeClosed = this.timerValue;
    }
    this.checkReminders();
    localStorage.setItem('reminders', JSON.stringify(this.reminders));
  }

  endSession() {
    this.showEndSession = true;
  }

  onCloseEndSession() {
    this.showEndSession = false;
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }

  switchToSupervisor() {
    this.router.navigate(['/supervisor/' + this.patientID]);
  }

  switchToGraphs() {
    this.router.navigate(['/graphs/' + this.patientID]);
  }

  getShowingItemsGroupedByTitle() {
    const groupedItems = this.showingItems.reduce(
      (
        acc: {
          Title: string;
          items: {
            Title: string;
            Name: string;
            Targets: PatientTargetModel[];
          }[];
        }[],
        item
      ) => {
        const existing = acc.find((group) => group.Title === item.Title);
        if (!existing) {
          acc.push({ Title: item.Title, items: [item] });
        } else {
          existing.items.push(item);
        }
        return acc;
      },
      []
    );

    return groupedItems;
  }

  selectProgram(program: PatientProgramModel) {
    this.showingItems = [
      {
        Title: program.SkillAreaName ?? '',
        Name: program.ProgramName ?? '',
        Targets: program.Targets ?? [],
        ProgramID: program.ID,
        ProgramNotes: program.ProgramNotes ?? '',
      },
    ];
    this.groupedShowingItems = this.getShowingItemsGroupedByTitle();
    this.selectedProgramIndex = this.programs.findIndex(
      (p) => p.ID === program.ID
    );
    this.selectedPlaylistIndex = -1;
    this.selectedTargetIndex = -1;
  }

  selectPlaylist(playlist: PatientPlaylistModel) {
    const programs: PatientProgramModel[] = [];
    playlist.Items?.forEach((item) => {
      const program = this.programs.find((p) => p.ID === item.PatientProgramID);
      if (program) {
        programs.push(program);
      } else {
        const target = this.targets.find((t) => t.ID === item.PatientTargetID);
        if (target) {
          programs.push({
            SkillAreaName: 'Targets',
            ProgramName: target.TargetName,
            Targets: [target],
          });
        }
      }
    });
    this.showingItems = programs.map((p) => ({
      Title: p.SkillAreaName ?? '',
      Name: p.ProgramName ?? '',
      Targets: p.Targets ?? [],
      ProgramID: p.ID,
      ProgramNotes: p.ProgramNotes ?? '',
    }));
    this.groupedShowingItems = this.getShowingItemsGroupedByTitle();
    this.selectedPlaylistIndex = this.playlists.findIndex(
      (p) => p.ID === playlist.ID
    );
    this.selectedProgramIndex = -1;
    this.selectedTargetIndex = -1;
  }

  selectTarget(target: PatientTargetModel) {
    this.showingItems = [
      { Title: 'Targets', Name: target.TargetName ?? '', Targets: [target] },
    ];
    this.groupedShowingItems = this.getShowingItemsGroupedByTitle();
    this.selectedTargetIndex = this.targets.findIndex(
      (t) => t.ID === target.ID
    );
    this.selectedProgramIndex = -1;
    this.selectedPlaylistIndex = -1;
  }

  nextOnList() {
    if (this.selectedTargetIndex >= 0) {
      this.nextTarget();
    } else if (this.selectedPlaylistIndex >= 0) {
      this.nextPlaylist();
    } else {
      if (this.selectedProgramIndex < 0) {
        this.selectedProgramIndex = -1;
      }
      this.nextProgram();
    }
  }

  nextProgram() {
    if (this.selectedProgramIndex + 1 >= this.programs.length) {
      this.selectedProgramIndex = -1;
      this.selectedTargetIndex = -1;
      this.selectedPlaylistIndex = -1;
      this.nextPlaylist();
    } else {
      this.selectedProgramIndex++;
      this.selectProgram(this.programs[this.selectedProgramIndex]);
    }
  }

  nextPlaylist() {
    if (this.selectedPlaylistIndex + 1 >= this.playlists.length) {
      this.selectedPlaylistIndex = -1;
      this.selectedProgramIndex = -1;
      this.selectedTargetIndex = -1;
      this.nextTarget();
    } else {
      this.selectedPlaylistIndex++;
      this.selectPlaylist(this.playlists[this.selectedPlaylistIndex]);
    }
  }

  nextTarget() {
    if (this.selectedTargetIndex + 1 >= this.targets.length) {
      //Nothing this is the end
    } else {
      this.selectedTargetIndex++;
      this.selectTarget(this.targets[this.selectedTargetIndex]);
    }
  }

  isFrequencyBehavior(behavior: PatientBehaviorModel): boolean {
    return behavior.BehaviorType === PatientBehaviorType.Frequency;
  }

  isDurationBehavior(behavior: PatientBehaviorModel): boolean {
    return behavior.BehaviorType === PatientBehaviorType.Duration;
  }

  showProgressNote() {
    if (!this.showingProgressNote) {
      this.showingProgressNote = true;
      this.progressNoteStep = 1;
      //Animate progress-note div to be bigger
      const progressNote = document.querySelector(
        '.progress-note'
      ) as HTMLElement;
      if (progressNote) {
        if (window.innerWidth > 700) {
          progressNote.style.maxWidth = '700px';
          progressNote.style.width = '700px';
        } else {
          progressNote.style.maxWidth = window.innerWidth - 100 + 'px';
          progressNote.style.width = window.innerWidth - 100 + 'px';
        }
      }
    }
  }

  hideProgressNote() {
    if (this.showingProgressNote) {
      this.showingProgressNote = false;
      //Animate progress-note div to be smaller
      const progressNote = document.querySelector(
        '.progress-note'
      ) as HTMLElement;
      if (progressNote) {
        progressNote.style.maxWidth = '25px';
        progressNote.style.width = '25px';
      }
    }
  }

  nextProgressNote() {
    this.progressNoteStep++;
  }

  backProgressNote() {
    if (this.progressNoteStep > 1) {
      this.progressNoteStep--;
    }
  }

  showProgramNotes(
    programNotesDiv: HTMLDivElement,
    programNotesTextarea: HTMLTextAreaElement
  ) {
    programNotesDiv.style.height = '200px';
    programNotesTextarea.focus();
    programNotesTextarea.classList.add('focused');
  }

  hideProgramNotes(
    programNotesDiv: HTMLDivElement,
    programNotesTextarea: HTMLTextAreaElement
  ) {
    programNotesDiv.style.height = '40px';
    programNotesTextarea.classList.remove('focused');
  }

  updateProgramNotes(item: {
    Title: string;
    Name: string;
    Targets: PatientTargetModel[];
    ProgramID?: number;
    ProgramNotes?: string;
  }) {
    //cancel the previous timeout if it exists
    if (item.ProgramID) {
      if (this.programNotesTimeouts[item.ProgramID]) {
        clearTimeout(this.programNotesTimeouts[item.ProgramID]);
      }

      //start a timnout to save the program notes in 2 seconds
      this.programNotesTimeouts[item.ProgramID] = setTimeout(() => {
        this.saveProgramNotes(item.ProgramID!, item.ProgramNotes!);
      }, 2000);
    }
  }

  saveProgramNotes(programID: number, programNotes: string) {
    this.staffApiService
      .ChangeProgramNotes({ programId: programID, newNotes: programNotes })
      .subscribe(() => {
        console.log('Program notes updated');
      });
  }
}
