
<div class="">
  <div class="logo-container" *ngIf="isSignedIn">
    <div class="">
      <div class="main-page-content-block">
        <img [src]="logoURL | secure | async"
             class="bussines-logo"
             alt="{{generalSettings?.BusinessFullName}}" />
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="{ 'hide': message==null, 'show': message!= null }" style="">
    <div class="col-sm-6 offset-sm-3">
      <a *ngIf="message" href="javascript:void(0);" (click)="clearMessage();" class="btn" style="position: relative; z-index: 1000 !important; float: right;"><i class="fa-regular fa-xmark"></i></a>
      <div *ngIf="message" [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error', 'alert-info': message.type === 'info' }">{{message.text}}</div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="top-center" key="confirmToat" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">

    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <br>
        <p class="text-wrap-carrige-return">{{message.detail}}<br><br></p>
      </div>
      <div class="grid p-fluid d-flex justify-content-center">
        <div>
          <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-primary"></button>
        </div>
        &nbsp;
        <div>
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>

  </ng-template>
</p-toast>

