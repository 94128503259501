/* tslint:disable */
/* eslint-disable */
export enum Permissions {
  ActionItems_View = 1,
  BedLogs_Edit = 2,
  BedLogs_EditSettings = 3,
  BedLogs_View = 4,
  Campuses_Manage = 5,
  Campuses_ViewAll = 6,
  CaseworkerReport_View = 7,
  Client_Add = 8,
  Client_Deactivate = 9,
  Client_Delete = 10,
  Client_Reactivate = 11,
  ClientAppointments_View = 12,
  ClientAppointments_Add = 13,
  ClientAppointments_Edit = 14,
  ClientDocuments_Add = 15,
  ClientDocuments_View = 16,
  ClientReports_View = 17,
  Clients_ViewAll = 18,
  Clients_ViewAllInProgram = 19,
  Contacts_Edit = 20,
  Contacts_View = 21,
  CourtReports_View = 22,
  DailyLogs_Edit = 23,
  DailyLogs_View = 24,
  DeactivatedClients_View = 25,
  Email_Delete = 26,
  Email_Send = 27,
  Email_View = 28,
  EmployeeInfo_Manage = 29,
  EmployeeScheduling_Manage = 30,
  EmployeeShifts_View = 31,
  EmployeeTrainingSummary_View = 32,
  EventLogs_View = 33,
  FamilyTeamMeetings_View = 34,
  ClientDashboard_View = 35,
  GeneralSettings_Manage = 36,
  GroupTherapyReports_View = 37,
  IncidentReport_Add = 38,
  IncidentReport_Delete = 39,
  IncidentReport_ViewAdminInfo = 40,
  IncidentReports_Edit = 41,
  IncidentReports_View = 42,
  InquiryStatus_View = 43,
  InsuranceBilling_View = 44,
  InsuranceProviders_Manage = 45,
  Intake_Edit = 46,
  MedEvaluations_Add = 47,
  MedEvaluations_Edit = 48,
  Medicaid_Access = 49,
  MedManagement_View = 50,
  Mileage_Access = 51,
  Mileage_DestinationPoints_Manage = 52,
  MileageVehicle_Add = 53,
  PointSystem_Level_EditSettings = 54,
  PointSystem_View = 55,
  ProctorHomes_Manage = 56,
  ProgressReports_View = 57,
  PRS_Add = 58,
  PRS_Edit = 59,
  PRS_View = 60,
  PSA_View = 61,
  PSA_ViewDollarAmounts = 62,
  RenderingProviders_Manage = 63,
  ReportsOfClients_View = 64,
  ShiftReport_View = 65,
  ShiftReports_Edit = 66,
  Sign_Anything = 67,
  Sign_CourtReports = 68,
  Sign_IncidentReports = 69,
  Sign_IncidentReportsSupervisor = 70,
  Sign_MedEvaluations = 71,
  Sign_ProgressNotes = 72,
  Sign_ProgressReports = 73,
  Sign_PRS = 74,
  Sign_TherapistSupervisor = 75,
  Sign_Tracking = 76,
  Sign_Trainings = 77,
  Sign_TreatmentForms = 78,
  Signatures_Edit = 79,
  Signatures_Manage = 80,
  StateBilling_Edit = 81,
  StateBilling_View = 82,
  StateContract_Manage = 83,
  TherapistAssignments_Manage = 84,
  TherapistSupervisor_View = 85,
  TherapyGroups_Manage = 86,
  TherapyGroups_ViewAll = 87,
  TherapySession_Edit = 88,
  TherapySessions_View = 89,
  TherapySessions_ViewAll = 90,
  TherapyUpdateReport_View = 91,
  NewClientStatus_View = 92,
  TimeCards_Manage = 93,
  Tracking_Add = 94,
  Tracking_Edit = 95,
  Tracking_View = 96,
  TherapistDashboard_View = 97,
  Training_Manage = 98,
  TreatmentForms_Edit = 99,
  TreatmentForms_View = 100,
  Users_Edit = 101,
  Users_Impersonate = 102,
  Users_Manage = 103,
  APRNDashboard_View = 104,
  ProctorParentDashboard_View = 105,
  MileageEntryForm_Add = 106,
  GroupTherapyReports_Add = 107,
  TherapyProgressUpdates_Add = 108,
  NewClientStatus_AddInquiry = 109,
  NewClientStatus_EditInquiry = 110,
  NewClientStatus_AddPreAuth = 111,
  NewClientStatus_EditPreAuth = 112,
  NewClientStatus_ViewPreAuth = 113,
  NewClientStatus_AssignCampus = 114,
  NewClientStatus_AssignTherapist = 115,
  AuthorizationsReport_View = 116,
  TreatmentTeam_Assignable = 117,
  InsuranceCodes_ShowAllCodes = 118,
  InsuranceCodes_ShowHCodes = 119,
  InsuranceCodes_ShowTCodes = 120,
  Client_Summary = 121,
  Authorizations_Edit = 122,
  Authorizations_ViewBillingSpecialistInfo = 123,
  Navigation_ViewAllPages = 124,
  TreatmentPlan_ProceduralGoal_EditTemplates = 125,
  DocumentationCompletion_View = 126,
  CounselingTypes_Edit = 127,
  StaffingCalendar_View = 128,
  PayrollReport_View = 129,
  IsTherapist = 130,
  IsStaff = 131,
  IsAPRN = 132,
  IsSupervisor = 133,
  Scheduling_OwnView = 134,
  Scheduling_AllView = 135,
  Scheduling_OwnEdit = 136,
  Scheduling_AllEdit = 137,
  Authorizations_View = 138,
  ClientSummary_ViewTreatmentPlan = 139,
  ClientSummary_ViewMHA = 140,
  ClientSummary_ViewPersonalClientInfo = 141,
  ClientSummary_ViewNotes = 142,
  ClientSummary_ViewMoreThanTwoTherapySessions = 143,
  ViewScheduledClients = 144,
  Scheduling_NonMeetingEvent_OwnEdit = 145,
  PayrollHours_View = 146,
  Scheduling_SaveRetroactive = 147,
  Scheduling_DeleteRetroactive = 148,
  TreatmentTeam_Edit = 149,
  CommunityService_View = 150,
  TherapySession_CanEditSessionDetails = 151,
  Scheduling_CreateGroupMatrix = 152,
  CaseloadSummary_View = 153,
  PayrollApproval_View = 154,
  PsychEvaluationSummary_View = 155,
  ITV_Dashboard = 156,
  TimeCard_ClockPunch = 157,
  Consequences_Create = 158,
  Consequences_View = 159,
  TherapyGroups_AssignableTo = 160,
  Psychologist_Dashboard = 161,
  Credentials_Manage = 162,
  Sign_AsDirector = 163,
  Director_Dashboard = 164,
  ContactLog_ViewAll = 165,
  BurnRateReport_View = 166,
  Tracking_AssignableToClient = 167,
  SupervisionReport_ViewSummary = 168,
  Alert_Administrative = 169,
  Alert_Clinical = 170,
  Alert_EditAll = 171,
  Signatures_EditDate = 172,
  InsuranceProviders_Add = 173,
  InsuranceProviders_Delete = 174,
  Billing_BillableServices = 175,
  BillingBreakdown = 176,
  DocumentationAudit = 177,
  ProgressReports_Edit = 178,
  FamilyTeamMeetings_Edit = 179,
  CourtReports_Edit = 180,
  GroupTherapyReports_Edit = 181,
  ActionSteps_Manage = 182,
  TherapySession_EditApprovedSessions = 183,
  TherapySession_EditBilledSessions = 184,
  TherapySession_CreateProgressNote = 185,
  Scheduling_ManageTimeOff = 186,
  Scheduling_SchedulingNeeds = 187,
  Scheduling_ScheduleEfficiency = 188,
  Insurance_ViewInsuranceProviderReport = 189,
  Insurance_ViewInsuranceCodeReport = 190,
  Permissions_Manage = 191,
  Supervisors_Manage = 192,
  ImpersonationRules_Manage = 193,
  Payroll_CanOverrideDateSubmitted = 194,
  Scheduling_ViewAtAGlance = 195,
  Payroll_CanUnlockPayPeriod = 196,
  BehaviorTickets_Manage = 197,
  TreatmentPlan_View = 198,
  NoteGraveyard_Manage = 199,
  TherapySession_CanKillNotes = 200,
  Billing_CanExport = 201,
  Authorizations_ManageAuthorizationAssignments = 202,
  QualityAssurance_Manage = 203,
  IsAuditor = 204,
  Auditors_Manage = 205,
  Billing_WronglyBilled = 206,
  OutcomeQuestionnaire_Manage = 207,
  LastServiceDateReport_View = 208,
  AssignTreatmentSupervisors_Manage = 209,
  TherapistBillingReport_View = 210,
  CanBeAssignedToInsuranceProviders = 211,
  AccessToBillingProcess = 212,
  NoteAssistant_Manage = 213,
  SupervisionSurvey_Manage = 214,
  RecordsRequest_Access = 215,
  CancellationsReport_Access = 216,
  RejectedNotes_Access = 217,
  BillingProcess_CanDeleteRemit = 218,
  BillingProcess_ViewAll = 219,
  BillingProcess_ViewIncompleteDocumentation = 220,
  BillingProcess_AssignUsersToInsuranceProviders = 221,
  BillingProcess_AccessFindBillingItemTool = 222,
  SupervisionBreakdown_Access = 223,
  Service_CanEditAfterSubmission = 224,
  Users_Impersonate_view_only = 225,
  BillingProcess_CanEditProcessed = 226,
  BillingProcess_CanProcessRemit = 227,
  BillingProcess_CanProcessPayment = 228,
  BillingProcess_Payments = 229,
  BillingProcess_Invoices = 230,
  BillingProcess_CanDeletePayment = 231,
  BillingProcess_CanVoidInvoice = 232,
  PrivatePay_Manage = 233,
  OptoEmail_View = 234,
  OptoEmail_Send = 235,
  OptoEmail_SendToPatients = 236,
  OptoEmail_SendToAny = 237,
  OptoEmail_Manage = 238,
  BillingItem_CanSetAmounts = 239,
  BillingItem_CanSetUnrecoverable = 240,
  BillingProcess_ViewPatientRefunds = 241,
  BillingProcess_ManagePatientRefunds = 242,
  Messages_View = 243,
  Messages_Send = 244,
  Messages_SendToPatients = 245,
  Messages_ViewPatients = 246,
  Messages_Manage = 247,
  OptoFax_View = 248,
  OptoFax_Send = 249,
  OptoFax_Edit = 250,
  OptoFax_Manage = 251,
  SMS_ViewAll = 252,
  Fax_ViewAll = 253,
  Messages_ViewAll = 254,
  Email_ViewAll = 255,
  BillingProcess_BillExistingClaimNumber = 256,
  SupportTickets_View = 301,
  SupportTickets_Submit = 302,
  Intake_CanEditWaitList = 1000,
  Scheduling_Receive_TimeOffEmails = 1001,
  ClientSummary_EditLegalNotes = 1002,
  ClientSummary_EditMedicalNeeds = 1003,
  Intake_CanDeactivate = 1004,
  WaitList_Manage = 1005,
  Intake_CanEditInquiry = 1006,
  NewClientStatus_AuthRequests = 1008,
  NewClientProcess_Settings = 1009,
  EdiProcessing = 1011,
  OverBurnedReport_View = 1012,
  PsychologicalEvaluation_Edit = 2001,
  CanDoIndirectCounceling = 2002,
  ServiceDescriptions_Manage = 3000,
  ClientSummary_CanCreateProgressNote = 3001,
  PayrollDiscrepancy_View = 3002,
  FeeSchedule_Manage = 3003,
  MarketRates_Edit = 3004,
  ViewTermedClinicians = 3005,
  IgnoreAvailabilityCheckOnSchedulingEfficiency = 3006,
  MismatchedServiceDescription_Access = 3007,
  CanAddStaffToService = 3008,
  Scheduling_EditStaffingLocation = 3009,
  DischargeForms_GraveyardOrDelete = 3010,
  AvailableTreatmentCategories_Manage = 3011,
  BillingProcess_EditContactLog = 3012,
  Availability_ClientNavigationLink = 3013,
  CurriculumMilestoneAssessment_EditCompleted = 3014,
  ALOC_Read = 3015,
  ALOC_Write = 3016,
  ALOC_EditCompleted = 3017,
  AuthStatus_Edit = 3018,
  OptumComplianceReport_View = 3019,
  Scheduling_EditCampusPrimaryLocation = 3020,
  PendingAuthorization_ManuallyAdd = 3021,
  PayrollApproval_DirectorView = 3022,
  AuthorizationsReport_EditAuths = 3023,
  ManageAnnouncements_View = 3024,
  ManageAnnouncements_Edit = 3025,
  IncidentReportSummary_View = 3026,
  ManageEmailAlerts_View = 3027,
  ManageEmailAlerts_Edit = 3028,
  ClientServiceHours_View = 3029,
  ManageHL7 = 3030,
  ClientInsuranceProviderSummary_View = 3031,
  ClientServiceHours_DirectorView = 3032,
  ProviderCodeSummaryReport_View = 3033,
  ClientTransition_View = 3034,
  ClientTransition_Edit = 3035,
  Holidays_View = 3036,
  Holidays_Manage = 3037,
  Holidays_EditApproved = 3038,
  MarketingDashboard_View = 3039,
  EmployeePayrollReports_View = 3040,
  CredentialDocuments_View = 3041,
  ForceOnboarding = 3042,
  NewHireTracking_View = 3043,
  PaneledProviders_View = 3044,
  LeavePay_View = 3045,
  LeavePay_Edit = 3046,
  Leave_EditApproved = 3047,
  LeaveType_Edit = 3048,
  Scheduling_CanScheduleOverburn = 3049,
  ClientServiceNoteReport_View = 3050,
  ClientServiceNote_QaEdit = 3051,
  Equipment_View = 3052,
  Equipment_Manage = 3053,
  On_Boarding_View = 3054,
  On_Boarding_Manage = 3055,
  Offer_Letter_View = 3056,
  Offer_Letter_Manage = 3057,
  EmployeeFile_View = 3058,
  EmployeeFile_Manage = 3059,
  Offer_Letter_BehaviorTech_RBT_Manage = 3060,
  Offer_Letter_BehaviorTech_RBT_AA_Manage = 3061,
  On_Boarding_Manage_i9Form = 3062,
  EmployeeFile_ManageIncomplete = 3063,
  PayrollApproval_ShowAllItems = 3064,
  FullTimeStatusChecks_View = 3065,
  FullTimeStatusChecks_Manage = 3066,
  FinalCheckPaid_Manage = 3067,
  PaylocityTransmission_View = 3068,
  PaylocityTranmission_Manage = 3069,
  BenefitReports_Manage = 3070,
  PatientDocumentationStatusDueDate_Manage = 3071,
  ViewableExceptions_View = 3072,
  ViewableExceptions_Manage = 3073,
  Scheduling_AssignedNonClientTraining_Edit = 3074,
  CredentialingDashboard_View = 3075,
  KRACompleted_View = 3076,
  SelfCare_Manage = 3077,
  SelfCare_View = 3078,
  EmployeeStaffingAvailability_View = 3079,
  Calendar_OverrideChecks = 3080,
  SelfCareReports_View = 3081,
  SpokenLanguages_Manage = 3082,
  SpokenLanguages_View = 3083,
  PayrollWeeklyHours_View = 3084,
  ProspectiveCandidate_View = 3085,
  ProspectiveCandidate_Manage = 3086,
  ManageDocumenationTypes_View = 3087,
  ManageDocumenationTypes_Edit = 3088,
  AuthorizationUtilization_View = 3089,
  AuthorizationUtilization_Manage = 3090,
  DischargeForm_UpdateCompleted = 3091,
  DailyLogReport_View = 3092,
  DischargeForm_EditAllAfterCompleted = 3093,
  ClientDocuments_Delete = 3094,
  PerformanceManagement_Link = 3095,
  Intake_CreatePatientRecord = 3096,
  ViewHL7 = 3097,
  Pto_Manage = 3098,
  Pto_View = 3099,
  BasePercentage_Edit = 3100,
  SecondaryCodes_Edit = 3101,
  QaScoringReport_View = 3102,
  CertificationReport_View = 3103,
  CertificationReport_Manage = 3104,
  SweetSpot_ViewAll = 3105,
  SweetSpot_ViewPrimary = 3106,
  TreatmentTeam_EditNonEditable = 3110,
  DischargeForm_View = 3111,
  MentalHealthAssessment_View = 3112,
  CanRejectNote = 3113,
  CanViewContractRates = 3114,
  PayrollApproval_DirectorCampus = 3115,
  RequestedHoursUtilization_View = 3116,
  RequestedHoursUtilization_Manage = 3117,
  TrackingPatientAuthorizationCategory_Manage = 3118,
  RoomSearch_View = 3119,
  RoomSearch_Edit = 3120,
  PayrollApproval_EditItems = 3121,
  InternApplications_View = 3122,
  InternApplications_Manage = 3123,
  Internship_View = 3124,
  Internship_Manage = 3125,
  InternshipType_Manage = 3126,
  TrackingSheet_View = 3127,
  TrackingSheet_Edit = 3128,
  TrackingSheet_Master = 3129,
  TrackingSheetCategory_Edit = 3130,
  Intake_EditInquiryInformation = 3131,
  ClientPortalLogin_EditFields = 3132,
  Calendar_ScheduleOwn_NotDelete = 3133,
  EditDocumentation_Master = 3134,
  BSP_IgnoreSignaturePrintingRequirement = 3135,
  TrainingStartDate_Edit = 3136,
  EmployeeReviewDates_Edit = 3137,
  Cluster_Edit = 3138,
  Cluster_View = 3200,
  Users_ViewAll = 3201,
  Users_ViewAllInCampus = 3202,
  KRAReport_View = 3203,
  EmployeeClient_IncidentReportQueue = 3204,
  ProfitSharing_View = 3205,
  ProfitSharing_Manage = 3206,
  MeetingNotes_Master = 3207,
  ClientForms_Master = 3208,
  BetaTesting_Master = 3209,
  BetaTesting_Scheduling = 3210,
  EmployeeClients_View = 3211,
  ClientPortal_ImpersonateAndEdit = 3212,
  ClientSummary_MedClinicViewAccess = 3213,
  AccountingView = 3300,
  AccountingEdit = 3301,
  HR_Records_Full_Access = 3302,
  HR_Records_View = 3303,
  HR_Records_Edit = 3304,
  Finance_SetDepositDate = 3411,
  Finance_ViewStatements = 3412,
  Finance_UploadStatements = 3413,
  DashboardWidgets_ManageTemplates = 3421,
  DashboardWidgets_ManageWidgets = 3422,
  DashboardWidgets_UserDashboard = 3423,
  DashboardWidgets_PersonalWidgets = 3424,
  CapitationAgreement_Invoices = 3431,
  ExpenseReport_Submit = 3501,
  ExpenseReport_Location = 3502,
  ExpenseReport_Approve = 3506,
  ExpenseReport_Basic = 3507,
  ExpenseReport_Supervisor = 3508,
  ExpenseReport_Executive = 3511,
  ExpenseReport_ViewAll = 3512,
  ExpenseReport_ManageLocation = 3513,
  ExpenseReport_ManageCategories = 3514,
  ExpenseReport_ManageVendors = 3515,
  ExpenseReport_Recuring = 3516,
  ExpenseReport_ManageTransaction = 3517,
  ExpenseReport_ViewTransaction = 3518,
  ExpenseReport_ManageUserCard = 3519,
  SparkApp_Staff = 3601,
  SparkApp_Supervisor = 3602,
  NewClientProcess_CreateContactLogType = 4000,
  DiagnosisCodes_Manage = 4001,
  AuthorizationsDashboard_AuthsQueues = 4002,
  AuthorizationsDashboard_ManageAuths = 4003,
  ClientSummary_ExportContactLogs = 4004,
  AuthorizationsDashboard_CallbackQueue = 4005,
  AuthorizationsDashboard_CallbackEdit = 4006,
  AuthorizationsDashboard_CallbackAuthorize = 4007,
  CurriculumMilestoneAssessment_Write = 4010,
  CurriculumMilestoneAssessment_Read = 4011,
  MHA_EditComplete = 4014,
  BehaviorServicePlan_Write = 4015,
  BehaviorServicePlan_Read = 4016,
  BehaviorServicePlan_EditComplete = 4017,
  BehaviorServicePlan_SubmitForAuthorization = 4018,
  QA_Dashboard = 4020,
  QA_DashboardMaster = 4021,
  CSSRS_Write = 4022,
  CSSRS_Read = 4023,
  ProgressReports_DeleteSignature = 4024,
  BPE_Write = 4025,
  BPE_Read = 4026,
  BPE_EditCompleted = 4027,
  RBT_Write = 4028,
  RBT_Report = 4029,
  AA_Dashboard = 4030,
  MHTreatmentPlan_EditComplete = 4035,
  MHTreatmentPlan_View = 4036,
  MHSafetyPlan_Write = 4040,
  MHSafetyPlan_Read = 4041,
  MHSafetyPlan_EditCompleted = 4042,
  ContingencyPlan_View = 4043,
  SessionDocumentation_Delete = 4045,
  Documentation_Delete = 4046,
  Documentation_Reject = 4047,
  MedClinicDashboard = 4050,
  AllowExemptServiceFromTP = 4052,
  HRPortal_StaffInjuriesQueue = 4053,
  SendIncidentReportToHR = 4054,
  SendIncidentReportToLicensing = 4055,
  SendIncidentByEmail = 4056,
  RejectIncidentReport = 4057,
  IncidentReport_SignAsQA = 4058,
  IncidentReport_SignAsDirector = 4059,
  IncidentReport_DeleteSignature = 4060,
  ExecutiveVicePresident_Dashboard = 4065,
  ClientLogin_OnHold = 4070,
  BillingProcess_Collections = 4075,
  BillingProcess_SendToCollections = 4076,
  BillingProcess_RemoveFromCollections = 4077,
  ClinicianCredentialView = 4080,
  RenderingProvidersView = 4081,
  CAQH_FullAccess = 4085,
  EmployeeReview_ManageTemplate = 4091,
  IncidentReport_AddOtherReason = 4095,
  NeurofeedbackDocumentationsView = 4100,
  EMDRDocumentationsView = 4101,
  EMDRDocumentationsWrite = 4102,
  NeurofeedbackDocumentationsWrite = 4103,
  AcademyAssessment_Reject = 4104,
  AcademyNote_View = 4105,
  IAP_View = 4106,
  IAP_Write = 4107,
  Academy_Supervision = 4108,
  GDMA_ClusterReport = 4110,
  TOBI_ViewClientForm = 4111,
  GDMA_AcceptRejectNA = 4112,
  ReprocessServiceDocumentations = 4113,
  FillPrevisitDiagnosticEncounter = 4115,
  MedClinicAddDiagnosisCodes = 4116,
  BSP_CustomTreatmentRecommendation = 4120,
  CanWriteDocumentationAddendum = 4125,
  CanRequestClientTreatmentDocumentationSignature = 4126,
  CanExemptDocumentationSignature = 4127,
  TreatmentReportsManagement = 4128,
  CanRequestAcademyAssessmentClientSignature = 4129,
  ManageClientPortalLogin = 4500,
  ManageDepartments = 4510,
  Intake_SendPortalLogin = 6000,
  HR_Management = 6001,
  HR_Superadmin = 6002,
  Approve_Document_Upload = 6003,
  BillingProcess_DeleteBillingNote = 6009,
  Facilities_Ticket_Manager = 6010,
  Facilities_Ticket_Director = 6012,
  Can_Submit_FacilitiesTickets = 6013,
  IT_Ticket_Manager = 6014,
  IT_Ticket_Director = 6015,
  IT_Ticket_Submitter = 6020,
  EmployeeFile_UploadOnly = 6030,
  PayrollApprovalAA = 6050,
  Benefits_Manage = 6051,
  Benefits_Access = 6060,
  Ticket_Types_Manager = 6100,
  Can_Delete_Employee_Documents = 6500,
  Equipment_Delete = 7000,
  Application_Dashboard = 7500,
  Application_Dashboard_Director = 7501,
  Application_Dashboard_Executive = 7502,
  ManageClientDocumentCategories = 7601,
  Can_Assign_Equipment_Types = 7800,
  Manage_Equipment_Region = 7801,
  ChatAdmin = 8001,
  ChatUser = 8002,
  Chat_ViewAll = 8003,
  FormBuilder_Manage = 9001,
  FormBuilder_View = 9002,
  FormBuilder_ViewFilledForm = 9003,
  FormBuilder_FillClientForm = 9004,
  CriticalNewsFeed_ManageAll = 9500,
  CriticalNewsFeed_View = 9501,
  CriticalNewsFeed_CanPost = 9502,
  CriticalNewsFeed_CanComment = 9503,
  CriticalNewsFeed_ManagePinnedPosts = 9504,
  CriticalNewsFeed_ManageComments = 9505,
  CanEditUserNames = 11000,
  MedClinic_EmployeeServices_Request = 11001,
  MedClinic_ClientSummaryAccess = 11002,
  MedClinic_Medications_Edit = 11003,
  ScreeningTools_CanView = 11005,
  ScreeningTools_CanAdd = 11006,
  MedClinic_Authorize_Patients = 11007,
  AddNewProjects = 11008,
  EditAllprojects = 11009,
  ManageUsers_BasicEdit = 11010,
  Knowledge_Base_Manager = 11012,
  Knowledge_Base_Executive = 11013,
  Knowledge_Base_Release_Articles = 11014,
  Knowledge_Base_ManageSOM = 11015
}
