import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth';

//import { AccountService } from '../services/account.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        console.error('Unauthorized request ' + request.url + ' signing out');
        //this.authService.signOut();
        this.router.navigate(['/signin']);
      }

      const error = (err.error && err.error.message) ? err.error.message :
        (err.errors && err.errors.model && err.errors.model.length) ? err.errors.model[0] :
          (err.title) ? err.title :
            (err.error || err.statusText);
      return throwError(error);
    }));
  }
}
