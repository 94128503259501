import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserModel } from '../core/api/models';
import {
  StaffHomeComponent

} from '.';

@Component({
  selector: 'staff-main',
  templateUrl: './staff-main.component.html',
  styleUrls: ['./staff-main.component.css']
})
export class StaffMainComponent implements OnInit {

  userInfo: UserModel | null = null;


  pageTitle: string | null = null;
  loading: boolean = false;

  constructor(private router: Router,
    private authService: AuthService) { 
      console.log('StaffMainComponent');
    }



  ngOnInit() {

    this.authService.user().subscribe(user => this.userInfo = user);
    
  }

  activated(comp: any) {
    this.pageTitle = comp.pageTitle || '&nbsp;';
    if (comp instanceof StaffMainComponent) {
      let tComp = comp as StaffMainComponent;
      tComp.userInfo = this.userInfo;
    }
  }

}
