<div class="dashboard">
  <div class="header">
    <div class="client-name">
      {{ this.patient?.FirstName }} {{ this.patient?.LastName }} - Service #{{ this.patientSession?.ServiceID }}
    </div>
    <button (click)="switchToSupervisor()" class="updates-button me-3">
      Supervisor
    </button>
    <!-- <button (click)="switchToGraphs()" class="updates-button me-3">
      Graphs
    </button> -->
  </div>
  <div *ngIf="!sessionID || !patientID" class="text-center w-100 mt-5 text-danger">
Invalid session or patient
  </div>
  <div class="flags-container" *ngIf="sessionID && patientID">
    <div class="flags">
      <div class="top-box">
        <div class="flag flex-column">
          <div *ngIf="!isStarted()">
            Session
            <button class="button-start" (click)="startSession()">Start</button>
          </div>
          <div *ngIf="isStarted()">
            {{ timerString() }}
            <button class="button-end" (click)="endSession()">End</button>
          </div>
        </div>
      </div>

      <div class="flags-horizontal" *ngIf="isStarted()">
        @for (target of favoriteTargets; track target.ID) {
        <app-target-selector
          [target]="target"
          [isBottomBox]="false"
          [promptCodes]="target.PromptCodes??[]"
          [subTargetsDictionary]="subTargetsDictionary"
          [promptSchedules]="promptSchedules" 
        ></app-target-selector>
        }
      </div>
    </div>
  </div>

  <div class="flags" *ngIf="isStarted()">
    @for (target of specialFavoriteTargets; track target.ID) {
    <app-target-selector
      [target]="target"
      [isBottomBox]="false"
      [promptCodes]="target.PromptCodes??[]"
      [subTargetsDictionary]="subTargetsDictionary"
      [promptSchedules]="promptSchedules" ></app-target-selector>
    }
    <div class="flags-horizontal">
      @for (behavior of behaviors; track behavior.ID) { 
        @if(isFrequencyBehavior(behavior)) {
      <app-box-behavior-frequency
        [text]="behavior.PatientBehaviorName ?? ''"
        [showDayValue]="false"
        [behaviorID]="behavior.ID ?? 0"
      ></app-box-behavior-frequency>
      } 
      @if(isDurationBehavior(behavior)) {
      <app-box-behavior-duration
        [text]="behavior.PatientBehaviorName ?? ''"
        [behaviorID]="behavior.ID ?? 0"
      ></app-box-behavior-duration>
      } 
    
    }
    </div>
  </div>
  @if (isStarted()) { @for (reminder of showingReminders; track reminder.ID) {
  <div class="alert" (click)="closeReminder(reminder)">
    {{ reminder.Message }}
  </div>
  } }
  <div class="programs" *ngIf="isStarted()">
    @for (itemArea of groupedShowingItems; track itemArea.Title){

    <div class="program-title">{{ itemArea.Title }}</div>
    @for (item of itemArea.items; track $index) {
    <div class="program-name">{{ item.Name }}</div>
    <div class="program-items">
      @for (target of item.Targets; track target.ID) {
      <app-target-selector
        [target]="target"
        [isBottomBox]="true"
        [promptCodes]="target.PromptCodes??[]"
        [subTargetsDictionary]="subTargetsDictionary"
        [promptSchedules]="promptSchedules" ></app-target-selector>
      }
    </div>
    @if (item.ProgramID){
    <div
      class="program-notes"
      (click)="showProgramNotes(programNotesDiv, programNotesTextarea)"
      (clickOutside)="hideProgramNotes(programNotesDiv, programNotesTextarea)"
      #programNotesDiv
    >
      <span
        >Program Notes:
        <i
          class="pi pi-arrow-down"
          *ngIf="!programNotesTextarea.classList.contains('focused')"
        ></i
      ></span>
      <textarea
        class="program-notes-textarea"
        #programNotesTextarea
        [(ngModel)]="item.ProgramNotes"
        (ngModelChange)="updateProgramNotes(item)"
        (blur)="saveProgramNotes(item.ProgramID, item.ProgramNotes!)"
      ></textarea>
    </div>
    } } }
  </div>

  <div class="programs-list-container" *ngIf="isStarted()">
    <div class="programs-list">
      @for (program of programs; track program.ID; let i = $index) {
      <div class="program-list-item" (click)="selectProgram(program)" [class.selected]="i == selectedProgramIndex">
        <span
          class="badge-count"
          [class.badge-count-green]="
            countMasteredTargets(program) >= (program.Targets?.length ?? 0)
          "
          [class.badge-count-red]="
            countMasteredTargets(program) < (program.Targets?.length ?? 0)
          "
          >{{ countMasteredTargets(program) }}/{{
            program.Targets?.length ?? 0
          }}</span
        >
        <div class="program-list-item-title">Program</div>
        <div class="program-list-item-name">{{ program.ProgramName }}</div>
        <div class="program-list-item-circles">
          @for (counter of program.Counters; track counter.targetStatus) {
          <div
            class="program-list-item-circle"
            [style.background-color]="counter.color"
          >
            {{ counter.count }}
          </div>
          }
        </div>
      </div>
      } @for (playlist of playlists; track playlist.ID; let i = $index) {
      <div class="program-list-item" (click)="selectPlaylist(playlist)"  [class.selected]="i == selectedPlaylistIndex">
        <div class="program-list-item-title">Playlist</div>
        <div class="program-list-item-name">{{ playlist.PlaylistName }}</div>
        <div class="program-list-item-circles"></div>
      </div>
      } @for (target of targets; track target.ID; let i = $index) {
      <div class="program-list-item" (click)="selectTarget(target)" [class.selected]="i == selectedTargetIndex">
        <div class="program-list-item-title">Target</div>
        <div class="program-list-item-name">{{ target.TargetName }}</div>
        <div class="program-list-item-circles"></div>
      </div>
      }
    </div>
    <div class="programs-list-buttons">
      <button class="programs-list-button" *ngIf="false">Filter</button>
      <button class="programs-list-button" (click)="nextOnList()">Next</button>
    </div>
  </div>
</div>

<div class="progress-note" (clickOutside)="hideProgressNote()" *ngIf="isStarted()">
  <div
    class="progress-note-text"
    (click)="showProgressNote()"
    [class.hide]="showingProgressNote"
  >
    <span>Progress Note </span>
    <span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="pi pi-arrow-right"></i
    ></span>
  </div>

  <div
    class="progress-note-text"
    (click)="hideProgressNote()"
    [class.hide]="!showingProgressNote"
  >
    <span>Progress Note </span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="pi pi-arrow-left"></i></span>
  </div>

  <div class="progress-note-inner">
    <div class="progress-note-section" *ngIf="progressNoteStep == 1">
      <h4 class="w-100 text-center">Select all present at session</h4>
      <div class="progress-note-section-content">
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="Parent/Guardian"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >Parent/Guardian</label
          >
        </div>
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="BCBA"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >BCBA</label
          >
        </div>
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="Siblings"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >Siblings</label
          >
        </div>
      </div>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 2">
      <h4 class="w-100 text-center">Session Data Summary</h4>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 3">
      <h4 class="w-100 text-center">Goals</h4>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 4">
      <h4 class="w-100 text-center">Barriers to progress</h4>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 5">
      <h4 class="w-100 text-center">Confirm Session Time</h4>
    </div>
    <div class="flex justify-content-center text-center gap-2 p-3 w-100">
      <p-button
        label="Back"
        class="me-3"
        severity="secondary"
        *ngIf="progressNoteStep > 1"
        (onClick)="backProgressNote()"
      />
      <p-button
        label="Next"
        *ngIf="progressNoteStep < 5"
        (onClick)="nextProgressNote()"
      />
      <p-button
        label="End Session"
        (onClick)="showEndSession = true"
        *ngIf="progressNoteStep == 5"
      />
    </div>
  </div>
</div>

@if (showEndSession) {
<div class="staff-dialog-backdrop" (click)="onCloseEndSession()"></div>
<p-dialog
  header="End Session - Handoff"
  class="staff-dialog"
  [closable]="false"
  [visible]="true"
>
  <app-end-session (close)="onCloseEndSession()" />
</p-dialog>
}

@if (loading) {
<app-loading-indicator />
}
