@if (showGraph()) {
<div class="supervisor-dialog-backdrop" (click)="onShowGraphClicked()"></div>
<dialog class="supervisor-dialog" open>
  <app-target-graph
    [patientId]="patientId()"
    [targetId]="target?.ID!"
  ></app-target-graph>
</dialog>
} @if (isCollapsed) {
<div class="container" (click)="toggleCollapsed()">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ target?.TargetName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-3">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included playlists:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedPlaylists }}
      </span>
    </div>
    <div class="col-3">
      <span class="fs-8 fw-bolder" style="color: #719eb6"> Skill Areas: </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedSkillArea }}
      </span>
    </div>
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
      >
        {{ target?.TargetName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-3">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included playlists:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedPlaylists }}
      </span>
    </div>
    <div class="col-2">
      <span class="fs-8 fw-bolder" style="color: #719eb6"> Skill Areas: </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedSkillArea }}
      </span>
    </div>
    <div class="col-1">
      <button (click)="onShowGraphClicked()" class="side-menu-button2">
        Graph
      </button>
    </div>
    <div class="col-1">
      <button (click)="editTarget()" class="side-menu-button2">Edit</button>
    </div>
    <div class="col-1">
      <button (click)="deleteTarget()" class="switch-mode-button">
        Delete
      </button>
    </div>
  </div>
  <div class="row">
    <p-table [value]="[target]" [tableStyle]="{ 'min-width': '40rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th style="text-align: center">Goal Name</th>
          <th style="text-align: center">SD</th>
          <th style="text-align: center">Desired Trials</th>
          <th style="text-align: center">Target Type</th>
          <th style="text-align: center">Instructions</th>
          <th style="text-align: center">Favorite</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-target>
        <tr>
          <td style="text-align: center">{{ target.GoalName }}</td>
          <td style="text-align: center">
            {{ target.DiscriminativeStimulus }}
          </td>
          <td style="text-align: center">{{ target.DesiredTrials }}</td>
          <td style="text-align: center">
            {{ targetTypeToDisplay(target.TargetType) }}
          </td>
          <td style="text-align: center">{{ target.Instructions }}</td>
          <td style="text-align: center">
            {{
              target.IsFavorite
                ? "Yes"
                : target.IsSpecialFavorite
                ? "Special"
                : "No"
            }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No items found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
}
