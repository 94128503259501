import { AfterViewInit, Component, effect, EventEmitter, OnInit, Output } from '@angular/core';
import { StaffApiService } from '../../../core/api/services';
import { PatientSessionService } from '../patient-session.service';
import { PatientSessionModel } from '../../../core/api/models';
import { SelectItem } from 'primeng/api';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-end-session',
  templateUrl: './end-session.component.html',
  styleUrl: './end-session.component.css'
})
export class EndSessionComponent {
  @Output() close = new EventEmitter<void>();

  handoff: 'staff' | 'guardian' = 'staff';
  guardian: any;
  explain: string = '';
  step: 'start' | 'nextStaff' | 'nextGuardian' | 'NoOnStaffHandoff' | 'YesOnStaffHandoff' | 'NoOnGuardianHandoff' | 'YesOnGuardianHandoff' = 'start';
  staffName: string = 'TO DO - next StaffName here';
  guardians: any[] = [];

  patientSession: PatientSessionModel | null = null;


  constructor(
    private staffApiService: StaffApiService,
    private patientSessionService: PatientSessionService,
    private authService: AuthService) {
    effect(() => {
      this.patientSession = this.patientSessionService.patientSession();

      if (this.patientSession) {
        this.staffApiService.GetPatientGuardians({ patientId: this.patientSession.PatientID }).subscribe(guardians => {
          this.guardians = guardians;
        });
      }
    });
  }


  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    if (this.step == 'NoOnStaffHandoff' || this.step == 'NoOnGuardianHandoff') {
      this.patientSession!.Explanation = this.explain;
    }
    if (this.step == 'YesOnGuardianHandoff') {
      this.patientSession!.EFI_LiablePersonID = this.guardian.EFI_LiablePersonID;
    }
    this.saveSession();
    const serviceID = this.patientSession!.ServiceID;
    this.patientSessionService.endSession().subscribe(() => { 
      this.close.emit();
      window.location.href = this.authService.getOptoURL()?(this.authService.getOptoURL()+'/DailyTreatment/Services/View/'+serviceID):'/';
    });
  }

  onNextOnHandoff() {
    if (this.handoff == 'staff') {
      this.step = 'nextStaff';
      this.patientSession!.IsHandoffToStaff = true;
      this.patientSession!.IsHandoffToParent = false;

    } else {
      this.step = 'nextGuardian';
      this.patientSession!.IsHandoffToStaff = false;
      this.patientSession!.IsHandoffToParent = true;
    }
    this.saveSession();
  }

  onNoOnStaffHandoff() {
    this.step = 'NoOnStaffHandoff';
  }

  onNoOnGuardianHandoff() {
    this.step = 'NoOnGuardianHandoff';
  }

  onYesOnGuardianHandoff() {
    this.step = 'YesOnGuardianHandoff';
  }

  saveSession() {
    this.patientSessionService.patientSession.update(patientSession => {
      return Object.assign({}, this.patientSession);
    });
  }
}
