import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel, PatientTargetStatus, PromptCodeModel, PromptScheduleModel} from '../../../core/api/models';
import { getPatientTargetStatusColor, getPatientTargetStatusLabel } from '../../../shared/utils/utils';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-box-echoic',
  templateUrl: './box-echoic.component.html',

})
export class BoxEchoicComponent extends BaseBoxComponent {
  @Input() text: string|undefined = '-';
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;  
  @Input() requiredTrials: number = 3;
  @Input() targetID: number = 0; 
  @Input() targetStatus: PatientTargetStatus = PatientTargetStatus.Unspecified; 
  @Input() options: PromptCodeModel[] = [  ];
  @Input() currentPromptCodeID?: number;
  @Input() promptSchedule?: PromptScheduleModel|null;

  @Input() rates: {name: string, icon: string, color: string}[] = [
    {
      name: 'Articulation',
      icon: '0',
      color: '#5DADE2',
    },
    {
      name: 'Volume',
      icon: '.5',
      color: '#5DADE2',
    },
    {
      name: 'Speed',
      icon: 'NA',
      color: '#5DADE2',
    },
    {
      name: 'Tone',
      icon: '1',
      color: '#5DADE2',
    },
    {
      name: 'Rhythm',
      icon: '1',
      color: '#5DADE2',
    }
  ];
  
  selectedOption: PromptCodeModel | null = null;;
  selectedOptionRecordedDate: string | undefined = undefined;
  justSelectedOption: any;
  selectedRate: any;
  isPlayingVideo: boolean = false;
  totalSuccess: number = 0;
  selectedOptions: any[] = [];
  targetData: PatientTargetDataModel[] = [];


  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData) {
        this.targetData = targetData;
        //Add the options inside targetData to the selectedOptions based on the options available in the options array
        this.selectedOptions = targetData.map(t => this.options.find(o => o.ID ===  t.IntValue)).filter(o => o !== undefined) as PromptCodeModel[];
        this.totalSuccess = this.selectedOptions.filter(o => o.IsIndependent).length;
        if (this.selectedOptions.length > 0) {
          this.selectedOption = this.selectedOptions[this.selectedOptions.length-1];
        } else {
          this.selectedOption = null;
        }
      }
      else {
        this.targetData = [];
        this.selectedOptions = [];
        this.totalSuccess = 0;
        this.selectedOption = null;
      }
    });
  }

  ngOnInit(): void {
    if (!this.currentPromptCodeID) {
      this.currentPromptCodeID = this.options[0].ID ?? 0;
    }
  }

  override showExtra(): void {
    this.justSelectedOption = null;
    this.selectedOption = null;
    this.selectedRate = null;
    this.isPlayingVideo = false;
    this.isRecording = false;
    super.showExtra();
  }
  
  selectOption(option: any) {
    // Store the selected option
    this.selectedOption = option;
    this.justSelectedOption = option;

    // Initialize the selectedOptions array if it doesn't exist
    if (!this.selectedOptions) {
      this.selectedOptions = [];
    }


    // Add the selected option to the array
    this.selectedOptions.push(option);
    
    this.totalSuccess = this.selectedOptions.filter(o => o.IsIndependent).length;
    this.saveOption(option);

  }

  selectRate(rate: any) {
    this.selectedRate = rate;

    //change targetData with the selected rate on patientServicde
    this.patientSessionService.saveTargetData(
      {
        IntValue: this.selectedOption?.ID,
        TextValue: this.selectedOption?.Text+'|'+rate.name,
        PatientTargetID: this.targetID,
        RecordedDate: this.selectedOptionRecordedDate,
      }
    )

    // Close the extra container after selection
    this.closeExtra();
  }

  
  saveOption(option: PromptCodeModel) {
    this.selectedOption = option;
    this.selectedOptionRecordedDate = new Date().toServerString();
    this.patientSessionService.addTargetData({
      TextValue: option.Text,
      IntValue: option.ID,
      PatientTargetID: this.targetID,
      RecordedDate: this.selectedOptionRecordedDate,
    });
  }
  
  private videoStream: MediaStream | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  isRecording: boolean = false;

  record() {
    this.isRecording = true;
    this.selectedRate = {
      name: 'Record',
      icon: 'R',
      color: '#5DADE2',
    };
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoStream = stream;
          const videoElement = document.querySelector('video#recordingVideo') as HTMLVideoElement;
          if (videoElement) {
            videoElement.srcObject = stream;
            videoElement.play();
          }

          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
            if (this.selectedRate) {
              this.selectedRate.videoBlob = blob;
              this.selectedRate.videoUrl = URL.createObjectURL(blob);
            } else {
              console.error('No selected option to attach the video to.');
            }
          };

          this.mediaRecorder.start();
        })
        .catch(error => {
          console.error('Error accessing media devices.', error);
        });
    } else {
      console.error('getUserMedia not supported on your browser!');
    }
  }

  stopRecording() {
    this.isRecording = false;
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      this.videoStream?.getTracks().forEach(track => track.stop());
    }
  }

  playVideo() {
    const videoElement = document.querySelector('video#recordedVideo') as HTMLVideoElement;
    if (videoElement) {
      this.isPlayingVideo = true;
      videoElement.play();
      videoElement.classList.remove('hide');
      videoElement.onended = () => {
        videoElement.classList.add('hide');
        this.isPlayingVideo = false;
      };
    }
  }

  getStatusText() {
    return getPatientTargetStatusLabel(this.targetStatus);
  }

  getStatusColor() {
    return getPatientTargetStatusColor(this.targetStatus);
  }
  
  getIcon(option: PromptCodeModel): string {
    //get first letter of the text
    return option.Abbreviation ?? '';
  }
}