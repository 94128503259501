import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToolbarModule } from 'primeng/toolbar';
import { SettingsRoutingModule } from './settings.routing.module';
import { SettingsMainComponent } from './settings-main.component';
import { PromptCodesComponent } from './prompt-codes/prompt-codes.component';
import { PromptCodeComponent } from './prompt-codes/prompt-code/prompt-code.component';
import { AddPromptCodeComponent } from './prompt-codes/add-prompt-code/add-prompt-code.component';
import { PatientSessionService } from '../staff/home/patient-session.service';
import { PromptSchedulesComponent } from './prompt-schedules/prompt-schedules.component';
import { PromptScheduleComponent } from './prompt-schedules/prompt-schedule/prompt-schedule.component';
import { AddPromptScheduleComponent } from './prompt-schedules/add-prompt-schedule/add-prompt-schedule.component';

const pages = [
  // SettingsMainComponent,
  // PromptCodesComponent,
  // PromptCodeComponent,
  // AddPromptCodeComponent,
  // PromptSchedulesComponent,
  // PromptScheduleComponent,
  // AddPromptScheduleComponent,
  // Add other settings-related components here as they're created
];


@NgModule({
    imports: [
      CommonModule,
      SharedModule,
      SettingsRoutingModule,
      InputSwitchModule,
      ToolbarModule,
    ],
    //declarations: [...pages],
  })
export class SettingsModule {
  constructor(@Optional() @SkipSelf() parentModule: SettingsModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import in your base AppModule only.'
      );
    }
  }
} 