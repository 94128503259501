
  <h2>Add Staff Alert</h2>
  <form (ngSubmit)="onSubmit()">
    <p>
      <label for="staffName">Staff Name</label>
      <input type="text" id="staffName" name="staffName" [(ngModel)]="enteredStaffName" />
    </p>

    <p>
        <label for="message">Alert Message</label>
        <input type="text" id="message" name="message" [(ngModel)]="enteredAlertMessage" />
    </p>

    <p>

    <label for="program">Program</label>
    <select id="program" name="program" [(ngModel)]="selectedProgramId">
        @for (program of programs; track program.ID) {
            <option value="{{program.ID}}">{{program.ProgramName}}</option>
        }
    </select>

    <p class="actions">
      <button type="button" (click)="onCancel()">Cancel</button>
      <button type="submit">Add Alert</button>
    </p>
  </form>