import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect, OnInit } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel, PatientTargetModel, PatientTargetStatus, PromptCodeModel, PromptScheduleModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';
import { PatientTargetForChainModel } from '../../../shared/models/models';
@Component({
  selector: 'app-box-grouped-frequency',
  templateUrl: './box-grouped-frequency.component.html',
})
export class BoxGroupedFrequencyComponent extends BaseBoxComponent implements OnInit {
  @Input() subTargetsDictionary: { [key: number]: PatientTargetForChainModel[] } = {};
  @Input() subTargets: PatientTargetForChainModel[] = [];
  @Input() isBottomBox: boolean = false;
  @Input() isTopBox: boolean = true;
  @Input() text: string | null | undefined = '-';
  @Input() targetID: number = 0;
  @Input() isChain: boolean = false;
  @Input() isBackwards: boolean = false;
  @Input() promptSchedules: PromptScheduleModel[] = [];
  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
  }

  ngOnInit(): void {
    console.log(this.subTargets);
    if (this.isChain) {
//Change subtargets  to have the enabled flag true only if it is the first target or if the previous one is mastered. Make this inverse if the chain isBackwards
    this.subTargets.forEach((target, index) => {
      if (this.isBackwards) {
        target.Enabled = index === this.subTargets.length - 1 || this.subTargets[index + 1].TargetStatus === PatientTargetStatus.Mastered;
      } else {
        target.Enabled = index === 0 || this.subTargets[index - 1].TargetStatus === PatientTargetStatus.Mastered;
      }
    });


    }
  }

}
