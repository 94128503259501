
<button (click)="addReminderButtonClicked()" class="side-menu-button2 w-100 ">
  + Reminder
</button>
<ul id="skillAreas">
  @if (loadingReminders) {
  <app-loading-indicator />
  } @else { @for (reminder of reminders; track reminder.ID) {
  <app-reminder [reminder]="reminder"></app-reminder>
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no Reminders yet.
  </p>
  } }
</ul>
