<div class="dashboard">
  <div class="header">
    <div class="side-menu-buttons">
      <div class="client-name">
        {{ selectedPatient?.FirstName + " " + selectedPatient?.LastName }}
      </div>

      <div class="horizontal-flex-list">@if (selectedPatient) { }</div>
    </div>
    <button class="side-menu-button2" (click)="openMenu = !openMenu">
      Menu
    </button>
    <div class="side-menu-buttons menu-top" *ngIf="openMenu">
      <button
        (click)="switchToStaff()"
        *ngIf="this.patientSessionService.patientSession()?.ID"
        class="switch-mode-button"
      >
        Back to Session
      </button>

      <button (click)="switchToSessionList()" class="side-menu-button2">
        Session List
      </button>

      <button
        [routerLink]="['/supervisor', selectedPatient?.ID]"
        class="side-menu-button2"
      >
        Supervisor
      </button>

      <button
        [routerLink]="['/settings/prompt-codes']"
        class="side-menu-button2"
      >
        General Settings
      </button>

      <button (click)="backToOpto()" class="side-menu-button2">
        Back to Opto
      </button>
    </div>
  </div>
  @if (selectedPatient) {
  <div class="modules-container">
    <!-- <div class="horizontal-flex-list">
      <a
        [routerLink]="['/graphs', selectedPatient.ID, 'targets']"
        routerLinkActive="selected"
      >
        <span>Targets</span>
      </a>
      <a
        [routerLink]="['/graphs', selectedPatient.ID, 'programs']"
        routerLinkActive="selected"
      >
        <span>Programs</span>
      </a>
    </div> -->

    <router-outlet (activate)="activated($event)"></router-outlet>
  </div>
  }
</div>
