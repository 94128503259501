import {
  Component,
  computed,
  effect,
  input,
  OnInit,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

import { SupervisorApiService } from '../../core/api/services';
import {
  PatientProgramModel,
  PatientTargetModel,
  SkillAreaModel,
} from '../../core/api/models';
import { SupervisorHelperService } from '../supervisor.helper.service';
import { SupervisorBaseComponent } from '../supervisor-base.component';
import { AlertService } from '../../shared/services/alert.service';
import { SelectItemGroup } from 'primeng/api';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrl: '../supervisor-main.component.css',
})
export class ProgramsComponent extends SupervisorBaseComponent {
  patientId = input.required<string>();
  patientIdAsNumber = signal<number | null>(null);
  selectedFilters = signal<any[]>([]);

  searchKeyword = signal<string | null>(null);

  showAddProgram = this.supervisorHelperService.showAddProgram;

  possibleFilters = computed<SelectItemGroup[]>(() => {
    let skillAreas = this.supervisorHelperService.skillAreas();
    let targets = this.supervisorHelperService.targets();
    return [
      {
        label: 'Skill Areas',
        items: skillAreas.map((skillArea) => {
          return {
            label: skillArea.SkillAreaName ?? 'No Name for Skill Area',
            value: skillArea,
          };
        }),
      },
      {
        label: 'Targets',
        items: targets.map((target) => {
          return {
            label: target.TargetName ?? 'No Name for Target',
            value: target,
          };
        }),
      },
    ];
  });

  filteredPrograms = computed(() => {
    let filteredPrograms = this.supervisorHelperService.programs();
    // use keyword from search bar for the first filter
    if (this.searchKeyword() != null && this.searchKeyword() != '') {
      filteredPrograms = filteredPrograms.filter((program) =>
        program.ProgramName?.toLowerCase().includes(
          this.searchKeyword()!.toLowerCase()
        )
      );
    }
    if (this.selectedFilters() == null) {
      return filteredPrograms;
    }
    this.selectedFilters().forEach((selectedFilter) => {
      if (this.supervisorHelperService.isSkillAreaModel(selectedFilter)) {
        filteredPrograms = filteredPrograms.filter(
          (program) => program.SkillAreaID === selectedFilter.ID
        );
      }
      if (this.supervisorHelperService.isPatientTargetModel(selectedFilter)) {
        filteredPrograms = filteredPrograms.filter((program) =>
          program.Targets?.some((target) => target.ID === selectedFilter.ID)
        );
      }
    });
    return filteredPrograms;
  });

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      if (this.patientId() != 'null') {
        this.loadBaseData(Number(this.patientId()));
      }
    });

  }

  ngOnInit(): void {
    this.patientIdAsNumber.set(Number(this.patientId()));
  }

  nameForFilter(filter: any): string {
    if (this.supervisorHelperService.isSkillAreaModel(filter)) {
      return filter.SkillAreaName ?? 'No Name for Skill Area';
    }
    if (this.supervisorHelperService.isPatientTargetModel(filter)) {
      return filter.TargetName ?? 'No Name for Target';
    }

    return '';
  }

  searchProgram($event: AutoCompleteCompleteEvent) {
    console.log('searchProgram');
  }

  
  addProgramButtonClicked() {
    console.log('addProgramButtonClicked');
    this.supervisorHelperService.additProgramButtonClicked(null);
  }

  
  onCloseAddProgram() {
    console.log('onCloseAddProgram');
    this.supervisorHelperService.onCloseAddProgram();
  }
}
