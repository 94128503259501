import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MessageService } from 'primeng/api';

import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ApiModule } from './core/api/api.module';
import { AuthModule } from './auth/auth.module';
import { StaffModule } from './staff/staff.module';
import { SupervisorModule } from './supervisor/supervisor.module';
import { GraphsModule } from './graphs/graphs.module';
import { SettingsModule } from './settings/settings.module';
import { PatientSessionService } from './staff/home/patient-session.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    //ApiModule,

    ApiModule.forRoot({
      rootUrl: environment.webApiRoot,
    }),

    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CoreModule.forRoot(),
    AuthModule,
    StaffModule,
    SupervisorModule,
    GraphsModule,
    SettingsModule,
  ],
  providers: [
    MessageService,
    PatientSessionService, 
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
