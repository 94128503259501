@if (target) {
    @if (isDurationTarget(target)) {
    <app-box-duration [isBottomBox]="isBottomBox" [text]="target.TargetName?.toString()"
        [targetID]="target.ID??0"></app-box-duration>
    }
    @if (isFrequencyTarget(target)) {
    <app-box-frequency [isBottomBox]="isBottomBox" [dayValue]="0" [showDayValue]="false" [targetID]="target.ID??0"
        [text]="target.TargetName?.toString()"></app-box-frequency>
    }
    @if (isGroupedFrequencyTarget(target) || isConditionalDiscriminationTarget(target) || isTaskAnalysisTarget(target)) {
    <app-box-grouped-frequency [isBottomBox]="isBottomBox" [targetID]="target.ID??0" [subTargetsDictionary]="subTargetsDictionary"
        [text]="target.TargetName?.toString()" 
        [subTargets]="subTargetsDictionary[target.ID ?? 0]" [isChain]="isTaskAnalysisTarget(target)" [isBackwards]="target.IsBackward??false" 
        [promptSchedules]="promptSchedules??[]" ></app-box-grouped-frequency>
    }
    @if (isDttTarget(target)) {
    <app-box-dtt [isBottomBox]="isBottomBox" [text]="target.TargetName?.toString()" [requiredTrials]="target.DesiredTrials??1"
        [targetID]="target.ID??0" [targetStatus]="targetStatus" 
        [options]="promptCodes" [enabled]="enabled" [currentPromptCodeID]="target.CurrentPromptCodeID??0" [promptSchedule]="promptSchedule"></app-box-dtt>
    }
    @if (isEchoicTarget(target)) {
    <app-box-echoic [isBottomBox]="isBottomBox" [text]="target.TargetName?.toString()" [requiredTrials]="target.DesiredTrials??1"
        [targetID]="target.ID??0" [targetStatus]="targetStatus"
        [options]="promptCodes" [currentPromptCodeID]="target.CurrentPromptCodeID??0" [promptSchedule]="promptSchedule"></app-box-echoic>
    }
    @if (isFluencyRateTarget(target)) {
        <app-box-fluencyrate [isBottomBox]="isBottomBox" [text]="target.TargetName?.toString()" [targetID]="target.ID??0"
        [expectedAmount]="target.DesiredTrials??1" [timerInitialValue]="target.Duration??60"></app-box-fluencyrate>
    }
    @if (isQuantityTarget(target)) {
        <app-box-quantity [isBottomBox]="isBottomBox" [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1"
        [unit]="target.QuantityUnit??'units'" [targetID]="target.ID??0"></app-box-quantity>
    }
    @if (isAnecdotalTarget(target)) {
        <app-box-anecdotal [isBottomBox]="isBottomBox" [text]="target.TargetName?.toString()" [targetID]="target.ID??0"
            [expectedAmount]="target.DesiredTrials??1"></app-box-anecdotal>
    }
}