import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PatientProgramModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';

@Component({
  selector: 'app-add-staff-alert',
  templateUrl: './add-staff-alert.component.html',
  styleUrl: './add-staff-alert.component.css'
})
export class AddStaffAlertComponent implements OnInit {

  @Output() close = new EventEmitter<void>();

  programs: PatientProgramModel[] = [];
  
  enteredStaffName = '';
  enteredAlertMessage = '';
  selectedProgramId = '';
  
  constructor(private supervisorApiService: SupervisorApiService) {}

  ngOnInit() {
    // this.programs = this.programsService.programs();
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    // this.programsService.addAlertForProgram({ staffName: this.enteredStaffName, message: this.enteredAlertMessage }, this.selectedProgramId);
    this.close.emit();
  }
}
