import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientBehaviorAntecedentModel, PatientBehaviorConsequenceModel, PatientBehaviorDataModel, PatientBehaviorLocationModel, PatientBehaviorModel, PatientBehaviorResponseModel, PatientBehaviorSeverityModel, PatientBehaviorType } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';
import { ABCDataPanelComponent } from '../abc-data-panel/abc-data-panel.component';

@Component({
  selector: 'app-box-behavior-frequency',
  templateUrl: './box-behavior-frequency.component.html',
})
export class BoxBehaviorFrequencyComponent extends BaseBoxComponent {
  @Input() text: string | null | undefined = '-';
  @Input() dayValue: number = 0;
  @Input() sessionValue: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() showDayValue: boolean = true;
  @Input() behaviorID: number = 0;

  behaviorData: PatientBehaviorDataModel[] = [];
  isEditing: boolean = false;
  behaviorType: PatientBehaviorType = PatientBehaviorType.Frequency;


  @ViewChild('inputField') inputField!: ElementRef;

  @ViewChild('abcDataPanel') abcDataPanel!: ABCDataPanelComponent;

  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const behaviorData = this.patientSessionService.geBehaviorData(this.behaviorID);
      if (behaviorData && behaviorData.length > 0) {
        this.behaviorData = behaviorData;
        this.sessionValue = behaviorData[behaviorData.length - 1].IntValue || 0;
      }
      else {
        this.sessionValue = 0;
      }
    });
  }


  setExtraBoxes(): void {
    const dropdowns = document.querySelectorAll('.p-overlay.p-component');
    //convert Element to ElementRef
    this.extraBoxes = Array.from(dropdowns).map(dropdown => new ElementRef(dropdown));
    this.extraBoxes.push(this.extraBox);
  }

  override onClickOutside() {
    this.isEditing = false;
    super.onClickOutside();
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.abcDataPanel.setLastEditingABCData();
    } else {
      this.abcDataPanel.clearEditingABCData();
    }
  }

  increaseSessionValue(): void {
    this.sessionValue++;
    this.addSessionValue();
  }

  addSessionValue(): void {
    let behaviorData: PatientBehaviorDataModel = {
      TextValue: this.sessionValue.toString(),
      IntValue: this.sessionValue,
      PatientSessionID: 0,
      PatientBehaviorID: this.behaviorID,
      RecordedDate: new Date().toServerString()
    };
    this.patientSessionService.addBehaviorData(behaviorData);
  }

  clickedBox(): void {
    if (!this.isEditing) {
      this.increaseSessionValue();
    }
  }

  decreaseSessionValue(): void {
    if (this.sessionValue > 0) {
      this.sessionValue--;
      this.addSessionValue();
    }
  }


  override doLongPress(): void {
    this.toggleEdit();
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.focus();
    }
    this.showExtra();

    setTimeout(() => {
      //scroll tabs to end
      const tabs = document.querySelectorAll('.abc-tabs');
      tabs.forEach(tab => {
        tab.scrollLeft = tab.scrollWidth;
      });
    }, 100);
  }

}
