<div #mainBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="pointer grouped-box" 
(clickOutside)="onClickOutside()"
[additionalElementRefs]="extraBoxes">
    <div class="grouped-box-title">{{text}}</div>
    @for (target of subTargets; track target.ID) {
        <app-target-selector [target]="target" [isBottomBox]="isBottomBox" 
        [promptCodes]="target.PromptCodes??[]" [subTargetsDictionary]="subTargetsDictionary"
        [promptSchedules]="promptSchedules" 
        [enabled]="target.Enabled"></app-target-selector>
    }
</div>