import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { CustomErrorHandler } from './error.handler';


function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}


@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule,
  ]
})

export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true
        },
        CustomErrorHandler
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule,
    @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your CoreModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
