import { Component, effect, input } from '@angular/core';
import { MaintenanceCriteriaFullModel, MasteryCriteriaModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../settings.helper.service';
import { Utils } from '../../core/utils';
import { forkJoin } from 'rxjs';
import { getPatientTargetStatuses } from '../../shared/utils/utils';


@Component({
  selector: 'app-mastery-criteria',
  templateUrl: './mastery-criteria.component.html',
})
export class MasteryCriteriaComponent {
  patientId = input<string | undefined>();

  masteryCriteria: MasteryCriteriaModel = {};
  targetStatuses: any[] = [];
  loadingMasteryCriteria: boolean = false;
  hasCustomMasteryCriteria: boolean = false;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('MaintananceCriteriaComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingMasteryCriteria = true;
    const patinetIdToUse = this.patientId() && !isNaN(Number(this.patientId())) ? Number(this.patientId()) : undefined;
    if (!patinetIdToUse) {
      this.hasCustomMasteryCriteria = true;
    }
    const GetMasteryCriteriaObservable = this.supervisorApiService
      .GetMasteryCriteria({ patientID: patinetIdToUse });

    this.targetStatuses = getPatientTargetStatuses();

    GetMasteryCriteriaObservable
      .subscribe((masteryCriteria) => {
        if (masteryCriteria) {
          this.hasCustomMasteryCriteria = true;
          this.masteryCriteria = masteryCriteria;
        }
        else {
          this.masteryCriteria = { PatientID: patinetIdToUse };
        }
        this.loadingMasteryCriteria = false;
      }, (err) => {
        this.loadingMasteryCriteria = false;
        this.alertService.error(Utils.getAjaxError(err));
      });

  }


  save() {
    this.loadingMasteryCriteria = true;
    this.supervisorApiService.SaveMasteryCriteria({ body: this.masteryCriteria }).subscribe(() => {
      this.loadingMasteryCriteria = false;
      this.hasCustomMasteryCriteria = true;
      this.alertService.success('Mastery criteria saved successfully');
    }, (err) => {
      this.loadingMasteryCriteria = false;
      this.alertService.error(Utils.getAjaxError(err));
    });
  }

  enableCustomMasteryCriteria() {
    this.hasCustomMasteryCriteria = true;
  }
}
