<h2 class="form-title">{{ titleName }}</h2>
<div *ngIf="loading">
  <app-loading-indicator></app-loading-indicator>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="control">
    <label for="name">Name</label>
    <input class="form-control" type="text" id="name" name="name" [(ngModel)]="name" />
  </div>

  <div class="control">
    <label for="message">Message</label>
    <textarea
      class="form-control"
      id="message"
      rows="3"
      name="message"
      [(ngModel)]="message"
    ></textarea>
  </div>

  <div class="control">
    <label for="interval">Interval(in seconds)</label>
    <input class="form-control" type="number" id="interval" name="interval" [(ngModel)]="interval" />
  </div>

  <div class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit">{{ actionName }}</button>
  </div>
</form>
