import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientModel, PatientBehaviorAntecedentModel, PatientBehaviorConsequenceModel } from '../../../../core/api/models';
import { StaffApiService } from '../../../../core/api/services';
import { SupervisorHelperService } from '../../../supervisor.helper.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { Utils } from '../../../../core/utils';

@Component({
  selector: 'app-add-consequence',
  templateUrl: './add-consequence.component.html',
})
export class AddConsequenceComponent {
  @Input({ required: true }) patientId!: number;
  consequenceToEdit: PatientBehaviorConsequenceModel | null = null;

  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  titleName: string = 'Add Antecedent';
  actionName: string = 'Add';

  consequenceId: number | null = null;
  name = '';
  message = '';
  interval = 0;

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    // EDIT LOGIC
    if (this.supervisorHelperService.consequenceToEdit() != null) {
      this.titleName = 'Edit Consequence';
      this.actionName = 'Save';
      this.consequenceToEdit = this.supervisorHelperService.consequenceToEdit();
      this.consequenceId = this.consequenceToEdit?.ID ?? 0;
      this.name = this.consequenceToEdit?.PatientBehaviorConsequence ?? '';
    }
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    this.staffApiService
      .SavePatientBehaviorConsequence({
        body: {
          ID: this.consequenceId,
          PatientBehaviorConsequence: this.name,
          PatientID: this.patientId,
        },
      })
      .subscribe(
        (consequence) => {
          console.log(consequence);
          this.loading = false;
          this.alertService.success('Consequence saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
