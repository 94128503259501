<div>
  <div class="control-row">
    <div class="control no-margin">
      <label for="selectedPrograms">Programs:</label>
      <p-multiSelect
        appendTo="body"
        styleClass="w-100"
        [options]="programs()"
        placeholder="Select Programs"
        optionLabel="ProgramName"
        optionValue="ID"
        [filter]="true"
        filterBy="ProgramName"
        display="chip"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
        (onChange)="onProgramsChange($event)"
      >
        <ng-template pTemplate="selectedProgram" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.ProgramName }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
  <div class="card">
    <div
      class="card-body"
      style="padding-left: 5rem; padding-right: 5rem; padding-bottom: 5rem"
    >
      <p-dropdown
        [options]="dateOptions"
        [(ngModel)]="dateOption"
        appendTo="body"
        styleClass="w-100"
        optionValue="value"
        optionLabel="text"
        (onChange)="onDateOptionChange($event)"
      />
      <div
        style="
          text-align: center;
          background-color: white;
          border: 2px solid cornflowerblue;
          border-radius: 20px;
        "
      >
        <apx-chart
          #chart
          [series]="chartOptions.series || []"
          [chart]="chartOptions.chart || { type: 'line' }"
          [xaxis]="chartOptions.xaxis || {}"
          [title]="chartOptions.title || {}"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
