<div class="w-100 d-flex justify-content-center" *ngIf="promptSchedules.length > 0">
  <button (click)="addPromptScheduleButtonClicked()" class="side-menu-button2 w-100 ">
    + Prompt Schedule
  </button>
</div>
<ul id="skillAreas">
  @if (loadingPromptSchedules) {
  <app-loading-indicator />
  } @else { @for (promptSchedule of promptSchedules; track promptSchedule.ID) {
  <app-prompt-schedule [promptSchedule]="promptSchedule"></app-prompt-schedule>
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no Prompt Schedules yet.
  </p>
  } }
</ul>


@if (showAddPromptSchedule()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddPromptSchedule()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-prompt-schedule [patientId]="null" (close)="onCloseAddPromptSchedule()" />
  </dialog>
  }