import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import {
  MaintenanceCriteriaModel,
  PatientModel,
  PatientPlaylistModel,
  PatientProgramModel,
  PatientTargetModel,
  PatientTargetStatus,
  PromptScheduleModel,
  SkillAreaModel,
  TargetFailOption,
  TargetType,
  TreatmentGoalModel,
} from '../../../core/api/models';
import { Utils } from '../../../core/utils';
import { AlertService } from '../../../shared/services/alert.service';
import { SupervisorApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { formatDate } from '@angular/common';
import { SupervisorBaseComponent } from '../../supervisor-base.component';
import { getPatientTargetStatuses } from '../../../shared/utils/utils';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-target',
  templateUrl: './add-target.component.html',
  styleUrl: './add-target.component.css',
})
export class AddTargetComponent
  extends SupervisorBaseComponent
  implements OnInit {
  @Input() selectedPatient: PatientModel | null = null;
  @Output() close = new EventEmitter<void>();

  selectedPrograms: PatientProgramModel | null = null;

  selectedPlaylists: PatientPlaylistModel | null = null;

  promptSchedules: PromptScheduleModel[] = [];
  selectedPromptSchedule: any | null = null;//PromptScheduleModel | null = null;

  treatmentGoals: TreatmentGoalModel[] = [];

  selectedSubTargets: PatientTargetModel[] = [];

  skillAreas: SkillAreaModel[] = [];
  selectedSkillArea: SkillAreaModel | null = null;

  targetTypes: any[] = [];

  targetStatuses: any[] = getPatientTargetStatuses();
  selectedStatus = PatientTargetStatus.Unspecified;
  maintenanceCriterias: {text: string, value: number}[] = [];

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
  }

  // TODO: Add a method to get the BSP/BIP to fill in the goalName

  targetForm = new FormGroup({
    targetName: new FormControl<string | null>(null, {
      validators: [Validators.required, Validators.maxLength(50)],
    }),
    goalName: new FormControl<string | null>(null, {
      validators: [Validators.maxLength(255)],
    }),
    BSP_TreatmentGoalID: new FormControl<number | null>(null, {
      validators: [],
    }),
    discriminativeStimulus: new FormControl<string | null>(null, {
      validators: [Validators.maxLength(200)],
    }),
    desiredTrials: new FormControl<number | null>(1, {
      validators: [Validators.min(0), Validators.max(100)],
    }),
    targetType: new FormControl<TargetType>(TargetType.Anecdotal, {
      validators: [Validators.required],
    }),
    duration: new FormControl<number | null>(0),
    targetStatus: new FormControl<PatientTargetStatus>(
      PatientTargetStatus.Unspecified
    ),
    PatientProgramIDs: new FormControl<number[] | null>(null),
    PatientPlaylistIDs: new FormControl<number[] | null>(null),
    PromptScheduleID: new FormControl<number | null>(null),
    instructions: new FormControl<string | null>(null),
    IsBackward: new FormControl<boolean | null>(false),
    isFavorite: new FormControl<boolean | null>(false),
    isSpecialFavorite: new FormControl<boolean | null>(false),
    SubTargetIDs: new FormControl<number[] | null>(null),
    // FirstTrialMustBeIndependent: new FormControl<boolean>(false),
    // consecutiveDays: new FormControl<number | null>(null),
    // responsePercentage: new FormControl<number | null>(null),
    DateBaselined: new FormControl<string | null>(null),
    DateClosed: new FormControl<string | null>(null),
    DateMastered: new FormControl<string | null>(null),
    DateOpened: new FormControl<string | null>(null),
    QuantityUnit: new FormControl<string | null>(null),
    SkillAreaID: new FormControl<number | null>(null),
    ShouldMasterAutomatic: new FormControl<boolean>(false),
  });

  currentTarget?: PatientTargetModel;

  titleName: string = 'Add Target';
  actionName: string = 'Add';

  ngOnInit(): void {
    this.currentTarget = {
      PatientID: this.selectedPatient?.ID,
      TargetFailOption: TargetFailOption.MarkAsFailed,
    };

    this.loadBaseData(this.selectedPatient?.ID!, () => {
      this.isLoading = true;
      this.promptSchedules = this.supervisorHelperService.promptSchedules();
      this.treatmentGoals = this.supervisorHelperService.treatmentGoals();
      this.isLoading = false;
      this.skillAreas = this.supervisorHelperService.skillAreas();

      const maintenanceLevels = this.supervisorApiService.GetMaintananceLevels();
      const maintenanceCriteriaFull = this.supervisorApiService.GetMaintenanceCriteriaFull({patientID: this.selectedPatient?.ID!});
      
      forkJoin([maintenanceLevels, maintenanceCriteriaFull]).subscribe(
        ([maintenanceLevels, maintenanceCriteriaFull]) => {
          if (maintenanceCriteriaFull.MaintenanceCriterias != null) {
            this.maintenanceCriterias = maintenanceCriteriaFull.MaintenanceCriterias.filter(criteria => criteria.MaintenanceLevelID != null).map(criteria => ({
              text: maintenanceLevels.find(level => level.ID === criteria.MaintenanceLevelID)?.MaintenanceLevel ?? '',
              value: criteria.ID ?? 0
            }));
          }
        }
      );

      this.loadTargetTypes();
      this.updatesFormValidators();

      // EDIT LOGIC
      if (this.supervisorHelperService.targetToEdit() != null) {
        this.titleName = 'Edit Target';
        this.actionName = 'Save';
        this.currentTarget =
          this.supervisorHelperService.targetToEdit()!;

        this.targetForm.patchValue({
          targetName: this.currentTarget.TargetName,
          goalName: this.currentTarget.GoalName,
          BSP_TreatmentGoalID: this.currentTarget.BSP_TreatmentGoalID,
          discriminativeStimulus:
            this.currentTarget.DiscriminativeStimulus,
          desiredTrials: this.currentTarget.DesiredTrials,
          targetType: this.currentTarget.TargetType,
          duration: this.currentTarget.Duration,
          targetStatus: this.currentTarget.TargetStatus,
          PatientProgramIDs: this.currentTarget.PatientProgramIDs,
          PatientPlaylistIDs: this.currentTarget.PatientPlaylistIDs,
          PromptScheduleID: this.currentTarget.PromptScheduleID,
          instructions: this.currentTarget.Instructions,
          isFavorite: this.currentTarget.IsFavorite,
          isSpecialFavorite: this.currentTarget.IsSpecialFavorite,
          IsBackward: this.currentTarget.IsBackward,
          SubTargetIDs: this.currentTarget.SubTargetIDs,
          // FirstTrialMustBeIndependent:
          //   this.currentTarget.FirstTrialMustBeIndependent,
          // consecutiveDays: this.currentTarget.ConsecutiveDays,
          // responsePercentage: this.currentTarget.ResponsePercentage,
          DateBaselined: this.formatDate(
            this.currentTarget.DateBaselined
          ),
          DateClosed: this.formatDate(this.currentTarget.DateClosed),
          DateMastered: this.formatDate(
            this.currentTarget.DateMastered
          ),
          DateOpened: this.formatDate(this.currentTarget.DateOpened),
          QuantityUnit: this.currentTarget.QuantityUnit,
          SkillAreaID: this.currentTarget.SkillAreaID,
          ShouldMasterAutomatic: this.currentTarget.ShouldMasterAutomatic,
        });
      }
    });
  }

  formatDate(date: string | null | undefined): string | null {
    if (!date) return null;
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  loadTargetTypes() {
    this.targetTypes = [
      { value: TargetType.Anecdotal, text: 'Anecdotal', disabled: false },
      {
        value: TargetType.DiscreteTrialTeaching,
        text: 'DTT (Discrete Trial Teaching)',
        disabled: false,
      },
      {
        value: TargetType.ConditionalDiscrimination,
        text: this.canCreateConditionalDiscriminationTarget()
          ? 'Conditional Discrimination'
          : 'Conditional Discrimination (requires at least 3 targets of type DTT or JumpTo)',
        disabled:
          !this.canCreateConditionalDiscriminationTarget(),
      },
      { value: TargetType.Duration, text: 'Duration', disabled: false },
      { value: TargetType.Echoic, text: 'Echoic', disabled: false },
      { value: TargetType.FluencyRate, text: 'Fluency Rate', disabled: false },
      { value: TargetType.Frequency, text: 'Frequency', disabled: false },
      {
        value: TargetType.GroupedFrequency,
        text: 'Grouped Frequency',
        disabled: false,
      },
      {
        value: TargetType.JumpTo,
        text: 'Jump To',
        disabled: false,
      },
      { value: TargetType.Quantity, text: 'Quantity', disabled: false },
      {
        value: TargetType.TaskAnalysis,
        text: 'Task Analysis',
        disabled: false,
      },
      { value: TargetType.Toileting, text: 'Toileting', disabled: false },
      {
        value: TargetType.WholePartialInterval,
        text: 'Whole Partial Interval',
        disabled: false,
      },
    ];
  }

  canCreateConditionalDiscriminationTarget() {
    // We must have at least 3 targets of type DTT or JumpTo to create a Conditional Discrimination target.
    return (
      this.supervisorHelperService
        .targets()
        .filter(
          (t) =>
            t.TargetType === TargetType.DiscreteTrialTeaching ||
            t.TargetType === TargetType.JumpTo
        ).length >= 3
    );
  }


  requiresDuration() {
    return (
      this.targetForm.get('targetType')?.value === TargetType.Duration ||
      this.targetForm.get('targetType')?.value === TargetType.FluencyRate
    );
  }

  requiresSubTargets() {
    return (
      this.targetForm.get('targetType')?.value ===
      TargetType.ConditionalDiscrimination ||
      this.targetForm.get('targetType')?.value === TargetType.GroupedFrequency ||
      this.targetForm.get('targetType')?.value === TargetType.TaskAnalysis
    );
  }

  usesPrompts() {
    return this.targetForm.get('targetType')?.value === TargetType.DiscreteTrialTeaching
      || this.targetForm.get('targetType')?.value === TargetType.Echoic;
  }

  isQuantity() {
    return this.targetForm.get('targetType')?.value === TargetType.Quantity;
  }

  isTaskAnalysis() {
    return this.targetForm.get('targetType')?.value === TargetType.TaskAnalysis;
  }
  isInMaintenance() {
    return this.targetForm.get('targetStatus')?.value === PatientTargetStatus.Maintenance || this.targetForm.get('targetStatus')?.value === PatientTargetStatus.Failed_Maintenance;
  }

  updatesFormValidators() {

    if (this.requiresDuration()) {
      this.targetForm.controls.duration.setValidators([Validators.required]);
    }

    this.targetForm.controls.PromptScheduleID.updateValueAndValidity();
    this.targetForm.controls.duration.updateValueAndValidity();
  }

  onCancel() {
    this.currentTarget = undefined;
    if (this.targetForm) this.targetForm.reset();
    this.close.emit();
  }

  onSubmit() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.targetForm.markAllAsTouched();

      if (this.targetForm.invalid) {
        const errors = Utils.getFormErrors(this.targetForm);
        if (errors.length > 0) {
          this.alertService.error(errors.join(' \n'));
          this.isLoading = false;
          return;
        }
      }

      var formValue = this.targetForm.getRawValue();
      console.log(formValue);

      this.currentTarget = {
        ...this.currentTarget,
        ...formValue,
        ShouldMasterAutomatic: formValue.ShouldMasterAutomatic ?? false,
        // // this is to fix the problem with type from property
        // FirstTrialMustBeIndependent:
        //   formValue.FirstTrialMustBeIndependent ?? false,
      };
      console.info(this.currentTarget);

      this.supervisorApiService
        .SavePatientTarget({ body: this.currentTarget })
        .subscribe(
          (savedItem) => {
            this.isLoading = false;
            this.currentTarget = undefined;
            this.targetForm.reset();
            this.alertService.success('Target saved successfully');
            this.close.emit();
          },
          (err) => {
            this.isLoading = false;
            if (err.Message != null && typeof err.Message == 'string') {
              this.alertService.error(err.Message);
            } else {
              this.alertService.error('Error saving target');
            }
            this.close.emit();
          }
        );
    }
  }
}
