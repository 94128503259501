<div class="dashboard">
  <div class="header">
    <div class="side-menu-buttons">
      <div class="client-name">
        {{ selectedPatient?.FirstName + " " + selectedPatient?.LastName }}
      </div>
    </div>

    <button class="side-menu-button2" (click)="openMenu = !openMenu">
      Menu
    </button>
    <div class="side-menu-buttons menu-top" *ngIf="openMenu">
      <button
        (click)="switchToStaff()"
        *ngIf="this.patientSessionService.patientSession()?.ID"
        class="switch-mode-button"
      >
        Back to Session
      </button>

      <button
        [routerLink]="['/supervisor', selectedPatient?.ID]"
        class="side-menu-button2"
      >
        Supervisor
      </button>

      <button
        [routerLink]="['/graphs', selectedPatient?.ID]"
        class="side-menu-button2"
      >
        Graphs
      </button>

      <button (click)="backToOpto()" class="side-menu-button2">
        Back to Opto
      </button>
    </div>
  </div>
  <div class="modules-container">
    <div class="card">
      <div
        class="card-body"
        style="padding-left: 2rem; padding-right: 2rem; padding-bottom: 2rem"
      >
        <p-table
          [value]="sessions"
          #dt
          dataKey="ID"
          (onRowExpand)="onRowExpand($event)"
          (onRowCollapse)="onRowCollapse($event)"
          [tableStyle]="{ 'min-width': '70rem' }"
          editMode="row"
          [loading]="isLoading"
          [rowHover]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 5rem"></th>
              <th pSortableColumn="StartedDate">
                StartedDate <p-sortIcon field="StartedDate" />
              </th>
              <th pSortableColumn="LiablePersonID">
                LiablePersonID<p-sortIcon field="EFI_LiablePersonID" />
              </th>
              <th pSortableColumn="Explanation">
                Explanation <p-sortIcon field="Explanation" />
              </th>
              <th pSortableColumn="FinishedDate">
                FinishedDate <p-sortIcon field="FinishedDate" />
              </th>
              <th pSortableColumn="IsHandoffToParent">
                IsHandoffToParent <p-sortIcon field="IsHandoffToParent" />
              </th>
              <th pSortableColumn="IsHandoffToStaff">
                IsHandoffToStaff <p-sortIcon field="IsHandoffToStaff" />
              </th>
              <th pSortableColumn="UpdatedDate">
                UpdatedDate <p-sortIcon field="UpdatedDate" />
              </th>
              <th style="width: 4rem"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-session
            let-editingSession="editing"
            let-expanded="expanded"
            let-sessionIndex="rowIndex"
          >
            <tr [pEditableRow]="session">
              <td>
                <p-button
                  type="button"
                  pRipple
                  [pRowToggler]="session"
                  [text]="true"
                  [rounded]="true"
                  [plain]="true"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                />
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="datetime"
                      [(ngModel)]="session.StartedDate"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ session.StartedDate | date : "MM/dd/yyyy" }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>{{ session.LiablePersonID }}</td>
              <td>{{ session.Explanation }}</td>
              <td>{{ session.FinishedDate | date : "MM/dd/yyyy" }}</td>
              <td>{{ session.IsHandoffToParent }}</td>
              <td>{{ session.IsHandoffToStaff }}</td>
              <td>{{ session.UpdatedDate | date : "MM/dd/yyyy" }}</td>
              <td>
                <div
                  class="flex align-items-center justify-content-center gap-2"
                >
                  <button
                    *ngIf="!editingSession"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onSessionEditInit(session)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editingSession"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onSessionEditSave(session)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editingSession"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onSessionEditCancel(session, sessionIndex)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-session>
            <tr>
              <td colspan="7">
                <div class="p-3">
                  <p-table
                    [value]="dataFromSession(session)"
                    dataKey="ID"
                    editMode="row"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Recorded Date</th>
                        <th>Int Value</th>
                        <th>Text Value</th>
                        <th>Target Name</th>
                        <th>Target Type</th>
                        <th>PatientBehaviorID</th>
                        <th style="width: 4rem"></th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-sessionData
                      let-editingSessionData="editing"
                      let-sessionDataIndex="rowIndex"
                    >
                      <tr [pEditableRow]="sessionData">
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input
                                pInputText
                                type="datetime"
                                [(ngModel)]="sessionData.RecordedDate"
                              />
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{
                                sessionData.RecordedDate | date : "MM/dd/yyyy"
                              }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input
                                pInputText
                                type="number"
                                [(ngModel)]="sessionData.IntValue"
                              />
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ sessionData.IntValue }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input
                                pInputText
                                type="text"
                                [(ngModel)]="sessionData.TextValue"
                              />
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ sessionData.TextValue }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          {{ getTargetName(sessionData.PatientTargetID) }}
                        </td>
                        <td>
                          {{ getTargetType(sessionData.PatientTargetID) }}
                        </td>
                        <td>{{ sessionData.PatientBehaviorID }}</td>
                        <td>
                          <div
                            class="flex align-items-center justify-content-center gap-2"
                          >
                            <button
                              *ngIf="!editingSessionData"
                              pButton
                              pRipple
                              type="button"
                              pInitEditableRow
                              icon="pi pi-pencil"
                              (click)="onSessionDataEditInit(sessionData)"
                              class="p-button-rounded p-button-text"
                            ></button>
                            <button
                              *ngIf="editingSessionData"
                              pButton
                              pRipple
                              type="button"
                              pSaveEditableRow
                              icon="pi pi-check"
                              (click)="onSessionDataEditSave(sessionData)"
                              class="p-button-rounded p-button-text p-button-success mr-2"
                            ></button>
                            <button
                              *ngIf="editingSessionData"
                              pButton
                              pRipple
                              type="button"
                              pCancelEditableRow
                              icon="pi pi-times"
                              (click)="onSessionDataEditCancel(sessionData)"
                              class="p-button-rounded p-button-text p-button-danger"
                            ></button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">
                          There are no entries for this session.
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

@if (isLoading) {
<app-loading-indicator />
}
