/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetGraph } from '../fn/graph/get-graph';
import { GetGraph$Params } from '../fn/graph/get-graph';
import { GetSessionEntriesForBehavior } from '../fn/graph/get-session-entries-for-behavior';
import { GetSessionEntriesForBehavior$Params } from '../fn/graph/get-session-entries-for-behavior';
import { GetSessionEntriesForTarget } from '../fn/graph/get-session-entries-for-target';
import { GetSessionEntriesForTarget$Params } from '../fn/graph/get-session-entries-for-target';
import { GetSessionEntriesForTargetsFromProgram } from '../fn/graph/get-session-entries-for-targets-from-program';
import { GetSessionEntriesForTargetsFromProgram$Params } from '../fn/graph/get-session-entries-for-targets-from-program';
import { GraphDataModel } from '../models/graph-data-model';
import { PatientSessionDataModel } from '../models/patient-session-data-model';

@Injectable({ providedIn: 'root' })
export class GraphApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiGraphGetSessionEntriesForTargetsFromProgramGet()` */
  static readonly ApiGraphGetSessionEntriesForTargetsFromProgramGetPath = '/api/Graph/GetSessionEntriesForTargetsFromProgram';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSessionEntriesForTargetsFromProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSessionEntriesForTargetsFromProgram$Response(params?: GetSessionEntriesForTargetsFromProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientSessionDataModel>>> {
    return GetSessionEntriesForTargetsFromProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSessionEntriesForTargetsFromProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSessionEntriesForTargetsFromProgram(params?: GetSessionEntriesForTargetsFromProgram$Params, context?: HttpContext): Observable<Array<PatientSessionDataModel>> {
    return this.GetSessionEntriesForTargetsFromProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientSessionDataModel>>): Array<PatientSessionDataModel> => r.body)
    );
  }

  /** Path part for operation `apiGraphGetGraphGet()` */
  static readonly ApiGraphGetGraphGetPath = '/api/Graph/GetGraph';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetGraph()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetGraph$Response(params?: GetGraph$Params, context?: HttpContext): Observable<StrictHttpResponse<GraphDataModel>> {
    return GetGraph(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetGraph$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetGraph(params?: GetGraph$Params, context?: HttpContext): Observable<GraphDataModel> {
    return this.GetGraph$Response(params, context).pipe(
      map((r: StrictHttpResponse<GraphDataModel>): GraphDataModel => r.body)
    );
  }

  /** Path part for operation `apiGraphGetSessionEntriesForTargetGet()` */
  static readonly ApiGraphGetSessionEntriesForTargetGetPath = '/api/Graph/GetSessionEntriesForTarget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSessionEntriesForTarget()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSessionEntriesForTarget$Response(params?: GetSessionEntriesForTarget$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientSessionDataModel>>> {
    return GetSessionEntriesForTarget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSessionEntriesForTarget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSessionEntriesForTarget(params?: GetSessionEntriesForTarget$Params, context?: HttpContext): Observable<Array<PatientSessionDataModel>> {
    return this.GetSessionEntriesForTarget$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientSessionDataModel>>): Array<PatientSessionDataModel> => r.body)
    );
  }

  /** Path part for operation `apiGraphGetSessionEntriesForBehaviorGet()` */
  static readonly ApiGraphGetSessionEntriesForBehaviorGetPath = '/api/Graph/GetSessionEntriesForBehavior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSessionEntriesForBehavior()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSessionEntriesForBehavior$Response(params?: GetSessionEntriesForBehavior$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientSessionDataModel>>> {
    return GetSessionEntriesForBehavior(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSessionEntriesForBehavior$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSessionEntriesForBehavior(params?: GetSessionEntriesForBehavior$Params, context?: HttpContext): Observable<Array<PatientSessionDataModel>> {
    return this.GetSessionEntriesForBehavior$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientSessionDataModel>>): Array<PatientSessionDataModel> => r.body)
    );
  }

}
