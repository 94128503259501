<div id="maintanance-criteria" class="container">

  <div *ngIf="hasCustomProbeCriteria">
    <p>
      <label for="CorrectTrials">Correct trials: </label>
          <input type="number" id="CorrectTrials" name="CorrectTrials" class="form-control inline-input"
            [(ngModel)]="probeCriteria.CorrectTrials" />  of 
            <input type="number" id="TotalTrials" name="TotalTrials" class="form-control inline-input"
            [(ngModel)]="probeCriteria.TotalTrials" /> 
    </p>

    <p>
      <label for="TrialsPerDay">Trials Per Day: </label>
          <input type="number" id="TrialsPerDay" name="TrialsPerDay" class="form-control inline-input"
            [(ngModel)]="probeCriteria.TrialsPerDay" /> 
    </p>



    @if (loadingProbeCriteria) {
    <app-loading-indicator />
    }


    <p class="actions">
      <button type="button" (click)="save()" class="btn btn-primary">Save</button>
    </p>
  </div>
<div *ngIf="!hasCustomProbeCriteria">
  <p>
    Using general settings for probe criteria.<br>
    <button (click)="enableCustomProbeCriteria()" class="btn btn-secondary">Enable Custom Probe Criteria</button>
  </p>
</div>

</div>