import { effect, Injectable, signal } from '@angular/core';
import { PatientBehaviorAntecedentModel, PatientBehaviorConsequenceModel, PatientBehaviorDataModel, PatientBehaviorLocationModel, PatientBehaviorModel, PatientBehaviorResponseModel, PatientBehaviorSeverityModel, PatientSessionModel, PatientTargetDataModel } from '../../core/api/models';
import { StaffApiService } from '../../core/api/services';
import { catchError, forkJoin, Observable, of, Subscription, tap } from 'rxjs';
import { AlertService } from '../../shared/services/alert.service';

@Injectable({
    providedIn: 'root'
})
export class PatientSessionService {

    patientSession = signal<PatientSessionModel | null>(null)

    patientID = signal<number | null>(null);

    savedSessions = signal<PatientSessionModel[]>([]);

    targetsRunningData = signal<{ TargetID: number, Data: any }[]>([]);

    patientBehaviorLocations = signal<PatientBehaviorLocationModel[]>([]);
    patientBehaviorConsequences = signal<PatientBehaviorConsequenceModel[]>([]);
    patientBehaviorResponses = signal<PatientBehaviorResponseModel[]>([]);
    patientBehaviorSeverities = signal<PatientBehaviorSeverityModel[]>([]);
    patientBehaviorAntecedents = signal<PatientBehaviorAntecedentModel[]>([]);
    patientBehaviors = signal<PatientBehaviorModel[]>([]);
    constructor(
        private staffApiService: StaffApiService,
        private alertService: AlertService
    ) {
        console.log('PatientSessionService constructor called', new Date().getTime());
        effect(() => {
            const patientSession = this.patientSession();
            if (patientSession) {
                //save to local storage
                localStorage.setItem('patientSession', JSON.stringify(patientSession));
            }
        });
        effect(() => {
            const targetsRunningData = this.targetsRunningData();
            if (targetsRunningData) {
                //save to local storage
                localStorage.setItem('targetsRunningData', JSON.stringify(targetsRunningData));
            }
        });

        effect(() => {
            const savedSessions = this.savedSessions();
            if (savedSessions) {
                //save to local storage
                localStorage.setItem('savedSessions', JSON.stringify(savedSessions));
            }
        });

        effect(() => {
            const patientID = this.patientID();
            if (patientID) {
                localStorage.setItem('patientID', patientID.toString());
            }
        });
    }

    initializePatientSession(patientID?: number, sessionID?: number): void {
        if (patientID) {
            this.patientID.set(patientID);
        } else {
            const patientIDFromLocalStorage = localStorage.getItem('patientID');
            if (patientIDFromLocalStorage) {
                this.patientID.set(parseInt(patientIDFromLocalStorage));
            } else {
                this.patientID.set(null);
            }
        }

        //load from local storage
        const patientSession = localStorage.getItem('patientSession');
        if (patientSession) {
            this.patientSession.set(JSON.parse(patientSession));
        }

        
        if (this.patientSession() && ( ((sessionID && this.patientSession()!.ID !== sessionID)) || this.patientSession()!.PatientID !== this.patientID())) {
            this.patientSession.set(null);
        }

        if (sessionID) {
            //Check if there is a new version on the backend and update if needed
            this.staffApiService.GetPatienSession({ sessionID: sessionID }).subscribe(patientSession => {

                if (!patientSession.FinishedDate) {
                    //Check if the version is different
                    if (!this.patientSession() || new Date(patientSession.UpdatedDate!) > new Date(this.patientSession()!.UpdatedDate!)) {
                        this.patientSession.set(patientSession);
                    }
                } else {
                    this.patientSession.set(null);
                    this.alertService.error("Session already finished");
                }
            });
        }
        //load from local storage
        const targetsRunningData = localStorage.getItem('targetsRunningData');
        if (targetsRunningData) {
            this.targetsRunningData.set(JSON.parse(targetsRunningData));
        }
        //load from local storage
        const savedSessions = localStorage.getItem('savedSessions');
        if (savedSessions) {
            this.savedSessions.set(JSON.parse(savedSessions));
        }

        this.loadPatientBehaviorAntecedents();
        this.loadPatientBehaviorConsequences();
    }

    startSession(): void {
        const startTime = Date.now();
        this.patientSession.update(patientSession => {
            if (patientSession) {
                patientSession.StartedDate = new Date().toServerString();
            }
            return Object.assign({}, patientSession);
        });

        this.sendCurrentSessionToBackend();

        this.targetsRunningData.set([]);
    }

    endSession(): Observable<any> {
        this.patientSession.update(patientSession => {
            if (patientSession) {
                patientSession.FinishedDate = new Date().toServerString();
            }
            return Object.assign({}, patientSession);
        });

        this.savedSessions.update(savedSessions => {
            if (this.patientSession()) {
                savedSessions.push(this.patientSession()!);
                return Object.assign([], savedSessions);
            }
            return savedSessions;
        });

        this.patientSession.set(null);
        localStorage.removeItem('patientSession');
        
        this.targetsRunningData.set([]);

        return this.sendSavedSessionsToBackend();
    }

    getTargetData(targetID: number): PatientTargetDataModel[] | null {
        const patientSession = this.patientSession();
        if (!patientSession || !patientSession.TargetData) {
            return null;
        }

        const targetData = patientSession.TargetData?.filter(t => t.PatientTargetID === targetID);
        return targetData || null;
    }

    addTargetData(targetData: PatientTargetDataModel): void {
        const patientSession = this.patientSession();
        if (!patientSession) {
            return;
        }

        //use update to add the target data
        this.patientSession.update(patientSession => {
            if (!patientSession) {
                return patientSession;
            }
            if (!patientSession.TargetData) {
                patientSession.TargetData = [];
            }
            if (!targetData.PatientSessionID) {
                targetData.PatientSessionID = patientSession.ID;
            }
            if (!targetData.RecordedDate) {
                targetData.RecordedDate = new Date().toServerString();
            }
            patientSession.TargetData.push(targetData);
            return Object.assign({}, patientSession);
        });

        this.sendCurrentSessionToBackend();
    }

    saveTargetData(targetData: PatientTargetDataModel): void {
        //use update to save the target data    
        this.patientSession.update(patientSession => {
            if (!patientSession) {
                return patientSession;
            }
            if (!patientSession.TargetData) {
                patientSession.TargetData = [];
            }
            const targetDataIndex = patientSession.TargetData?.findIndex(t => t.PatientTargetID === targetData.PatientTargetID
                && ((!!t.ID && t.ID === targetData.ID) || t.RecordedDate === targetData.RecordedDate));
            if (targetDataIndex !== undefined && targetDataIndex !== -1) {
                patientSession.TargetData[targetDataIndex] = targetData;
            }
            return Object.assign({}, patientSession);
        });

        this.sendCurrentSessionToBackend();
    }

    getTargetsRunningData(targetID: number): { TargetID: number, Data: any } | null {
        const targetsRunningData = this.targetsRunningData();
        if (!targetsRunningData) {
            return null;
        }
        const targetRunningData = targetsRunningData.find(t => t.TargetID === targetID);
        return targetRunningData || null;
    }

    setTargetsRunningData(targetID: number, data: any): void {
        this.targetsRunningData.update(targetsRunningData => {
            const targetRunningData = targetsRunningData.find(t => t.TargetID === targetID);
            if (targetRunningData) {
                targetRunningData.Data = data;
            } else {
                targetsRunningData.push({ TargetID: targetID, Data: data });
            }
            return Object.assign([], targetsRunningData);
        });
    }


    geBehaviorData(targetID: number): PatientBehaviorDataModel[] | null {
        const patientSession = this.patientSession();
        if (!patientSession || !patientSession.BehaviorData) {
            return null;
        }

        const behaviorData = patientSession.BehaviorData?.filter(t => t.PatientBehaviorID === targetID);
        return behaviorData || null;
    }

    addBehaviorData(behaviorData: PatientBehaviorDataModel): void {
        const patientSession = this.patientSession();
        if (!patientSession) {
            return;
        }

        //use update to add the target data
        this.patientSession.update(patientSession => {
            if (!patientSession) {
                return patientSession;
            }
            if (!patientSession.BehaviorData) {
                patientSession.BehaviorData = [];
            }
            if (!behaviorData.PatientSessionID) {
                behaviorData.PatientSessionID = patientSession.ID;
            }
            if (!behaviorData.RecordedDate) {
                behaviorData.RecordedDate = new Date().toServerString();
            }
            patientSession.BehaviorData.push(behaviorData);
            return Object.assign({}, patientSession);
        });

        this.sendCurrentSessionToBackend();
    }

    saveBehaviorData(behaviorData: PatientBehaviorDataModel): void {
        //use update to save the target data    
        this.patientSession.update(patientSession => {
            if (!patientSession) {
                return patientSession;
            }
            if (!patientSession.BehaviorData) {
                patientSession.BehaviorData = [];
            }
            const behaviorDataIndex = patientSession.BehaviorData?.findIndex(t => t.PatientBehaviorID === behaviorData.PatientBehaviorID
                && ((!!t.ID && t.ID === behaviorData.ID) || t.RecordedDate === behaviorData.RecordedDate));
            if (behaviorDataIndex !== undefined && behaviorDataIndex !== -1) {
                patientSession.BehaviorData[behaviorDataIndex] = behaviorData;
            }
            return Object.assign({}, patientSession);
        });

        this.sendCurrentSessionToBackend();
    }

    sendSavedSessionsToBackend(): Observable<any> {
        const savedSessions = this.savedSessions();
        if (savedSessions.length === 0) {
            return of(null);
        }
        let changes = 0;
        let savedSessionIndexes: number[] = [];
        let saveSessionsObservables: Observable<PatientSessionModel>[] = [];
        savedSessions.forEach((session, index) => {
            if (session.FinishedDate) { //TODO: check here also if the session was updated 
                saveSessionsObservables.push(this.staffApiService.SavePatientSession({ body: session }));
                savedSessionIndexes.push(index);
            }

        });

        console.log("Sending ", saveSessionsObservables.length, " sessions to backend");

        // Wrap each API call with catchError to handle failure individually
        const wrappedCalls = saveSessionsObservables.map((call, index) =>
            call.pipe(
                catchError((error) => of({ error: true, details: error }))
            )
        );

        let indexesToRemove: number[] = [];

        //I want to return the observable to the caller
        return forkJoin(wrappedCalls).pipe(
            tap((results) => {
                this.savedSessions.update(savedSessions => {
                results.forEach((result, index) => {
                    if ('error' in result) {
                        console.log(`Saving session ${index + 1} failed with error:`, result.details);
                    } else {
                        indexesToRemove.push(savedSessionIndexes[index]);
                    }
                });

                //remove the indexes from the savedSessions array - sort them to avoid index issues
                indexesToRemove.sort((a, b) => b - a);
                indexesToRemove.forEach(index => {
                    savedSessions.splice(index, 1);
                });

                return Object.assign([], savedSessions);
                });
            })
        );
    }

    sendCurrentSessionToBackend(newSession?: PatientSessionModel): void {
        const patientSession = newSession || this.patientSession();
        if (!patientSession) {
            return;
        }
        this.staffApiService.SavePatientSession({ body: patientSession }).subscribe({
            next: result => {
                if (result && patientSession && !patientSession.ID) {
                    this.patientSession.update(currentPatientSession => {
                        if (currentPatientSession) {
                            currentPatientSession.ID = result.ID;
                        } else {
                            currentPatientSession = result;
                        }
                        return Object.assign({}, currentPatientSession);
                    });
                }
            },
            error: error => {
                console.error("Error saving patient session (will save locally):", error);
                this.patientSession.update(currentPatientSession => {
                    return Object.assign({}, patientSession);
                });
            }
        });
    }

    loadPatientBehaviorLocations(): void {
        this.staffApiService.GetPatientBehaviorLocations().subscribe(locations => {
            this.patientBehaviorLocations.set(locations);
        });
    }

    loadPatientBehaviorConsequences(): void {
        this.staffApiService.GetPatientBehaviorConsequences({ patientID: this.patientID() ?? undefined }).subscribe(consequences => {
            this.patientBehaviorConsequences.set(consequences);
        });
    }

    loadPatientBehaviorResponses(): void {
        this.staffApiService.GetPatientBehaviorResponses().subscribe(responses => {
            this.patientBehaviorResponses.set(responses);
        });
    }

    loadPatientBehaviorSeverities(): void {
        this.staffApiService.GetPatientBehaviorSeverities().subscribe(severities => {
            this.patientBehaviorSeverities.set(severities);
        });
    }

    loadPatientBehaviorAntecedents(): void {
        this.staffApiService.GetPatientBehaviorAntecedents({ patientID: this.patientID() ?? undefined }).subscribe(antecedents => {
            this.patientBehaviorAntecedents.set(antecedents);
        });
    }

}
