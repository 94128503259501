import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsMainComponent } from './settings-main.component';
import { PromptCodesComponent } from './prompt-codes/prompt-codes.component';
import { PromptSchedulesComponent } from './prompt-schedules/prompt-schedules.component';
import { MaintananceCriteriasComponent } from './maintanance-criterias/maintanance-criterias.component';
import { MasteryCriteriaComponent } from './mastery-criteria/mastery-criteria.component';
import { ProbeCriteriaComponent } from './probe-criteria/probe-criteria.component';
const routes: Routes = [
  
  { path: 'settings', component: SettingsMainComponent, children: [
    { path: 'prompt-codes', component: PromptCodesComponent },
    { path: 'prompt-schedules', component: PromptSchedulesComponent },
    { path: 'maintanance-criteria', component: MaintananceCriteriasComponent },
    { path: 'mastery-criteria', component: MasteryCriteriaComponent },
    { path: 'probe-criteria', component: ProbeCriteriaComponent },
  ] },
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes, {
        bindToComponentInputs: true,
        paramsInheritanceStrategy: 'always',
      }),
    ],
    exports: [RouterModule],
  })
  export class SettingsRoutingModule {}