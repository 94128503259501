import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { environment } from "../../../environments/environment";
import { JwTokenModel, UserModel } from "../../core/api/models";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Utils } from "../../core/utils";
import { AlertService } from "../../shared/services/alert.service";



@Component({
  selector: 'app-signin-component',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SignInComponent implements OnInit, AfterViewInit {
  loginForm!: FormGroup;
  signedIn: boolean = false;
  loading: boolean = false;
  checkingCredential: boolean = false;
  authFailedError: string | undefined | null;

  token: string | undefined | null;
  returnUrl: string | undefined | null;

  bussinessLogo: string | null = null;
  bussinessName: string | null = null;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private alertService: AlertService) {
  }


  ngOnInit(): void {

    console.debug('SignInComponent.ngOnInit');

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.returnUrl = params['returnUrl'];

      if (this.token)
        console.debug('URL token', this.token);
      if (this.returnUrl)
        console.debug('URL returnUrl', this.returnUrl);
    });
    

    
    this.loginForm = this.formBuilder.group(
      {
        username: ['', [Validators.required]],
        password: ['', [Validators.required]]
      });

    

    // get return url from route parameters or default to '/'
   

    if (this.authService.tokenInfo != null) {
      
      var jwToken = Utils.parseJwt(this.authService.tokenInfo.access_token || '');
      console.info('access_token', jwToken);
      if (this.loginForm && this.loginForm.get('username'))
        this.loginForm.get('username')!.setValue(jwToken['unique_name'] || null);

    }

    
    
  
  }

  ngAfterViewInit(): void {
    

    console.debug('SignInComponent.AfterViewInit');

    this.authService.settings().subscribe(x => {
      this.bussinessLogo = x?.BusinessLogoUrl  || null;
      this.bussinessName = x?.BusinessFullName || 'ArkMed';
      if (x == null || !x.BusinessName) {
        //location.href = '_app_offline.htm';
      }
        
      
    });



    this.token = this.token || this.route.snapshot.queryParamMap.get('token');
    

    if (this.authService.isSignedIn() && !this.token) {
      console.info('isSignedIn');
      this.authService.redirectAfterLogin(null, this.returnUrl);
      return;
    }

    if (this.token) {
      this.authService.tokenInfo = {
        refresh_token: this.token,
        refresh_expires_at: '2099-01-01'
      };
    }

    this.checkingCredential = true;
    console.debug('Initial checking credential', this.authService.tokenInfo?.refresh_token);
    this.authService.getTokenInfo()
      .forEach(response => {
          this.authService.getUserInfo().subscribe(x => {
            this.authService.redirectAfterLogin(x, this.returnUrl);
          });
      })
      .catch(
        err => {
          this.checkingCredential = false;
          if (this.token)
            this.alertService.error(err.Message ||  'Invalid token.');
        })
      .finally(() => {
        
        //
      });

  }

  public signIn(_: any) {
    /*
    if (!this.loginForm.valid) {
      return;
    }
    */
    const userName = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;
    this.loading = true;
    this.authFailedError = null;
    this.authService.signIn(userName, password).forEach(
      response => {
        this.authService.getUserInfo().subscribe(x => {
          this.authService.redirectAfterLogin(x, this.returnUrl);
        });
        
      }).catch(
        err => {
          this.alertService.error(err.Message || 'Invalid credentials');
          this.authFailedError = err.Message || 'Invalid credentials';
          this.loading = false;
        }).
      finally(() => {
      });

  }

  
  

}
