import {
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth';
import {
  PatientModel,
  PatientProgramModel,
  UserModel,
} from '../core/api/models';
import { HealthCheckApiService } from '../core/api/services/health-check-api.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { StaffApiService, SupervisorApiService } from '../core/api/services';
import { AlertService } from '../shared/services/alert.service';
import { Utils } from '../core/utils';
import { SupervisorHelperService } from './supervisor.helper.service';
import { PatientSessionService } from '../staff/home/patient-session.service';

@Component({
  selector: 'supervisor-main',
  templateUrl: './supervisor-main.component.html',
  styleUrl: './supervisor-main.component.css',
})
export class SupervisorMainComponent implements OnInit {
  patientId = input.required<string>();

  private destroyRef = inject(DestroyRef);

  loadingPatients: boolean = false;
  patients: any[] | undefined;
  selectedPatient: PatientModel | null = null;

  filteredPatients: any[] = [];

  serverInfo: any = null;
  userInfo: UserModel | null = null;

  pageTitle: string | null = null;
  loading: boolean = true;
  openMenu: boolean = false;

  
  showAddStaffAlert = this.supervisorHelperService.showAddStaffAlert;
  showAddPlaylist = this.supervisorHelperService.showAddPlaylist;
  showAddTarget = this.supervisorHelperService.showAddTarget;
  showAddSkillArea = this.supervisorHelperService.showAddSkillArea;
  showAddReminder = this.supervisorHelperService.showAddReminder;
  showAddPromptOrder = this.supervisorHelperService.showAddPromptOrder;
  showAddBehavior = this.supervisorHelperService.showAddBehavior;

  constructor(
    private authService: AuthService,
    private healthCheckApiService: HealthCheckApiService,
    private router: Router,
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService,
    private activatedRoute: ActivatedRoute,
    protected patientSessionService: PatientSessionService,
    private staffApiService: StaffApiService
  ) {
    console.log(
      'SupervisorMain PatientSessionService instance:',
      this.patientSessionService
    );
  }

  ngOnInit() {
    this.authService.user().subscribe((user) => (this.userInfo = user));
    this.serverInfo = 'Loading';

    const subscription = this.activatedRoute.params.subscribe((params) => {
      this.patientId = params['patientId'];
      this.staffApiService
        .GetPatientInfo({ id: Number(this.patientId) })
        .subscribe((patient) => {
          this.selectedPatient = patient;
            this.loading = false;
        });

      if (
        !isNaN(Number(this.patientId)) &&
        Number(this.patientId) !== this.patientSessionService.patientID()
      ) {
        this.patientSessionService.initializePatientSession(
          Number(this.patientId),
          undefined
        );
      }
    });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  isRouteActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  onPatientSelected(event: any) {
    this.loading = true;
    this.router.navigate(['/supervisor', this.selectedPatient?.ID]);
  }

  switchToStaff() {
    const patientSession = this.patientSessionService.patientSession();
    this.router.navigate([
      '/staff/home/' + this.selectedPatient?.ID + '/' + patientSession?.ID,
    ]);
  }

  switchToSessionList() {
    this.router.navigate(['/staff/' + this.selectedPatient?.ID + '/sessions']);
  }

  switchToGraphs() {
    this.router.navigate(['/graphs', this.selectedPatient?.ID]);
  }

  staffAlertButtonClicked() {
    console.log('staffAlertButtonClicked');
    this.supervisorHelperService.staffAlertButtonClicked();
  }

  settingsButtonClicked() {
    console.log('settingsButtonClicked');
    this.router.navigate([
      '/supervisor/' + this.selectedPatient?.ID + '/settings',
    ]);
  }






  onCloseAddSkillArea() {
    console.log('onCloseAddSkillArea');
    this.supervisorHelperService.onCloseAddSkillArea();
  }

  onCloseAddPlaylist() {
    console.log('onCloseAddPlaylist');
    this.supervisorHelperService.onCloseAddPlaylist();
  }

  onCloseAddTarget() {
    console.log('onCloseAddTarget');
    this.supervisorHelperService.onCloseAddTarget();
  }


  onCloseAddStaffAlert() {
    console.log('onCloseAddStaffAlert');
    this.supervisorHelperService.onCloseAddStaffAlert();
  }

  onCloseAddReminder() {
    console.log('onCloseAddReminder');
    this.supervisorHelperService.onCloseAddReminder();
  }

  onCloseAddPromptOrder() {
    console.log('onCloseAddPromptOrder');
    this.supervisorHelperService.onCloseAddPromptOrder();
  }

  onCloseAddBehavior() {
    console.log('onCloseAddBehavior');
    this.supervisorHelperService.onCloseAddBehavior();
  }

  activated(comp: any) {
    this.pageTitle = comp.pageTitle || '&nbsp;';
    if (comp instanceof SupervisorMainComponent) {
      let tComp = comp as SupervisorMainComponent;
      tComp.userInfo = this.userInfo;
    }
  }

  backToOpto() {
    window.location.href = this.authService.getOptoURL() ?? '/';
  }
}
