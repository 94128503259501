import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService } from '../shared/services/alert.service';
import { Utils } from './utils';
import { AuthService } from '../auth';
import { JwTokenModel } from './api/models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router,

    private alertService: AlertService,
    
    private authService: AuthService,
    private http: HttpClient
    
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if available
    var isAPIRequest = request.url.startsWith(environment.webApiRoot +'/api/');

    if (isAPIRequest) {
      
      request = request.clone({
        setHeaders: {
          Authorization:
            this.authService.isSignedIn() ? `Bearer ${this.authService.tokenInfo?.access_token}` : '',
            clientname: this.authService.getClientFromUrl()
          //'x-api-version': `${environment.apiServer.apiVersion}`
        }
      });
    }
    
    // Also handle errors globally
    return next.handle(request).pipe(tap({
      next: (event: HttpEvent<any>) => {

        if (event instanceof HttpErrorResponse) {

        }
        if (event instanceof HttpResponse) {

          // do stuff with response if you want

          
          if (isAPIRequest
            && this.authService.isSignedIn()
            && new Date(this.authService.tokenInfo?.expires_at || 0) < Utils.addMinutes(new Date(), 5)
            && this.authService.tokenInfo?.refresh_token
            && this.authService.tokenInfo?.refresh_expires_at
            && new Date(this.authService.tokenInfo.refresh_expires_at) > new Date() )
          {
            // If we are close to the token expiration, refresh it
            const refreshingToken = localStorage.getItem('refreshingToken') == this.authService.tokenInfo?.expires_at;
            if (!refreshingToken) {
              localStorage.setItem('refreshingToken', this.authService.tokenInfo!.expires_at!);
              this.authService.getTokenInfo().forEach(token => {
                console.debug('Token Refreshed', token?.expires_at);
                localStorage.removeItem('refreshingToken');
              });
            }
          }
        }
      },
      error: err => {
        // Handle this err
        console.error(err, request.url);
                
      }
    }));

  }
}
