import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';
@Component({
  selector: 'app-box-fluencyrate',
  templateUrl: './box-fluencyrate.component.html',
})
export class BoxFluencyrateComponent extends BaseBoxComponent implements AfterViewInit, OnInit {
  @Input() text: string | undefined = '-';
  @Input() expectedAmount: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() timerInitialValue: number = 60;
  @Input() targetID: number = 0;
  targetData: PatientTargetDataModel[] = [];



  isStarted: boolean = false;
  timerValue: number = 0;
  private startTime: number = 0;
  fluencies: { totalCorrect: number; totalIncorrect: number; date: string; rate: number }[] = [];  
  totalCorrect: number = 0;
  totalIncorrect: number = 0;


  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData) {
        this.targetData = targetData;
        this.fluencies = targetData.map(t => {
          if (!t.TextValue) {
            t.TextValue = '';
          }
          //parse the totalCorrect and totalIncorrect from the textvalue
          const [totalCorrect, totalIncorrect] = t.TextValue.split('/').map(Number);
          return {
            totalCorrect: totalCorrect,
            totalIncorrect: totalIncorrect,
            date: this.formatDate(new Date(t.RecordedDate ?? '')),
            rate: ((totalCorrect + totalIncorrect) <= 0) ? 0 : (100 * totalCorrect / (totalCorrect + totalIncorrect))
          };

        });

      }
      else {
        this.targetData = [];
        this.fluencies = [];
      }
    });

    effect(() => {
      const targetsRunningData = this.patientSessionService.getTargetsRunningData(this.targetID);
      if (targetsRunningData) {
        this.isStarted = targetsRunningData.Data.isStarted;
        this.startTime = targetsRunningData.Data.startTime;
        this.timerValue = targetsRunningData.Data.timerValue;
        this.totalCorrect = targetsRunningData.Data.totalCorrect;
        this.totalIncorrect = targetsRunningData.Data.totalIncorrect;
        if (this.isStarted) {
          this.updateTimer();
        }
      } else {
        this.isStarted = false;
        this.startTime = 0;
        this.timerValue = 0;
        this.totalCorrect = 0;
        this.totalIncorrect = 0;
      }
    });
  }

  ngOnInit() {
    this.timerValue = this.timerInitialValue;
  }

  override doLongPress(): void {
    this.showExtra2();
  }

  startTimer() {
    if (this.isStarted) {
      this.showExtra();
    } else {
      this.isStarted = true;
      this.startTime = Date.now();
      this.totalCorrect = 0;
      this.totalIncorrect = 0;
      this.updateTimer();
      this.saveTargetRunningData();
      this.showExtra();
    }
  }

  saveTargetRunningData() {
    this.patientSessionService.setTargetsRunningData(this.targetID, { isStarted: this.isStarted, startTime: this.startTime, timerValue: this.timerValue, totalCorrect: this.totalCorrect, totalIncorrect: this.totalIncorrect });
  }

  private updateTimer(): void {
    if (this.isStarted) {
      const currentTime = Date.now();
      this.timerValue = this.timerInitialValue - Math.floor((currentTime - this.startTime) / 1000);
      if (this.timerValue <= 0) {
        //Use set timeout just to avoid saving it inside an effect
        setTimeout(() => {
          this.stopTimer();
        }, 10);
      } else {
        setTimeout(() => {
          this.updateTimer();
          this.saveTargetRunningData();
        }, 1000);
      }
    }
  }

  stopTimer() {
    if (this.totalCorrect > 0 || this.totalIncorrect > 0) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      const total = this.totalCorrect + this.totalIncorrect;
      const rate = 100 * this.totalCorrect / total;
      this.patientSessionService.addTargetData({
        IntValue: this.totalCorrect,
        TextValue: `${this.totalCorrect}/${this.totalIncorrect}`,
        PatientTargetID: this.targetID,
        RecordedDate: currentDate.toServerString(),
      });
      // this.fluencies.push({ totalCorrect: this.totalCorrect, totalIncorrect: this.totalIncorrect, date: formattedDate, rate: rate });
    }
    this.timerValue = this.timerInitialValue;
    this.isStarted = false;
    this.totalCorrect = 0;
    this.totalIncorrect = 0;
    this.startTime = 0;

    this.saveTargetRunningData();
    this.closeExtra();
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  lastFluency() {
    if (this.fluencies.length > 0) {
      const lastFluency = this.fluencies[this.fluencies.length - 1];
      return `${lastFluency.totalCorrect} / ${lastFluency.rate.toFixed(0)}%`;
    } else {
      return '';
    }
  }

  incrementCorrect() {
    this.totalCorrect++;
    this.saveTargetRunningData();
  }

  incrementIncorrect() {
    this.totalIncorrect++;
    this.saveTargetRunningData();
  }

}