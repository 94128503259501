import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';
@Component({
  selector: 'app-box-frequency',
  templateUrl: './box-frequency.component.html',
})
export class BoxFrequencyComponent extends BaseBoxComponent {
  @Input() text: string | null | undefined = '-';
  @Input() dayValue: number = 0;
  @Input() sessionValue: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() showDayValue: boolean = true;
  @Input() targetID: number = 0;

  targetData: PatientTargetDataModel[] = [];
  isEditing: boolean = false;
  @ViewChild('inputField') inputField!: ElementRef;

  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData && targetData.length > 0) {
        this.targetData = targetData;
        this.sessionValue = Number(targetData[targetData.length - 1].IntValue) || 0;
      }
      else {
        this.sessionValue = 0;
        this.targetData = [];
      }
    });
  }

  override onClickOutside() {
    this.isEditing = false;
    super.onClickOutside();
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
  }

  increaseSessionValue(): void {
    this.sessionValue++;
    this.addSessionValue();
  }

  addSessionValue(): void {
    const targetData: PatientTargetDataModel = {
      IntValue: this.sessionValue,
      PatientSessionID: 0,
      PatientTargetID: this.targetID,
      RecordedDate: new Date().toServerString(),
      TextValue: this.sessionValue.toString()
    };
    this.patientSessionService.addTargetData(targetData);

  }

  clickedBox(): void {
    if (!this.isEditing) {
      this.increaseSessionValue();
    }
  }

  decreaseSessionValue(): void {
    if (this.sessionValue > 0) {
      this.sessionValue--;
      this.addSessionValue();
    }
  }

  override doLongPress(): void {
    this.toggleEdit();
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.focus();
    }
  }


}
