<h2 class="form-title">{{ titleName }}</h2>
<div *ngIf="loading">
  <app-loading-indicator></app-loading-indicator>
</div>
<form (ngSubmit)="onSubmit()" *ngIf="!loading && promptScheduleToEdit != null">
  <div class="control">
    <label for="text">Text</label>
    <input class="form-control" type="text" id="text" name="text" [(ngModel)]="promptScheduleToEdit!.PromptScheduleName" />
  </div>
    <div class="control d-flex mb-3 mt-3">
      <label for="promotionNeeded">Promotion:</label>
      <input type="number" id="promotionNeeded" name="promotionNeeded" [(ngModel)]="promptScheduleToEdit!.PromotionNeeded" class="form-control inline-input" />
      <label for="promotionTotal">of</label>
      <input type="number" id="promotionTotal" name="promotionTotal" [(ngModel)]="promptScheduleToEdit!.PromotionTotal" class="form-control inline-input" />
    </div>

    <div class="control d-flex mb-3 mt-3">
      <label for="demotionNeeded">Demotion:</label>
      <input type="number" id="demotionNeeded" name="demotionNeeded" [(ngModel)]="promptScheduleToEdit!.DemotionNeeded" class="form-control inline-input" />
      <label for="demotionTotal">of</label>
      <input type="number" id="demotionTotal" name="demotionTotal" [(ngModel)]="promptScheduleToEdit!.DemotionTotal" class="form-control inline-input" />
    </div>

  <div class="control">
    <label for="tieIsDemotion">Tie goes to:</label>
    <p-selectButton  id="tieIsDemotion" name="tieIsDemotion" [options]="[{label: 'Demotion', value: true}, {label: 'Promotion', value: false}]" [(ngModel)]="promptScheduleToEdit!.TieIsDemotion" />
  </div>
  <div class="control">
    <label for="isInverse">Is Inverse:</label>
    <p-selectButton  id="isInverse" name="isInverse" [options]="[{label: 'Yes', value: true}, {label: 'No', value: false}]" [(ngModel)]="promptScheduleToEdit!.IsInverse" />
  </div>

  <div class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit">{{ actionName }}</button>
  </div>
</form>
