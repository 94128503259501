import { Directive, ElementRef, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[clickOutside],[additionalElementRefs]'
})
export class ClickOutsideDirective {
  @Input() additionalElementRefs: ElementRef[] | null = null;
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    //check if any parent of target is the document
    let isTargetInsideDocument = false;
    let parent = target;
    while (parent) {
      if (parent === document) {
        isTargetInsideDocument = true;
        break;
      }
      parent = parent.parentNode;
    }
    const clickedAdditionals = this.additionalElementRefs && this.additionalElementRefs.some(ref => ref?.nativeElement?.contains(target));
    if (!clickedInside && !clickedAdditionals && isTargetInsideDocument) {
      this.clickOutside.emit();
    }
  }
}