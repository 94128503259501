 @if (showAddPlaylist()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddPlaylist()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-playlist
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddPlaylist()"
  />
</dialog>
} @if (showAddTarget()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddTarget()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-target
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddTarget()"
  />
</dialog>
} @if (showAddStaffAlert()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddStaffAlert()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-staff-alert (close)="onCloseAddStaffAlert()" />
</dialog>
} @if (showAddSkillArea()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddSkillArea()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-skill-area
    (close)="onCloseAddSkillArea()"
    [selectedPatient]="selectedPatient"
  />
</dialog>
} @if (showAddReminder() && selectedPatient) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddReminder()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-reminder
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddReminder()"
  />
</dialog>
} @if (showAddPromptOrder()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddPromptOrder()"></div>
<dialog class="supervisor-dialog" open>
  <!-- <app-add-prompt-order (close)="onCloseAddPromptOrder()" /> -->
</dialog>
} @if (showAddBehavior()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddBehavior()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-behavior
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddBehavior()"
  />
</dialog>
}

<div class="dashboard">
  <div class="header">
    <div class="side-menu-buttons">
      <div class="client-name">
        {{ selectedPatient?.FirstName + " " + selectedPatient?.LastName }}
      </div>

    </div>
    <button class="side-menu-button2" (click)="openMenu = !openMenu">
      Menu
    </button>
    <div class="side-menu-buttons menu-top" *ngIf="openMenu">
      <button
        (click)="switchToStaff()"
        *ngIf="this.patientSessionService.patientSession()?.ID"
        class="switch-mode-button"
      >
        Back to Session
      </button>

      <button (click)="switchToSessionList()" class="side-menu-button2">
        Session List
      </button>

      <button
        [routerLink]="['/graphs', selectedPatient?.ID]"
        class="side-menu-button2"
      >
        Graphs
      </button>

      <button
        [routerLink]="['/settings/prompt-codes']"
        class="side-menu-button2"
      >
        General Settings
      </button>

      <button (click)="backToOpto()" class="side-menu-button2">
        Back to Opto
      </button>
    </div>
  </div>
  <div class="modules-container">
    <div class="horizontal-flex-list">
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'skillAreas']" routerLinkActive="selected">
        <span>Skill Areas</span>
      </a>
      <a
        class="main-button"
        [routerLink]="['/supervisor', selectedPatient?.ID, 'playlists']"
        routerLinkActive="selected"
      >
        <span>Playlists</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'programs']" routerLinkActive="selected">
        <span>Programs</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'targets']" routerLinkActive="selected">
        <span>Targets</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'behaviors']" routerLinkActive="selected">
        <span>Behaviors</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'antecedents']" routerLinkActive="selected">
        <span>Antecedents</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'consequences']" routerLinkActive="selected">
        <span>Consequences</span>
      </a>

      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'reminders']" routerLinkActive="selected">
        <span>Reminders</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'prompt-codes']" routerLinkActive="selected">
        <span>Prompt Codes</span>
      </a>
      <a
        class="main-button"
        [routerLink]="['/supervisor', selectedPatient?.ID, 'prompt-schedules']"
        routerLinkActive="selected"
      >
        <span>Prompt Schedules</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'maintanance-criteria']" routerLinkActive="selected">
        <span>Maintanance Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'mastery-criteria']" routerLinkActive="selected">
        <span>Mastery Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'probe-criteria']" routerLinkActive="selected">
        <span>Probe Criteria</span>
      </a>
    </div>

    <router-outlet (activate)="activated($event)"></router-outlet>
  </div>
</div>

@if (loading) {
<app-loading-indicator />
}
