import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { NgModule } from '@angular/core';
import { GraphsMainComponent } from './graphs-main.component';
import { ProgramsComponent } from './programs/programs.component';
import { TargetsComponent } from './targets/targets.component';

const routes: Routes = [
  {
    path: 'graphs',
    redirectTo: 'graphs/:patientId',
    pathMatch: 'full',
  },

  {
    path: 'graphs/:patientId',
    redirectTo: 'graphs/:patientId/targets',
    pathMatch: 'full',
  },
  {
    path: 'graphs/:patientId',
    component: GraphsMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'programs',
        component: ProgramsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'targets',
        component: TargetsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class GraphsRoutingModule {}
