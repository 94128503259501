<div class="w-100 d-flex justify-content-center" *ngIf="promptCodes.length > 0">
  <button (click)="addPromptCodeButtonClicked()" class="side-menu-button2 w-100 ">
    + Prompt Code
  </button>
</div>
<ul id="prompt-codes-patient" class="container">

  @if (loadingPromptCodes) {
  <app-loading-indicator />
  } @else {
     @for (promptCode of promptCodes; track promptCode.ID) {
      <div class="row ">
      <div class="col-1 d-flex align-items-center">
        <i class="pi pi-arrow-circle-up pointer" (click)="moveUp(promptCode)"  style="font-size: 1.1rem; color: #719eb6"></i>
        <i class="pi pi-arrow-circle-down ms-2 pointer" (click)="moveDown(promptCode)"  style="font-size: 1.1rem; color: #719eb6"></i>
      </div>
      <div class="col-11">
      <app-prompt-code [promptCode]="promptCode"></app-prompt-code>
    </div>
    </div>
    } 
  @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no custom Prompt Codes for this patient.<br>
    <button class="btn btn-primary" (click)="customizePromptCodes()">Customize Prompt Codes</button>
  </p>
  } }
</ul>

@if (showAddPromptCode()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddPromptCode()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-prompt-code [patientId]="patientId" (close)="onCloseAddPromptCode()" />
  </dialog>
  }