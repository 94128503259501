import { Component, effect, input } from '@angular/core';
import { PatientBehaviorAntecedentModel } from '../../../core/api/models';
import { StaffApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-antecedents',
  templateUrl: './antecedents.component.html',
})
export class AntecedentsComponent {
  patientId = input.required<number>();

  antecedents: PatientBehaviorAntecedentModel[] = [];

  loadingAntecedents: boolean = false;
  showAddAntecedent = this.supervisorHelperService.showAddAntecedent;

  constructor(
    private staffApiService: StaffApiService,
    private alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('RemindersComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingAntecedents = true;
    this.staffApiService
      .GetPatientBehaviorAntecedents({ patientID: Number(this.patientId()) })
      .subscribe(
        (antecedents) => {
          this.loadingAntecedents = false;
          this.antecedents = antecedents;
        },
        (err) => {
          this.loadingAntecedents = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }
  
  addAntecedentButtonClicked() {
    console.log('addAntecedentButtonClicked');
    this.supervisorHelperService.addAntecedentButtonClicked(null);
  }

  onCloseAddAntecedent() {
    this.supervisorHelperService.onCloseAddAntecedent();
  }
}
