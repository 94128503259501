import { Component, effect, input } from '@angular/core';
import { PatientBehaviorModel } from '../../core/api/models';
import { StaffApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SupervisorHelperService } from '../supervisor.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-behaviors',
  templateUrl: './behaviors.component.html',
  styleUrl: './behaviors.component.css',
})
export class BehaviorsComponent {
  patientId = input.required<string>();

  behaviors: PatientBehaviorModel[] = [];

  loadingBehaviors: boolean = false;

  constructor(
    private staffApiService: StaffApiService,
    private alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('BehaviorsComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingBehaviors = true;
    this.staffApiService
      .GetPatientBehaviors({ patientID: Number(this.patientId()) })
      .subscribe(
        (behaviors) => {
          this.loadingBehaviors = false;
          this.behaviors = behaviors;
        },
        (err) => {
          this.loadingBehaviors = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }
  

  addBehaviorButtonClicked() {
    console.log('addBehaviorButtonClicked');
    this.supervisorHelperService.addBehaviorButtonClicked(null);
  }
}
