
<button (click)="addBehaviorButtonClicked()" class="side-menu-button2 w-100">
  + Behavior
</button>
<ul id="behaviors">
  @if (loadingBehaviors) {
  <app-loading-indicator />
  } @else { @for (behavior of behaviors; track behavior.ID) {
  <app-behavior [behavior]="behavior"></app-behavior>
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no Behaviors yet.
  </p>
  } }
</ul>
