<h2 class="form-title">{{ titleName }}</h2>
<div class="overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="control">
    <label for="name">Name</label>
    <input class="form-control" type="text" id="name" name="name" [(ngModel)]="enteredName" />
  </div>

  <div class="control">
    <label for="selectedProgram">Targets:</label>
    <p-multiSelect
      appendTo="body"
      autoWidth="false"
      [style]="{ width: '100%' }"
      [options]="targets"
      name="selectedTargets"
      [(ngModel)]="selectedTargets"
      placeholder="Select Target"
      optionLabel="TargetName"
      display="chip"
      [showClear]="true"
    />
  </div>

  <div class="control">
    <label for="selectedSkillArea">Skill Area:</label>
    <p-dropdown
      autoWidth="false"
      [style]="{ width: '100%' }"
      appendTo="body"
      [options]="skillAreas"
      [(ngModel)]="selectedSkillArea"
      name="selectedSkillArea"
      [checkmark]="true"
      optionLabel="SkillAreaName"
      [showClear]="true"
      placeholder="Select a Skill Area"
    />
  </div>

  <div class="control">
    <label for="details">Details</label>
    <textarea
      class="form-control"
      id="details"
      rows="5"
      name="details"
      [(ngModel)]="enteredDetails"
    ></textarea>
  </div>

  <p class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit" [disabled]="loading">{{ actionName }}</button>
  </p>
</form>
