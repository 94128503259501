import { Component, effect, input } from '@angular/core';
import { PatientReminderModel, PromptCodeModel, PromptScheduleModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../settings.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-prompt-schedules',
  templateUrl: './prompt-schedules.component.html',
})
export class PromptSchedulesComponent {
  patientId = input.required<string>();

  promptSchedules: PromptScheduleModel[] = [];

  loadingPromptSchedules: boolean = false;
  showAddPromptSchedule = this.settingsHelperService.showAddPromptSchedule;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('RemindersComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingPromptSchedules = true;
    this.supervisorApiService
      .GetPromptSchedules({ patientID: undefined })
      .subscribe(
        (promptSchedules) => {
          this.loadingPromptSchedules = false;
          this.promptSchedules = promptSchedules;
        },
        (err) => {
          this.loadingPromptSchedules = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }

  addPromptScheduleButtonClicked() {
    this.settingsHelperService.addPromptScheduleButtonClicked(null);
  }

  onCloseAddPromptSchedule() {
    this.settingsHelperService.onCloseAddPromptSchedule();
  }
}
