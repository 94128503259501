<div #mainBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="duration-box pointer" (click)="inputField.focus()"
(clickOutside)="onClickOutside()"
[additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select" (mousedown)="startLongPress()" (mouseup)="endLongPress()"
        (mouseleave)="endLongPress()" (touchstart)="startLongPress()" (touchend)="endLongPress()"
        (touchcancel)="endLongPress()">

        <div class="quantity-container">
            <div class="quantity-input">
                <input class="flag-input" type="number" [(ngModel)]="quantity" [placeholder]="'XX'" (keypress)="hitQuantity($event)" #inputField (focus)="inputField.select()" [autofocus]="true" /> {{unit}} +
            </div>
            <div class="flag-text">{{ text }}</div>
        </div>
        <span class="badge-count" [class.badge-count-green]="quantities.length>=expectedAmount" [class.badge-count-red]="quantities.length<expectedAmount">{{quantities.length}}/{{expectedAmount}}</span>
       
        <!-- <button class="badge-bottom-left">?</button> -->
    </div>
</div>

<div class="extra-container" #extraBox [class.hide]="!extraOpened">
    <div class="extra-close" (click)="closeExtra()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let eachQuantity of quantities" class="extra-text">{{ eachQuantity.Quantity }} {{unit}} {{'@'}} {{eachQuantity.Date}}</div>
    </div>
</div>