/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { JwTokenModel } from '../../models/jw-token-model';

export interface Authenticate$Params {
  latitude?: number;
  longitude?: number;
  deviceName?: string;
      body?: {
'username'?: string;
'password'?: string;
}
}

export function Authenticate(http: HttpClient, rootUrl: string, params?: Authenticate$Params, context?: HttpContext): Observable<StrictHttpResponse<JwTokenModel>> {
  const rb = new RequestBuilder(rootUrl, Authenticate.PATH, 'post');
  if (params) {
    rb.query('latitude', params.latitude, {});
    rb.query('longitude', params.longitude, {});
    rb.query('deviceName', params.deviceName, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<JwTokenModel>;
    })
  );
}

Authenticate.PATH = '/api/Account/Authenticate';
