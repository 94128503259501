/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangeProgramNotes } from '../fn/staff/change-program-notes';
import { ChangeProgramNotes$Params } from '../fn/staff/change-program-notes';
import { DeleteAntecedent } from '../fn/staff/delete-antecedent';
import { DeleteAntecedent$Params } from '../fn/staff/delete-antecedent';
import { DeleteConsequence } from '../fn/staff/delete-consequence';
import { DeleteConsequence$Params } from '../fn/staff/delete-consequence';
import { GetMyPatients } from '../fn/staff/get-my-patients';
import { GetMyPatients$Params } from '../fn/staff/get-my-patients';
import { GetPatienSession } from '../fn/staff/get-patien-session';
import { GetPatienSession$Params } from '../fn/staff/get-patien-session';
import { GetPatientBehaviorAntecedents } from '../fn/staff/get-patient-behavior-antecedents';
import { GetPatientBehaviorAntecedents$Params } from '../fn/staff/get-patient-behavior-antecedents';
import { GetPatientBehaviorConsequences } from '../fn/staff/get-patient-behavior-consequences';
import { GetPatientBehaviorConsequences$Params } from '../fn/staff/get-patient-behavior-consequences';
import { GetPatientBehaviorData } from '../fn/staff/get-patient-behavior-data';
import { GetPatientBehaviorData$Params } from '../fn/staff/get-patient-behavior-data';
import { GetPatientBehaviorLocations } from '../fn/staff/get-patient-behavior-locations';
import { GetPatientBehaviorLocations$Params } from '../fn/staff/get-patient-behavior-locations';
import { GetPatientBehaviorResponses } from '../fn/staff/get-patient-behavior-responses';
import { GetPatientBehaviorResponses$Params } from '../fn/staff/get-patient-behavior-responses';
import { GetPatientBehaviors } from '../fn/staff/get-patient-behaviors';
import { GetPatientBehaviors$Params } from '../fn/staff/get-patient-behaviors';
import { GetPatientBehaviorSeverities } from '../fn/staff/get-patient-behavior-severities';
import { GetPatientBehaviorSeverities$Params } from '../fn/staff/get-patient-behavior-severities';
import { GetPatientGuardians } from '../fn/staff/get-patient-guardians';
import { GetPatientGuardians$Params } from '../fn/staff/get-patient-guardians';
import { GetPatientInfo } from '../fn/staff/get-patient-info';
import { GetPatientInfo$Params } from '../fn/staff/get-patient-info';
import { GetPatientSessions } from '../fn/staff/get-patient-sessions';
import { GetPatientSessions$Params } from '../fn/staff/get-patient-sessions';
import { GetPatientTargetData } from '../fn/staff/get-patient-target-data';
import { GetPatientTargetData$Params } from '../fn/staff/get-patient-target-data';
import { GuardianModel } from '../models/guardian-model';
import { PatientBehaviorAntecedentModel } from '../models/patient-behavior-antecedent-model';
import { PatientBehaviorConsequenceModel } from '../models/patient-behavior-consequence-model';
import { PatientBehaviorDataModel } from '../models/patient-behavior-data-model';
import { PatientBehaviorLocationModel } from '../models/patient-behavior-location-model';
import { PatientBehaviorModel } from '../models/patient-behavior-model';
import { PatientBehaviorResponseModel } from '../models/patient-behavior-response-model';
import { PatientBehaviorSeverityModel } from '../models/patient-behavior-severity-model';
import { PatientModel } from '../models/patient-model';
import { PatientSessionModel } from '../models/patient-session-model';
import { PatientTargetDataModel } from '../models/patient-target-data-model';
import { SavePatientBehavior } from '../fn/staff/save-patient-behavior';
import { SavePatientBehavior$Params } from '../fn/staff/save-patient-behavior';
import { SavePatientBehaviorAntecedent } from '../fn/staff/save-patient-behavior-antecedent';
import { SavePatientBehaviorAntecedent$Params } from '../fn/staff/save-patient-behavior-antecedent';
import { SavePatientBehaviorConsequence } from '../fn/staff/save-patient-behavior-consequence';
import { SavePatientBehaviorConsequence$Params } from '../fn/staff/save-patient-behavior-consequence';
import { SavePatientBehaviorData } from '../fn/staff/save-patient-behavior-data';
import { SavePatientBehaviorData$Params } from '../fn/staff/save-patient-behavior-data';
import { SavePatientSession } from '../fn/staff/save-patient-session';
import { SavePatientSession$Params } from '../fn/staff/save-patient-session';
import { SavePatientTargetData } from '../fn/staff/save-patient-target-data';
import { SavePatientTargetData$Params } from '../fn/staff/save-patient-target-data';

@Injectable({ providedIn: 'root' })
export class StaffApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStaffGetMyPatientsGet()` */
  static readonly ApiStaffGetMyPatientsGetPath = '/api/Staff/GetMyPatients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetMyPatients()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMyPatients$Response(params?: GetMyPatients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientModel>>> {
    return GetMyPatients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetMyPatients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMyPatients(params?: GetMyPatients$Params, context?: HttpContext): Observable<Array<PatientModel>> {
    return this.GetMyPatients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientModel>>): Array<PatientModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientInfoGet()` */
  static readonly ApiStaffGetPatientInfoGetPath = '/api/Staff/GetPatientInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientInfo$Response(params?: GetPatientInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientModel>> {
    return GetPatientInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientInfo(params?: GetPatientInfo$Params, context?: HttpContext): Observable<PatientModel> {
    return this.GetPatientInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientModel>): PatientModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientSessionsGet()` */
  static readonly ApiStaffGetPatientSessionsGetPath = '/api/Staff/GetPatientSessions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientSessions$Response(params?: GetPatientSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientSessionModel>>> {
    return GetPatientSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientSessions(params?: GetPatientSessions$Params, context?: HttpContext): Observable<Array<PatientSessionModel>> {
    return this.GetPatientSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientSessionModel>>): Array<PatientSessionModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatienSessionGet()` */
  static readonly ApiStaffGetPatienSessionGetPath = '/api/Staff/GetPatienSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatienSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatienSession$Response(params?: GetPatienSession$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientSessionModel>> {
    return GetPatienSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatienSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatienSession(params?: GetPatienSession$Params, context?: HttpContext): Observable<PatientSessionModel> {
    return this.GetPatienSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientSessionModel>): PatientSessionModel => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientSessionPost()` */
  static readonly ApiStaffSavePatientSessionPostPath = '/api/Staff/SavePatientSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientSession()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientSession$Response(params?: SavePatientSession$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientSessionModel>> {
    return SavePatientSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientSession$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientSession(params?: SavePatientSession$Params, context?: HttpContext): Observable<PatientSessionModel> {
    return this.SavePatientSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientSessionModel>): PatientSessionModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorsGet()` */
  static readonly ApiStaffGetPatientBehaviorsGetPath = '/api/Staff/GetPatientBehaviors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviors()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviors$Response(params?: GetPatientBehaviors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorModel>>> {
    return GetPatientBehaviors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviors(params?: GetPatientBehaviors$Params, context?: HttpContext): Observable<Array<PatientBehaviorModel>> {
    return this.GetPatientBehaviors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorModel>>): Array<PatientBehaviorModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientBehaviorPost()` */
  static readonly ApiStaffSavePatientBehaviorPostPath = '/api/Staff/SavePatientBehavior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientBehavior()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehavior$Response(params?: SavePatientBehavior$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientBehaviorModel>> {
    return SavePatientBehavior(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientBehavior$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehavior(params?: SavePatientBehavior$Params, context?: HttpContext): Observable<PatientBehaviorModel> {
    return this.SavePatientBehavior$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientBehaviorModel>): PatientBehaviorModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientTargetDataGet()` */
  static readonly ApiStaffGetPatientTargetDataGetPath = '/api/Staff/GetPatientTargetData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientTargetData()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargetData$Response(params?: GetPatientTargetData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientTargetDataModel>>> {
    return GetPatientTargetData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientTargetData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargetData(params?: GetPatientTargetData$Params, context?: HttpContext): Observable<Array<PatientTargetDataModel>> {
    return this.GetPatientTargetData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientTargetDataModel>>): Array<PatientTargetDataModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientTargetDataPost()` */
  static readonly ApiStaffSavePatientTargetDataPostPath = '/api/Staff/SavePatientTargetData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientTargetData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTargetData$Response(params?: SavePatientTargetData$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientTargetDataModel>> {
    return SavePatientTargetData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientTargetData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTargetData(params?: SavePatientTargetData$Params, context?: HttpContext): Observable<PatientTargetDataModel> {
    return this.SavePatientTargetData$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientTargetDataModel>): PatientTargetDataModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorDataGet()` */
  static readonly ApiStaffGetPatientBehaviorDataGetPath = '/api/Staff/GetPatientBehaviorData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorData()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorData$Response(params?: GetPatientBehaviorData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorDataModel>>> {
    return GetPatientBehaviorData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorData(params?: GetPatientBehaviorData$Params, context?: HttpContext): Observable<Array<PatientBehaviorDataModel>> {
    return this.GetPatientBehaviorData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorDataModel>>): Array<PatientBehaviorDataModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientBehaviorDataPost()` */
  static readonly ApiStaffSavePatientBehaviorDataPostPath = '/api/Staff/SavePatientBehaviorData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientBehaviorData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorData$Response(params?: SavePatientBehaviorData$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientBehaviorDataModel>> {
    return SavePatientBehaviorData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientBehaviorData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorData(params?: SavePatientBehaviorData$Params, context?: HttpContext): Observable<PatientBehaviorDataModel> {
    return this.SavePatientBehaviorData$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientBehaviorDataModel>): PatientBehaviorDataModel => r.body)
    );
  }

  /** Path part for operation `apiStaffChangeProgramNotesPost()` */
  static readonly ApiStaffChangeProgramNotesPostPath = '/api/Staff/ChangeProgramNotes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ChangeProgramNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  ChangeProgramNotes$Response(params?: ChangeProgramNotes$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return ChangeProgramNotes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ChangeProgramNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ChangeProgramNotes(params?: ChangeProgramNotes$Params, context?: HttpContext): Observable<string> {
    return this.ChangeProgramNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientGuardiansGet()` */
  static readonly ApiStaffGetPatientGuardiansGetPath = '/api/Staff/GetPatientGuardians';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientGuardians()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientGuardians$Response(params?: GetPatientGuardians$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuardianModel>>> {
    return GetPatientGuardians(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientGuardians$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientGuardians(params?: GetPatientGuardians$Params, context?: HttpContext): Observable<Array<GuardianModel>> {
    return this.GetPatientGuardians$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuardianModel>>): Array<GuardianModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorLocationsGet()` */
  static readonly ApiStaffGetPatientBehaviorLocationsGetPath = '/api/Staff/GetPatientBehaviorLocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorLocations$Response(params?: GetPatientBehaviorLocations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorLocationModel>>> {
    return GetPatientBehaviorLocations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorLocations(params?: GetPatientBehaviorLocations$Params, context?: HttpContext): Observable<Array<PatientBehaviorLocationModel>> {
    return this.GetPatientBehaviorLocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorLocationModel>>): Array<PatientBehaviorLocationModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorConsequencesGet()` */
  static readonly ApiStaffGetPatientBehaviorConsequencesGetPath = '/api/Staff/GetPatientBehaviorConsequences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorConsequences()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorConsequences$Response(params?: GetPatientBehaviorConsequences$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorConsequenceModel>>> {
    return GetPatientBehaviorConsequences(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorConsequences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorConsequences(params?: GetPatientBehaviorConsequences$Params, context?: HttpContext): Observable<Array<PatientBehaviorConsequenceModel>> {
    return this.GetPatientBehaviorConsequences$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorConsequenceModel>>): Array<PatientBehaviorConsequenceModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorResponsesGet()` */
  static readonly ApiStaffGetPatientBehaviorResponsesGetPath = '/api/Staff/GetPatientBehaviorResponses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorResponses()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorResponses$Response(params?: GetPatientBehaviorResponses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorResponseModel>>> {
    return GetPatientBehaviorResponses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorResponses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorResponses(params?: GetPatientBehaviorResponses$Params, context?: HttpContext): Observable<Array<PatientBehaviorResponseModel>> {
    return this.GetPatientBehaviorResponses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorResponseModel>>): Array<PatientBehaviorResponseModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorSeveritiesGet()` */
  static readonly ApiStaffGetPatientBehaviorSeveritiesGetPath = '/api/Staff/GetPatientBehaviorSeverities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorSeverities()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorSeverities$Response(params?: GetPatientBehaviorSeverities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorSeverityModel>>> {
    return GetPatientBehaviorSeverities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorSeverities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorSeverities(params?: GetPatientBehaviorSeverities$Params, context?: HttpContext): Observable<Array<PatientBehaviorSeverityModel>> {
    return this.GetPatientBehaviorSeverities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorSeverityModel>>): Array<PatientBehaviorSeverityModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorAntecedentsGet()` */
  static readonly ApiStaffGetPatientBehaviorAntecedentsGetPath = '/api/Staff/GetPatientBehaviorAntecedents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorAntecedents()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorAntecedents$Response(params?: GetPatientBehaviorAntecedents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorAntecedentModel>>> {
    return GetPatientBehaviorAntecedents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorAntecedents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorAntecedents(params?: GetPatientBehaviorAntecedents$Params, context?: HttpContext): Observable<Array<PatientBehaviorAntecedentModel>> {
    return this.GetPatientBehaviorAntecedents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorAntecedentModel>>): Array<PatientBehaviorAntecedentModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientBehaviorAntecedentPost()` */
  static readonly ApiStaffSavePatientBehaviorAntecedentPostPath = '/api/Staff/SavePatientBehaviorAntecedent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientBehaviorAntecedent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorAntecedent$Response(params?: SavePatientBehaviorAntecedent$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientBehaviorAntecedentModel>> {
    return SavePatientBehaviorAntecedent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientBehaviorAntecedent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorAntecedent(params?: SavePatientBehaviorAntecedent$Params, context?: HttpContext): Observable<PatientBehaviorAntecedentModel> {
    return this.SavePatientBehaviorAntecedent$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientBehaviorAntecedentModel>): PatientBehaviorAntecedentModel => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientBehaviorConsequencePost()` */
  static readonly ApiStaffSavePatientBehaviorConsequencePostPath = '/api/Staff/SavePatientBehaviorConsequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientBehaviorConsequence()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorConsequence$Response(params?: SavePatientBehaviorConsequence$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientBehaviorConsequenceModel>> {
    return SavePatientBehaviorConsequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientBehaviorConsequence$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorConsequence(params?: SavePatientBehaviorConsequence$Params, context?: HttpContext): Observable<PatientBehaviorConsequenceModel> {
    return this.SavePatientBehaviorConsequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientBehaviorConsequenceModel>): PatientBehaviorConsequenceModel => r.body)
    );
  }

  /** Path part for operation `apiStaffDeleteAntecedentDelete()` */
  static readonly ApiStaffDeleteAntecedentDeletePath = '/api/Staff/DeleteAntecedent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteAntecedent()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteAntecedent$Response(params?: DeleteAntecedent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteAntecedent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteAntecedent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteAntecedent(params?: DeleteAntecedent$Params, context?: HttpContext): Observable<void> {
    return this.DeleteAntecedent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiStaffDeleteConsequenceDelete()` */
  static readonly ApiStaffDeleteConsequenceDeletePath = '/api/Staff/DeleteConsequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteConsequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteConsequence$Response(params?: DeleteConsequence$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteConsequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteConsequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteConsequence(params?: DeleteConsequence$Params, context?: HttpContext): Observable<void> {
    return this.DeleteConsequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
