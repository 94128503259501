@if (isCollapsed()) {
<div class="container">
    <div class="row h-4rem align-items-center">
        <div class="col-3">
            <button type="button" class="btn btn-primary position-relative program-name" (click)="toggleCollapse()">
                {{ skillArea?.SkillAreaName }}
            </button>
        </div>
        <div class="col-1">
            <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
        </div>
    </div>
</div>
} @else {
<div class="container">
    <div class="row h-4rem align-items-center">
        <div class="col-3">
            <button type="button" class="btn btn-primary position-relative program-name" (click)="toggleCollapse()">
                {{ skillArea?.SkillAreaName }}
            </button>
        </div>
        <div class="col-1">
            <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
        </div>
        <div class="col-8">
            <div class="">
                <button (click)="editSkillArea()" class="side-menu-button2 me-2">Edit Skill Area</button>
                <button (click)="deleteSkillArea()" class="switch-mode-button">Delete Skill Area</button>
            </div>
        </div>
    </div>
</div>
}
