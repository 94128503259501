/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Authenticate } from '../fn/account/authenticate';
import { Authenticate$Params } from '../fn/account/authenticate';
import { GeneralSettings } from '../fn/account/general-settings';
import { GeneralSettings$Params } from '../fn/account/general-settings';
import { GeneralSettingsModel } from '../models/general-settings-model';
import { JwTokenModel } from '../models/jw-token-model';
import { LogNavigation } from '../fn/account/log-navigation';
import { LogNavigation$Params } from '../fn/account/log-navigation';
import { Logout } from '../fn/account/logout';
import { Logout$Params } from '../fn/account/logout';
import { Me } from '../fn/account/me';
import { Me$Params } from '../fn/account/me';
import { RefreshToken } from '../fn/account/refresh-token';
import { RefreshToken$Params } from '../fn/account/refresh-token';
import { Test } from '../fn/account/test';
import { Test$Params } from '../fn/account/test';
import { UserModel } from '../models/user-model';

@Injectable({ providedIn: 'root' })
export class AccountApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountTestGet()` */
  static readonly ApiAccountTestGetPath = '/api/Account/Test';

  /**
   * Reuturns a 200 OK  "pong" response to indicate the service is running.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Test()` instead.
   *
   * This method doesn't expect any request body.
   */
  Test$Response(params?: Test$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return Test(this.http, this.rootUrl, params, context);
  }

  /**
   * Reuturns a 200 OK  "pong" response to indicate the service is running.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Test$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Test(params?: Test$Params, context?: HttpContext): Observable<void> {
    return this.Test$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountGeneralSettingsGet()` */
  static readonly ApiAccountGeneralSettingsGetPath = '/api/Account/GeneralSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GeneralSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  GeneralSettings$Response(params?: GeneralSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<GeneralSettingsModel>> {
    return GeneralSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GeneralSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GeneralSettings(params?: GeneralSettings$Params, context?: HttpContext): Observable<GeneralSettingsModel> {
    return this.GeneralSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeneralSettingsModel>): GeneralSettingsModel => r.body)
    );
  }

  /** Path part for operation `apiAccountLogNavigationPost()` */
  static readonly ApiAccountLogNavigationPostPath = '/api/Account/LogNavigation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `LogNavigation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  LogNavigation$Response(params?: LogNavigation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return LogNavigation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `LogNavigation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  LogNavigation(params?: LogNavigation$Params, context?: HttpContext): Observable<void> {
    return this.LogNavigation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAccountMeGet()` */
  static readonly ApiAccountMeGetPath = '/api/Account/Me';

  /**
   * Get the user login profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Me()` instead.
   *
   * This method doesn't expect any request body.
   */
  Me$Response(params?: Me$Params, context?: HttpContext): Observable<StrictHttpResponse<UserModel>> {
    return Me(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the user login profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Me$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Me(params?: Me$Params, context?: HttpContext): Observable<UserModel> {
    return this.Me$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /** Path part for operation `apiAccountAuthenticatePost()` */
  static readonly ApiAccountAuthenticatePostPath = '/api/Account/Authenticate';

  /**
   * Authenticate with credentials and retrives access token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Authenticate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  Authenticate$Response(params?: Authenticate$Params, context?: HttpContext): Observable<StrictHttpResponse<JwTokenModel>> {
    return Authenticate(this.http, this.rootUrl, params, context);
  }

  /**
   * Authenticate with credentials and retrives access token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Authenticate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  Authenticate(params?: Authenticate$Params, context?: HttpContext): Observable<JwTokenModel> {
    return this.Authenticate$Response(params, context).pipe(
      map((r: StrictHttpResponse<JwTokenModel>): JwTokenModel => r.body)
    );
  }

  /** Path part for operation `apiAccountRefreshTokenPost()` */
  static readonly ApiAccountRefreshTokenPostPath = '/api/Account/RefreshToken';

  /**
   * POST requests with a refresh token returning a new JWT access token with user details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `RefreshToken()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  RefreshToken$Response(params?: RefreshToken$Params, context?: HttpContext): Observable<StrictHttpResponse<JwTokenModel>> {
    return RefreshToken(this.http, this.rootUrl, params, context);
  }

  /**
   * POST requests with a refresh token returning a new JWT access token with user details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `RefreshToken$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  RefreshToken(params?: RefreshToken$Params, context?: HttpContext): Observable<JwTokenModel> {
    return this.RefreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<JwTokenModel>): JwTokenModel => r.body)
    );
  }

  /** Path part for operation `apiAccountLogoutPost()` */
  static readonly ApiAccountLogoutPostPath = '/api/Account/Logout';

  /**
   * Revoke access token and remove cookies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  Logout$Response(params?: Logout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return Logout(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke access token and remove cookies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Logout(params?: Logout$Params, context?: HttpContext): Observable<void> {
    return this.Logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
