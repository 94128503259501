import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToolbarModule } from 'primeng/toolbar';

import { SupervisorMainComponent } from './supervisor-main.component';
import { SharedModule } from '../shared/shared.module';
import { SupervisorRoutingModule } from './supervisor.routing.module';
import { ProgramsComponent } from './programs/programs.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { TargetsComponent } from './targets/targets.component';
import { ProgramComponent } from './programs/program/program.component';
import { PlaylistComponent } from './playlists/playlist/playlist.component';
import { AddProgramComponent } from './programs/add-program/add-program.component';
import { AddPlaylistComponent } from './playlists/add-playlist/add-playlist.component';
import { AddTargetComponent } from './targets/add-target/add-target.component';
import { TargetComponent } from './targets/target/target.component';
import { AddStaffAlertComponent } from './add-staff-alert/add-staff-alert.component';
import { DragDropModule } from 'primeng/dragdrop';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

import { SkillAreasComponent } from './skill-areas/skill-areas.component';
import { AddSkillAreaComponent } from './skill-areas/add-skill-area/add-skill-area.component';
import { RemindersComponent } from './reminders/reminders.component';
import { AddReminderComponent } from './reminders/add-reminder/add-reminder.component';
import { SkillAreaComponent } from './skill-areas/skill-area/skill-area.component';
import { ReminderComponent } from './reminders/reminder/reminder.component';
import { BehaviorsComponent } from './behaviors/behaviors.component';
import { AddBehaviorComponent } from './behaviors/add-behavior/add-behavior.component';
import { BehaviorComponent } from './behaviors/behavior/behavior.component';
import { PromptCodesPatientComponent } from './prompt-codes/prompt-codes-patient.component';
import { SettingsModule } from '../settings/settings.module';

import { PromptCodesComponent } from '../settings/prompt-codes/prompt-codes.component';
import { PromptCodeComponent } from '../settings/prompt-codes/prompt-code/prompt-code.component';
import { AddPromptCodeComponent } from '../settings/prompt-codes/add-prompt-code/add-prompt-code.component';
import { PromptSchedulesComponent } from '../settings/prompt-schedules/prompt-schedules.component';
import { PromptScheduleComponent } from '../settings/prompt-schedules/prompt-schedule/prompt-schedule.component';
import { AddPromptScheduleComponent } from '../settings/prompt-schedules/add-prompt-schedule/add-prompt-schedule.component';
import { SettingsMainComponent } from '../settings/settings-main.component';
import { PromptSchedulesPatientComponent } from './prompt-schedules/prompt-schedules-patient.component';
import { GraphsModule } from '../graphs/graphs.module';
import { MaintananceCriteriasComponent } from '../settings/maintanance-criterias/maintanance-criterias.component';
import { MaintananceCriteriaPatientComponent } from './maintanance-criteria/maintanance-criteria-patient.component';
import { MasteryCriteriaComponent } from '../settings/mastery-criteria/mastery-criteria.component';
import { ProbeCriteriaComponent } from '../settings/probe-criteria/probe-criteria.component';
import { AntecedentsComponent } from './abc/antecendents/antecedents.component';
import { AntecedentComponent } from './abc/antecendents/antecedent/antecedent.component';
import { AddAntecedentComponent } from './abc/antecendents/add-antecedent/add-antecedent.component';
import { ConsequencesComponent } from './abc/consequences/consequences.component';
import { ConsequenceComponent } from './abc/consequences/consequence/consequence.component';
import { AddConsequenceComponent } from './abc/consequences/add-consequence/add-consequence.component';

const pages = [
  SupervisorMainComponent,
  ProgramsComponent,
  PlaylistsComponent,
  TargetsComponent,
  ProgramComponent,
  PlaylistComponent,
  AddProgramComponent,
  AddPlaylistComponent,
  AddTargetComponent,
  TargetComponent,
  AddStaffAlertComponent,
  SkillAreasComponent,
  AddSkillAreaComponent,
  RemindersComponent,
  AddReminderComponent,
  SkillAreaComponent,
  ReminderComponent,
  BehaviorsComponent,
  AddBehaviorComponent,
  BehaviorComponent,
  PromptCodesPatientComponent,
  PromptSchedulesPatientComponent,

  SettingsMainComponent,
  PromptCodesComponent,
  PromptCodeComponent,
  AddPromptCodeComponent,
  PromptSchedulesComponent,
  PromptScheduleComponent,
  AddPromptScheduleComponent,
  MaintananceCriteriasComponent,
  MaintananceCriteriaPatientComponent,
  MasteryCriteriaComponent,
  ProbeCriteriaComponent,
  AntecedentsComponent,
  AntecedentComponent,
  AddAntecedentComponent,
  ConsequencesComponent,
  ConsequenceComponent,
  AddConsequenceComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GraphsModule,
    SupervisorRoutingModule,
    DragDropModule,
    InputNumberModule,
    CdkDropList,
    CdkDrag,
    InputSwitchModule,
    ToolbarModule,
    SettingsModule,
  ],
  declarations: [...pages],
})
export class SupervisorModule {
  constructor(@Optional() @SkipSelf() parentModule: SupervisorModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import in your base AppModule only.'
      );
    }
  }
}
