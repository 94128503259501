<div class="w-100 d-flex justify-content-center" *ngIf="promptSchedules.length > 0">
  <button (click)="addPromptScheduleButtonClicked()" class="side-menu-button2  w-100">
    + Prompt Schedule
  </button>
</div>
<ul id="prompt-schedules-patient">
  @if (loadingPromptSchedules) {
  <app-loading-indicator />
  } @else { @for (promptSchedule of promptSchedules; track promptSchedule.ID) {
  <app-prompt-schedule [patientId]="patientId" [promptSchedule]="promptSchedule"></app-prompt-schedule>
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no custom Prompt Schedules for this patient.<br>
    <button class="btn btn-primary" (click)="customizePromptCodes()">Customize Prompt Codes</button>
  </p>
  } }
</ul>
@if (showAddPromptSchedule()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddPromptSchedule()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-prompt-schedule [patientId]="patientId" (close)="onCloseAddPromptSchedule()" />
  </dialog>
}