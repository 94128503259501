import {
  Component,
  computed,
  effect,
  input,
  OnInit,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { PatientPlaylistModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { SupervisorHelperService } from '../supervisor.helper.service';
import { SupervisorBaseComponent } from '../supervisor-base.component';
import { AlertService } from '../../shared/services/alert.service';
import { SelectItemGroup } from 'primeng/api';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrl: '../supervisor-main.component.css',
})
export class PlaylistsComponent extends SupervisorBaseComponent {
  patientId = input.required<string>();

  selectedFilters = signal<any[]>([]);
  possibleFilters = computed<SelectItemGroup[]>(() => {
    let programs = this.supervisorHelperService.programs();
    let targets = this.supervisorHelperService.targets();
    return [
      {
        label: 'Programs',
        items: programs.map((program) => {
          return {
            label: program.ProgramName ?? 'No Name for Program',
            value: program,
          };
        }),
      },
      {
        label: 'Targets',
        items: targets.map((target) => {
          return {
            label: target.TargetName ?? 'No Name for Target',
            value: target,
          };
        }),
      },
    ];
  });
  searchKeyword = signal<string | null>(null);

  filteredPlaylists = computed(() => {
    let filteredPlaylists = this.supervisorHelperService.playlists();
    // use keyword from search bar for the first filter
    if (this.searchKeyword() != null && this.searchKeyword() != '') {
      filteredPlaylists = filteredPlaylists.filter((playlist) =>
        playlist.PlaylistName?.toLowerCase().includes(
          this.searchKeyword()!.toLowerCase()
        )
      );
    }
    if (this.selectedFilters() == null) {
      return filteredPlaylists;
    }
    this.selectedFilters().forEach((selectedFilter) => {
      if (this.supervisorHelperService.isPatientProgramModel(selectedFilter)) {
        filteredPlaylists = filteredPlaylists.filter((playlist) =>
          playlist.Items?.some(
            (item) => item.PatientProgramID === selectedFilter.ID
          )
        );
      }
      if (this.supervisorHelperService.isPatientTargetModel(selectedFilter)) {
        filteredPlaylists = filteredPlaylists.filter((playlist) =>
          playlist.Items?.some(
            (item) => item.PatientTargetID === selectedFilter.ID
          )
        );
      }
    });
    return filteredPlaylists;
  });

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      if (this.patientId() != 'null') {
        this.loadBaseData(Number(this.patientId()));
      }
    });
  }

  
  addPlaylistButtonClicked() {
    console.log('addPlaylistButtonClicked');
    this.supervisorHelperService.additPlaylistButtonClicked(null);
  }
}
