

<div class="dashboard">
  <div class="header justify-content-end">
    <div class="side-menu-buttons ">
      <button class="side-menu-button2" (click)="openMenu=!openMenu" >Menu</button>
      <div class="side-menu-buttons menu-top" *ngIf="openMenu">
        <button (click)="switchToStaff()" *ngIf="this.patientSessionService.patientSession()?.ID"
          class="switch-mode-button">
          Back to Session
        </button>
        <button (click)="switchToSupervisor()" class="updates-button me-3">
          Back to Supervisor
        </button>
        <button (click)="backToOpto()" class="side-menu-button2">
          Back to Opto
        </button>
      </div>
    </div>
  </div>
  <div class="modules-container">
    <div class="horizontal-flex-list">
      <a class="main-button" [routerLink]="['/settings', 'prompt-codes']" routerLinkActive="selected">
        <span>Prompt Codes</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'prompt-schedules']" routerLinkActive="selected">
        <span>Prompt Schedules</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'maintanance-criteria']" routerLinkActive="selected">
        <span>Maintanance Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'mastery-criteria']" routerLinkActive="selected">
        <span>Mastery Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'probe-criteria']" routerLinkActive="selected">
        <span>Probe Criteria</span>
      </a>
    </div>

    <router-outlet (activate)="activated($event)"></router-outlet>
  </div>

</div>

@if (loading) {
<app-loading-indicator />
}