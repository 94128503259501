import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientModel, PatientReminderModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-add-reminder',
  templateUrl: './add-reminder.component.html',
  styleUrl: './add-reminder.component.css',
})
export class AddReminderComponent {
  @Input({ required: true }) selectedPatient!: PatientModel;
  reminderToEdit: PatientReminderModel | null = null;

  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  titleName: string = 'Add Reminder';
  actionName: string = 'Add';

  reminderId: number | null = null;
  name = '';
  message = '';
  interval = 0;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    // EDIT LOGIC
    if (this.supervisorHelperService.reminderToEdit() != null) {
      this.titleName = 'Edit Reminder';
      this.actionName = 'Save';
      this.reminderToEdit = this.supervisorHelperService.reminderToEdit();
      this.reminderId = this.reminderToEdit?.ID ?? 0;
      this.name = this.reminderToEdit?.Name ?? '';
      this.message = this.reminderToEdit?.Message ?? '';
      this.interval = this.reminderToEdit?.Interval ?? 0;
    }
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    this.supervisorApiService
      .SavePatientReminder({
        body: {
          ID: this.reminderId,
          Interval: this.interval,
          Message: this.message,
          Name: this.name,
          PatientID: this.selectedPatient?.ID,
        },
      })
      .subscribe(
        (reminder) => {
          console.log(reminder);
          this.loading = false;
          this.alertService.success('Reminder saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
