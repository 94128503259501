<div id="maintanance-criteria" class="container">

  <div *ngIf="hasCustomMasteryCriteria">
    <p>
      <label for="ResponsePercentage">Response Percentage: </label>
          <input type="number" id="ResponsePercentage" name="ResponsePercentage" class="form-control inline-input"
            [(ngModel)]="masteryCriteria.ResponsePercentage" /> %
    </p>

    <p>
      <label for="ConsecutiveDays">Consecutive Days: </label>
          <input type="number" id="ConsecutiveDays" name="ConsecutiveDays" class="form-control inline-input"
            [(ngModel)]="masteryCriteria.ConsecutiveDays" /> 
    </p>

    <p>
      <p-checkbox id="FirstTrialIndependent" name="FirstTrialIndependent" [(ngModel)]="masteryCriteria.FirstTrialIndependent" binary="true" />
      <label for="FirstTrialIndependent">First Trial Independent</label>
    </p>


    @if (loadingMasteryCriteria) {
    <app-loading-indicator />
    }


    <p class="actions">
      <button type="button" (click)="save()" class="btn btn-primary">Save</button>
    </p>
  </div>
<div *ngIf="!hasCustomMasteryCriteria">
  <p>
    Using general settings for mastery criteria.<br>
    <button (click)="enableCustomMasteryCriteria()" class="btn btn-secondary">Enable Custom Mastery Criteria</button>
  </p>
</div>

</div>