@if(step=='start'){
<div class="flex justify-content-end gap-2 p-3">
  <div>
    <p-radioButton [(ngModel)]="handoff" name="handoff" value="staff" />Hand off client to other staff
  </div>
  <div>
    <p-radioButton [(ngModel)]="handoff" name="handoff" value="guardian" />Hand off client to guardian
  </div>
</div>
<div class="flex justify-content-end gap-2 p-3">
  <p-button label="Cancel" severity="secondary" (onClick)="onCancel()" />
  <p-button label="Next" class="ms-3" (onClick)="onNextOnHandoff()" />
</div>
}
@if(step=='nextStaff'){
<div class="flex justify-content-end gap-2 p-3">
  <div>
    This client's next session is starting with {{staffName}}.<br>
    Are you ready for Staff to take over for the next session?
  </div>
</div>
<div class="flex justify-content-end gap-2 p-3">
  <p-button label="No" severity="secondary" (onClick)="onNoOnStaffHandoff()" />
  <p-button label="End Session" class="ms-3" (onClick)="onSubmit()" />
</div>
}
@if (step=='NoOnStaffHandoff'){
<div class="flex justify-content-end gap-2 p-3">
  <div>
    Are you sure you would you like to end your session now without a handoff?<br>
    Explain:<br> <textarea [(ngModel)]="explain" class="w-100"></textarea>
  </div>
</div>
<div class="flex justify-content-end gap-2 p-3">
  <p-button label="Back to session" severity="secondary" (onClick)="onCancel()" />
  <p-button label="End Session" class="ms-3" (onClick)="onSubmit()" />
</div>
}
@if(step=='nextGuardian'){
<div class="flex justify-content-end gap-2 p-3">
  <div>
    This client's sessions are over for the day. Is the parent there for pickup?
  </div>
</div>
<div class="flex justify-content-end gap-2 p-3">
  <p-button label="No" severity="secondary" (onClick)="onNoOnGuardianHandoff()" />
  <p-button label="Yes" class="ms-3" (onClick)="onYesOnGuardianHandoff()" />
</div>
}
@if(step=='NoOnGuardianHandoff'){
<div class="flex justify-content-end gap-2 p-3">
  <div>
    Would you like to end your session without a parent signature?<br>
    Explain:<br> <textarea [(ngModel)]="explain" class="w-100"></textarea>
  </div>
</div>
<div class="flex justify-content-end gap-2 p-3">
  <p-button label="Back to session" severity="secondary" (onClick)="onCancel()" />
  <p-button label="End Session" class="ms-3" (onClick)="onSubmit()" />
</div>
}
@if(step=='YesOnGuardianHandoff'){
<div class="flex justify-content-end gap-2 p-3">
  <div>
    Select person picking client up:<br>
    <p-dropdown [options]="guardians" optionLabel="Name" 
    [(ngModel)]="guardian" placeholder="Select a guardian" appendTo="body" styleClass="w-100"/><br>
    
  </div>
</div>
<div class="flex justify-content-end gap-2 p-3">
  <p-button label="Back to session" severity="secondary" (onClick)="onCancel()" />
  <p-button label="End Session" class="ms-3" (onClick)="onSubmit()" />
</div>
}