/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateCustomPromptCodes } from '../fn/supervisor/create-custom-prompt-codes';
import { CreateCustomPromptCodes$Params } from '../fn/supervisor/create-custom-prompt-codes';
import { DeletePatientPlaylist } from '../fn/supervisor/delete-patient-playlist';
import { DeletePatientPlaylist$Params } from '../fn/supervisor/delete-patient-playlist';
import { DeletePatientProgram } from '../fn/supervisor/delete-patient-program';
import { DeletePatientProgram$Params } from '../fn/supervisor/delete-patient-program';
import { DeletePatientReminder } from '../fn/supervisor/delete-patient-reminder';
import { DeletePatientReminder$Params } from '../fn/supervisor/delete-patient-reminder';
import { DeletePatientTarget } from '../fn/supervisor/delete-patient-target';
import { DeletePatientTarget$Params } from '../fn/supervisor/delete-patient-target';
import { DeletePromptCode } from '../fn/supervisor/delete-prompt-code';
import { DeletePromptCode$Params } from '../fn/supervisor/delete-prompt-code';
import { DeletePromptSchedule } from '../fn/supervisor/delete-prompt-schedule';
import { DeletePromptSchedule$Params } from '../fn/supervisor/delete-prompt-schedule';
import { DeleteSkillArea } from '../fn/supervisor/delete-skill-area';
import { DeleteSkillArea$Params } from '../fn/supervisor/delete-skill-area';
import { DeleteTargetTemplate } from '../fn/supervisor/delete-target-template';
import { DeleteTargetTemplate$Params } from '../fn/supervisor/delete-target-template';
import { GetAllPatients } from '../fn/supervisor/get-all-patients';
import { GetAllPatients$Params } from '../fn/supervisor/get-all-patients';
import { GetMaintananceLevels } from '../fn/supervisor/get-maintanance-levels';
import { GetMaintananceLevels$Params } from '../fn/supervisor/get-maintanance-levels';
import { GetMaintenanceCriteriaFull } from '../fn/supervisor/get-maintenance-criteria-full';
import { GetMaintenanceCriteriaFull$Params } from '../fn/supervisor/get-maintenance-criteria-full';
import { GetMasteryCriteria } from '../fn/supervisor/get-mastery-criteria';
import { GetMasteryCriteria$Params } from '../fn/supervisor/get-mastery-criteria';
import { GetPatientPlaylists } from '../fn/supervisor/get-patient-playlists';
import { GetPatientPlaylists$Params } from '../fn/supervisor/get-patient-playlists';
import { GetPatientPrograms } from '../fn/supervisor/get-patient-programs';
import { GetPatientPrograms$Params } from '../fn/supervisor/get-patient-programs';
import { GetPatientReminders } from '../fn/supervisor/get-patient-reminders';
import { GetPatientReminders$Params } from '../fn/supervisor/get-patient-reminders';
import { GetPatientTargets } from '../fn/supervisor/get-patient-targets';
import { GetPatientTargets$Params } from '../fn/supervisor/get-patient-targets';
import { GetPatientTreatmentGoals } from '../fn/supervisor/get-patient-treatment-goals';
import { GetPatientTreatmentGoals$Params } from '../fn/supervisor/get-patient-treatment-goals';
import { GetProbeCriteria } from '../fn/supervisor/get-probe-criteria';
import { GetProbeCriteria$Params } from '../fn/supervisor/get-probe-criteria';
import { GetPromptCodes } from '../fn/supervisor/get-prompt-codes';
import { GetPromptCodes$Params } from '../fn/supervisor/get-prompt-codes';
import { GetPromptSchedules } from '../fn/supervisor/get-prompt-schedules';
import { GetPromptSchedules$Params } from '../fn/supervisor/get-prompt-schedules';
import { GetSkillAreas } from '../fn/supervisor/get-skill-areas';
import { GetSkillAreas$Params } from '../fn/supervisor/get-skill-areas';
import { GetTargetTemplates } from '../fn/supervisor/get-target-templates';
import { GetTargetTemplates$Params } from '../fn/supervisor/get-target-templates';
import { MaintananceLevelModel } from '../models/maintanance-level-model';
import { MaintenanceCriteriaFullModel } from '../models/maintenance-criteria-full-model';
import { MovePromptCodeDown } from '../fn/supervisor/move-prompt-code-down';
import { MovePromptCodeDown$Params } from '../fn/supervisor/move-prompt-code-down';
import { MovePromptCodeUp } from '../fn/supervisor/move-prompt-code-up';
import { MovePromptCodeUp$Params } from '../fn/supervisor/move-prompt-code-up';
import { PatientModel } from '../models/patient-model';
import { PatientPlaylistModel } from '../models/patient-playlist-model';
import { PatientProgramModel } from '../models/patient-program-model';
import { PatientReminderModel } from '../models/patient-reminder-model';
import { PatientTargetModel } from '../models/patient-target-model';
import { ProbeCriteriaModel } from '../models/probe-criteria-model';
import { PromptCodeModel } from '../models/prompt-code-model';
import { PromptScheduleModel } from '../models/prompt-schedule-model';
import { SaveMaintenanceCriteriaFull } from '../fn/supervisor/save-maintenance-criteria-full';
import { SaveMaintenanceCriteriaFull$Params } from '../fn/supervisor/save-maintenance-criteria-full';
import { SaveMasteryCriteria } from '../fn/supervisor/save-mastery-criteria';
import { SaveMasteryCriteria$Params } from '../fn/supervisor/save-mastery-criteria';
import { SavePatientPlaylist } from '../fn/supervisor/save-patient-playlist';
import { SavePatientPlaylist$Params } from '../fn/supervisor/save-patient-playlist';
import { SavePatientProgram } from '../fn/supervisor/save-patient-program';
import { SavePatientProgram$Params } from '../fn/supervisor/save-patient-program';
import { SavePatientReminder } from '../fn/supervisor/save-patient-reminder';
import { SavePatientReminder$Params } from '../fn/supervisor/save-patient-reminder';
import { SavePatientTarget } from '../fn/supervisor/save-patient-target';
import { SavePatientTarget$Params } from '../fn/supervisor/save-patient-target';
import { SaveProbeCriteria } from '../fn/supervisor/save-probe-criteria';
import { SaveProbeCriteria$Params } from '../fn/supervisor/save-probe-criteria';
import { SavePromptCode } from '../fn/supervisor/save-prompt-code';
import { SavePromptCode$Params } from '../fn/supervisor/save-prompt-code';
import { SavePromptSchedule } from '../fn/supervisor/save-prompt-schedule';
import { SavePromptSchedule$Params } from '../fn/supervisor/save-prompt-schedule';
import { SaveSkillArea } from '../fn/supervisor/save-skill-area';
import { SaveSkillArea$Params } from '../fn/supervisor/save-skill-area';
import { SaveTargetTemplate } from '../fn/supervisor/save-target-template';
import { SaveTargetTemplate$Params } from '../fn/supervisor/save-target-template';
import { SkillAreaModel } from '../models/skill-area-model';
import { TargetTemplateModel } from '../models/target-template-model';
import { TreatmentGoalModel } from '../models/treatment-goal-model';

@Injectable({ providedIn: 'root' })
export class SupervisorApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSupervisorGetTargetTemplatesGet()` */
  static readonly ApiSupervisorGetTargetTemplatesGetPath = '/api/Supervisor/GetTargetTemplates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetTargetTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetTargetTemplates$Response(params?: GetTargetTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TargetTemplateModel>>> {
    return GetTargetTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetTargetTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetTargetTemplates(params?: GetTargetTemplates$Params, context?: HttpContext): Observable<Array<TargetTemplateModel>> {
    return this.GetTargetTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TargetTemplateModel>>): Array<TargetTemplateModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveTargetTemplatePost()` */
  static readonly ApiSupervisorSaveTargetTemplatePostPath = '/api/Supervisor/SaveTargetTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveTargetTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveTargetTemplate$Response(params?: SaveTargetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TargetTemplateModel>> {
    return SaveTargetTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveTargetTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveTargetTemplate(params?: SaveTargetTemplate$Params, context?: HttpContext): Observable<TargetTemplateModel> {
    return this.SaveTargetTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TargetTemplateModel>): TargetTemplateModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeleteTargetTemplatePost()` */
  static readonly ApiSupervisorDeleteTargetTemplatePostPath = '/api/Supervisor/DeleteTargetTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteTargetTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteTargetTemplate$Response(params?: DeleteTargetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteTargetTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteTargetTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteTargetTemplate(params?: DeleteTargetTemplate$Params, context?: HttpContext): Observable<void> {
    return this.DeleteTargetTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPromptSchedulesGet()` */
  static readonly ApiSupervisorGetPromptSchedulesGetPath = '/api/Supervisor/GetPromptSchedules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPromptSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPromptSchedules$Response(params?: GetPromptSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromptScheduleModel>>> {
    return GetPromptSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPromptSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPromptSchedules(params?: GetPromptSchedules$Params, context?: HttpContext): Observable<Array<PromptScheduleModel>> {
    return this.GetPromptSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromptScheduleModel>>): Array<PromptScheduleModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePromptSchedulePost()` */
  static readonly ApiSupervisorSavePromptSchedulePostPath = '/api/Supervisor/SavePromptSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePromptSchedule()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePromptSchedule$Response(params?: SavePromptSchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptScheduleModel>> {
    return SavePromptSchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePromptSchedule$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePromptSchedule(params?: SavePromptSchedule$Params, context?: HttpContext): Observable<PromptScheduleModel> {
    return this.SavePromptSchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptScheduleModel>): PromptScheduleModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePromptSchedulePost()` */
  static readonly ApiSupervisorDeletePromptSchedulePostPath = '/api/Supervisor/DeletePromptSchedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePromptSchedule()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePromptSchedule$Response(params?: DeletePromptSchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePromptSchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePromptSchedule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePromptSchedule(params?: DeletePromptSchedule$Params, context?: HttpContext): Observable<void> {
    return this.DeletePromptSchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPromptCodesGet()` */
  static readonly ApiSupervisorGetPromptCodesGetPath = '/api/Supervisor/GetPromptCodes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPromptCodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPromptCodes$Response(params?: GetPromptCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromptCodeModel>>> {
    return GetPromptCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPromptCodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPromptCodes(params?: GetPromptCodes$Params, context?: HttpContext): Observable<Array<PromptCodeModel>> {
    return this.GetPromptCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromptCodeModel>>): Array<PromptCodeModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePromptCodePost()` */
  static readonly ApiSupervisorSavePromptCodePostPath = '/api/Supervisor/SavePromptCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePromptCode()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePromptCode$Response(params?: SavePromptCode$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptCodeModel>> {
    return SavePromptCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePromptCode$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePromptCode(params?: SavePromptCode$Params, context?: HttpContext): Observable<PromptCodeModel> {
    return this.SavePromptCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptCodeModel>): PromptCodeModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorCreateCustomPromptCodesPost()` */
  static readonly ApiSupervisorCreateCustomPromptCodesPostPath = '/api/Supervisor/CreateCustomPromptCodes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateCustomPromptCodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  CreateCustomPromptCodes$Response(params?: CreateCustomPromptCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return CreateCustomPromptCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `CreateCustomPromptCodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  CreateCustomPromptCodes(params?: CreateCustomPromptCodes$Params, context?: HttpContext): Observable<void> {
    return this.CreateCustomPromptCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorMovePromptCodeUpPost()` */
  static readonly ApiSupervisorMovePromptCodeUpPostPath = '/api/Supervisor/MovePromptCodeUp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `MovePromptCodeUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  MovePromptCodeUp$Response(params?: MovePromptCodeUp$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return MovePromptCodeUp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `MovePromptCodeUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  MovePromptCodeUp(params?: MovePromptCodeUp$Params, context?: HttpContext): Observable<void> {
    return this.MovePromptCodeUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorMovePromptCodeDownPost()` */
  static readonly ApiSupervisorMovePromptCodeDownPostPath = '/api/Supervisor/MovePromptCodeDown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `MovePromptCodeDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  MovePromptCodeDown$Response(params?: MovePromptCodeDown$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return MovePromptCodeDown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `MovePromptCodeDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  MovePromptCodeDown(params?: MovePromptCodeDown$Params, context?: HttpContext): Observable<void> {
    return this.MovePromptCodeDown$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePromptCodePost()` */
  static readonly ApiSupervisorDeletePromptCodePostPath = '/api/Supervisor/DeletePromptCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePromptCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePromptCode$Response(params?: DeletePromptCode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePromptCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePromptCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePromptCode(params?: DeletePromptCode$Params, context?: HttpContext): Observable<void> {
    return this.DeletePromptCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetSkillAreasGet()` */
  static readonly ApiSupervisorGetSkillAreasGetPath = '/api/Supervisor/GetSkillAreas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSkillAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSkillAreas$Response(params?: GetSkillAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SkillAreaModel>>> {
    return GetSkillAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSkillAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSkillAreas(params?: GetSkillAreas$Params, context?: HttpContext): Observable<Array<SkillAreaModel>> {
    return this.GetSkillAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SkillAreaModel>>): Array<SkillAreaModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveSkillAreaPost()` */
  static readonly ApiSupervisorSaveSkillAreaPostPath = '/api/Supervisor/SaveSkillArea';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveSkillArea()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveSkillArea$Response(params?: SaveSkillArea$Params, context?: HttpContext): Observable<StrictHttpResponse<SkillAreaModel>> {
    return SaveSkillArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveSkillArea$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveSkillArea(params?: SaveSkillArea$Params, context?: HttpContext): Observable<SkillAreaModel> {
    return this.SaveSkillArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<SkillAreaModel>): SkillAreaModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeleteSkillAreaPost()` */
  static readonly ApiSupervisorDeleteSkillAreaPostPath = '/api/Supervisor/DeleteSkillArea';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteSkillArea()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteSkillArea$Response(params?: DeleteSkillArea$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteSkillArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteSkillArea$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteSkillArea(params?: DeleteSkillArea$Params, context?: HttpContext): Observable<void> {
    return this.DeleteSkillArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetAllPatientsGet()` */
  static readonly ApiSupervisorGetAllPatientsGetPath = '/api/Supervisor/GetAllPatients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllPatients()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllPatients$Response(params?: GetAllPatients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientModel>>> {
    return GetAllPatients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetAllPatients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllPatients(params?: GetAllPatients$Params, context?: HttpContext): Observable<Array<PatientModel>> {
    return this.GetAllPatients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientModel>>): Array<PatientModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientProgramsGet()` */
  static readonly ApiSupervisorGetPatientProgramsGetPath = '/api/Supervisor/GetPatientPrograms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientPrograms()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPrograms$Response(params?: GetPatientPrograms$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientProgramModel>>> {
    return GetPatientPrograms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientPrograms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPrograms(params?: GetPatientPrograms$Params, context?: HttpContext): Observable<Array<PatientProgramModel>> {
    return this.GetPatientPrograms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientProgramModel>>): Array<PatientProgramModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientProgramPost()` */
  static readonly ApiSupervisorSavePatientProgramPostPath = '/api/Supervisor/SavePatientProgram';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientProgram()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientProgram$Response(params?: SavePatientProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientProgramModel>> {
    return SavePatientProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientProgram$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientProgram(params?: SavePatientProgram$Params, context?: HttpContext): Observable<PatientProgramModel> {
    return this.SavePatientProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientProgramModel>): PatientProgramModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientProgramPost()` */
  static readonly ApiSupervisorDeletePatientProgramPostPath = '/api/Supervisor/DeletePatientProgram';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientProgram$Response(params?: DeletePatientProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientProgram(params?: DeletePatientProgram$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientPlaylistsGet()` */
  static readonly ApiSupervisorGetPatientPlaylistsGetPath = '/api/Supervisor/GetPatientPlaylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientPlaylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPlaylists$Response(params?: GetPatientPlaylists$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientPlaylistModel>>> {
    return GetPatientPlaylists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientPlaylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPlaylists(params?: GetPatientPlaylists$Params, context?: HttpContext): Observable<Array<PatientPlaylistModel>> {
    return this.GetPatientPlaylists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientPlaylistModel>>): Array<PatientPlaylistModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientPlaylistPost()` */
  static readonly ApiSupervisorSavePatientPlaylistPostPath = '/api/Supervisor/SavePatientPlaylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientPlaylist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientPlaylist$Response(params?: SavePatientPlaylist$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientPlaylistModel>> {
    return SavePatientPlaylist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientPlaylist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientPlaylist(params?: SavePatientPlaylist$Params, context?: HttpContext): Observable<PatientPlaylistModel> {
    return this.SavePatientPlaylist$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientPlaylistModel>): PatientPlaylistModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientPlaylistPost()` */
  static readonly ApiSupervisorDeletePatientPlaylistPostPath = '/api/Supervisor/DeletePatientPlaylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientPlaylist()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientPlaylist$Response(params?: DeletePatientPlaylist$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientPlaylist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientPlaylist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientPlaylist(params?: DeletePatientPlaylist$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientPlaylist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientTargetsGet()` */
  static readonly ApiSupervisorGetPatientTargetsGetPath = '/api/Supervisor/GetPatientTargets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientTargets()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargets$Response(params?: GetPatientTargets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientTargetModel>>> {
    return GetPatientTargets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientTargets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargets(params?: GetPatientTargets$Params, context?: HttpContext): Observable<Array<PatientTargetModel>> {
    return this.GetPatientTargets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientTargetModel>>): Array<PatientTargetModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientTreatmentGoalsGet()` */
  static readonly ApiSupervisorGetPatientTreatmentGoalsGetPath = '/api/Supervisor/GetPatientTreatmentGoals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientTreatmentGoals()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTreatmentGoals$Response(params?: GetPatientTreatmentGoals$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TreatmentGoalModel>>> {
    return GetPatientTreatmentGoals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientTreatmentGoals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTreatmentGoals(params?: GetPatientTreatmentGoals$Params, context?: HttpContext): Observable<Array<TreatmentGoalModel>> {
    return this.GetPatientTreatmentGoals$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TreatmentGoalModel>>): Array<TreatmentGoalModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientTargetPost()` */
  static readonly ApiSupervisorSavePatientTargetPostPath = '/api/Supervisor/SavePatientTarget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientTarget()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTarget$Response(params?: SavePatientTarget$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientTargetModel>> {
    return SavePatientTarget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientTarget$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTarget(params?: SavePatientTarget$Params, context?: HttpContext): Observable<PatientTargetModel> {
    return this.SavePatientTarget$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientTargetModel>): PatientTargetModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientTargetPost()` */
  static readonly ApiSupervisorDeletePatientTargetPostPath = '/api/Supervisor/DeletePatientTarget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientTarget()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientTarget$Response(params?: DeletePatientTarget$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientTarget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientTarget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientTarget(params?: DeletePatientTarget$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientTarget$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientRemindersGet()` */
  static readonly ApiSupervisorGetPatientRemindersGetPath = '/api/Supervisor/GetPatientReminders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientReminders()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientReminders$Response(params?: GetPatientReminders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientReminderModel>>> {
    return GetPatientReminders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientReminders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientReminders(params?: GetPatientReminders$Params, context?: HttpContext): Observable<Array<PatientReminderModel>> {
    return this.GetPatientReminders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientReminderModel>>): Array<PatientReminderModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientReminderPost()` */
  static readonly ApiSupervisorSavePatientReminderPostPath = '/api/Supervisor/SavePatientReminder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientReminder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientReminder$Response(params?: SavePatientReminder$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientReminderModel>> {
    return SavePatientReminder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientReminder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientReminder(params?: SavePatientReminder$Params, context?: HttpContext): Observable<PatientReminderModel> {
    return this.SavePatientReminder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientReminderModel>): PatientReminderModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientReminderPost()` */
  static readonly ApiSupervisorDeletePatientReminderPostPath = '/api/Supervisor/DeletePatientReminder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientReminder()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientReminder$Response(params?: DeletePatientReminder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientReminder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientReminder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientReminder(params?: DeletePatientReminder$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientReminder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetMaintenanceCriteriaFullGet()` */
  static readonly ApiSupervisorGetMaintenanceCriteriaFullGetPath = '/api/Supervisor/GetMaintenanceCriteriaFull';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetMaintenanceCriteriaFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMaintenanceCriteriaFull$Response(params?: GetMaintenanceCriteriaFull$Params, context?: HttpContext): Observable<StrictHttpResponse<MaintenanceCriteriaFullModel>> {
    return GetMaintenanceCriteriaFull(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetMaintenanceCriteriaFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMaintenanceCriteriaFull(params?: GetMaintenanceCriteriaFull$Params, context?: HttpContext): Observable<MaintenanceCriteriaFullModel> {
    return this.GetMaintenanceCriteriaFull$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaintenanceCriteriaFullModel>): MaintenanceCriteriaFullModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveMaintenanceCriteriaFullPost()` */
  static readonly ApiSupervisorSaveMaintenanceCriteriaFullPostPath = '/api/Supervisor/SaveMaintenanceCriteriaFull';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveMaintenanceCriteriaFull()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveMaintenanceCriteriaFull$Response(params?: SaveMaintenanceCriteriaFull$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return SaveMaintenanceCriteriaFull(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveMaintenanceCriteriaFull$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveMaintenanceCriteriaFull(params?: SaveMaintenanceCriteriaFull$Params, context?: HttpContext): Observable<void> {
    return this.SaveMaintenanceCriteriaFull$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetProbeCriteriaGet()` */
  static readonly ApiSupervisorGetProbeCriteriaGetPath = '/api/Supervisor/GetProbeCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetProbeCriteria()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetProbeCriteria$Response(params?: GetProbeCriteria$Params, context?: HttpContext): Observable<StrictHttpResponse<ProbeCriteriaModel>> {
    return GetProbeCriteria(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetProbeCriteria$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetProbeCriteria(params?: GetProbeCriteria$Params, context?: HttpContext): Observable<ProbeCriteriaModel> {
    return this.GetProbeCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProbeCriteriaModel>): ProbeCriteriaModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveProbeCriteriaPost()` */
  static readonly ApiSupervisorSaveProbeCriteriaPostPath = '/api/Supervisor/SaveProbeCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveProbeCriteria()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveProbeCriteria$Response(params?: SaveProbeCriteria$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return SaveProbeCriteria(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveProbeCriteria$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveProbeCriteria(params?: SaveProbeCriteria$Params, context?: HttpContext): Observable<void> {
    return this.SaveProbeCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetMaintananceLevelsGet()` */
  static readonly ApiSupervisorGetMaintananceLevelsGetPath = '/api/Supervisor/GetMaintananceLevels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetMaintananceLevels()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMaintananceLevels$Response(params?: GetMaintananceLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MaintananceLevelModel>>> {
    return GetMaintananceLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetMaintananceLevels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMaintananceLevels(params?: GetMaintananceLevels$Params, context?: HttpContext): Observable<Array<MaintananceLevelModel>> {
    return this.GetMaintananceLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MaintananceLevelModel>>): Array<MaintananceLevelModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetMasteryCriteriaGet()` */
  static readonly ApiSupervisorGetMasteryCriteriaGetPath = '/api/Supervisor/GetMasteryCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetMasteryCriteria()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMasteryCriteria$Response(params?: GetMasteryCriteria$Params, context?: HttpContext): Observable<StrictHttpResponse<MaintenanceCriteriaFullModel>> {
    return GetMasteryCriteria(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetMasteryCriteria$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMasteryCriteria(params?: GetMasteryCriteria$Params, context?: HttpContext): Observable<MaintenanceCriteriaFullModel> {
    return this.GetMasteryCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaintenanceCriteriaFullModel>): MaintenanceCriteriaFullModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveMasteryCriteriaPost()` */
  static readonly ApiSupervisorSaveMasteryCriteriaPostPath = '/api/Supervisor/SaveMasteryCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveMasteryCriteria()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveMasteryCriteria$Response(params?: SaveMasteryCriteria$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return SaveMasteryCriteria(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveMasteryCriteria$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveMasteryCriteria(params?: SaveMasteryCriteria$Params, context?: HttpContext): Observable<void> {
    return this.SaveMasteryCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
