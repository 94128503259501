import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-box-duration',
  templateUrl: './box-duration.component.html',
})
export class BoxDurationComponent extends BaseBoxComponent {
  @Input() text: string | undefined = '-';
  @Input() amountOfTimes: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() targetID: number = 0;
  isStarted: boolean = false;
  timerValue: number = 0;
  private startTime: number = 0;
  times: { ID?: number, Text: string, Duration: number, RecordedDate: string, FormattedDate: string }[] = [
  ];
  targetData: PatientTargetDataModel[] = [];


  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData) {
        this.targetData = targetData;
        this.times = targetData.map(t => ({ ID: t.ID, Text: t.TextValue ?? '', Duration: t.IntValue ?? 0, RecordedDate: t.RecordedDate ?? '', FormattedDate: this.formatDate(new Date(t.RecordedDate ?? '')) }));
        this.amountOfTimes = this.times.length;
      }
      else {
        this.targetData = [];
        this.times = [];
        this.amountOfTimes = 0;
      }
    });

    effect(() => {
      const targetsRunningData = this.patientSessionService.getTargetsRunningData(this.targetID);
      if (targetsRunningData) {
        this.isStarted = targetsRunningData.Data.isStarted;
        this.startTime = targetsRunningData.Data.startTime;
        this.timerValue = targetsRunningData.Data.timerValue;
        if (this.isStarted) {
          this.updateTimer();
        }
      } else {
        this.isStarted = false;
        this.startTime = 0;
        this.timerValue = 0;
      }
    });
  }

  override doLongPress(): void {
    this.showExtra();
  }

  startTimer() {
    this.isStarted = true;
    this.startTime = Date.now();
    this.timerValue = 0;
    this.updateTimer();
    this.saveTargetRunningData();
  }

  private updateTimer(): void {
    if (this.isStarted) {
      const currentTime = Date.now();
      this.timerValue = Math.floor((currentTime - this.startTime) / 1000);

      setTimeout(() => {
        this.updateTimer()
        this.saveTargetRunningData();
      }, 1000);
    }
  }


  saveTargetRunningData() {
    this.patientSessionService.setTargetsRunningData(this.targetID, { isStarted: this.isStarted, startTime: this.startTime, timerValue: this.timerValue });
  }

  stopTimer() {
    if (this.timerValue > 0) {
      this.saveTime();
      this.amountOfTimes = this.times.length;
    }
    this.timerValue = 0;
    this.isStarted = false;
    this.startTime = 0;
    this.saveTargetRunningData();
  }

  saveTime() {
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
    const formattedTime = this.timerString();
    this.patientSessionService.addTargetData({
      PatientTargetID: this.targetID,
      TextValue: formattedTime,
      IntValue: this.timerValue,
      RecordedDate: currentDate.toServerString()
    });
    this.times.unshift({ Text: formattedTime, Duration: this.timerValue, RecordedDate: currentDate.toServerString(), FormattedDate: formattedDate });
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

}