<div class="extra-container-textbox abc-container-textbox" [class.empty]="!editingABCData">
    <div class="abc-card">
        <div class="abc-tabs">
            <div class="abc-add-button" [class.first-add]="!editingABCData" (click)="addABCData()" *ngIf="!isDurationBehavior()">+ {{!editingABCData ? 'Add ABC Data' : ''}}</div>
            <div class="abc-tab" *ngFor="let data of abcData; let i = index" 
                 [class.active]="data === editingABCData" 
                 (click)="selectTab(data)">
                 @if (isDurationBehavior()) {
                    {{getDurationTimeRangeString(data)}}
                 }
                 @else {
                    {{formatDateFromString(data.RecordedDate??'')}}
                 }
            </div>
        </div>
        <div class="abc-card-content" *ngIf="editingABCData">
            <div class="abc-item-row">
                <div class="abc-item" [style.background-color]="'#8ac379'">
                    <div class="abc-item-name">
                        Antecedent</div>
                </div>
                <div class="abc-item-value">
                    <div>
                        <p-multiSelect autoWidth="false" [style]="{ width: '100%' }" appendTo="body" styleClass="abc-dropdown"
                            [options]="patientBehaviorAntecedents" [(ngModel)]="editingABCData.PatientBehaviorAntecedentIDs" (ngModelChange)="changedAntecedent()"
                            optionLabel="PatientBehaviorAntecedent" optionValue="ID" [filter]="true" filterBy="PatientBehaviorAntecedent" [showClear]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" placeholder="Select..." (click)="setExtraBoxes()">
                            <ng-template pTemplate="selectedItem" let-selectedOption >
                                <div class="flex align-items-center gap-2">
                                    <div>{{ selectedOption.PatientBehaviorAntecedent }}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <input class="form-control mt-2" type="text" *ngIf="isOtherAntecedentSelected" [(ngModel)]="editingABCData.PatientBehaviorAntecedentOther"  (ngModelChange)="changedABCData()"/>
                    </div>
                </div>
            </div>
            <div class="abc-item-row">
                <div class="abc-item" [style.background-color]="'#cc9e7f'">
                    <div class="abc-item-name">
                        Behavior</div>
                </div>
                <div class="abc-item-value">
                    <div>
                        <p-multiSelect autoWidth="false" [style]="{ width: '100%' }" appendTo="body"
                            [options]="patientBehaviors" [(ngModel)]="editingABCData.OtherPatientBehaviorIDs" (ngModelChange)="changedBehaviors()"
                            optionLabel="PatientBehaviorName" optionValue="ID" [filter]="true" filterBy="PatientBehaviorName" [showClear]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" placeholder="Select..." (click)="setExtraBoxes()">
                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                    <div>{{ selectedOption.PatientBehaviorName }}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>
            </div>
            <div class="abc-item-row">
                <div class="abc-item" [style.background-color]="'#c15757'">
                    <div class="abc-item-name">
                        Consequence</div>
                </div>
                <div class="abc-item-value">
                    <div>
                        <p-multiSelect autoWidth="false" [style]="{ width: '100%' }" appendTo="body"
                            [options]="patientBehaviorConsequences" [(ngModel)]="editingABCData.PatientBehaviorConsequenceIDs" (ngModelChange)="changedConsequences()"
                            optionLabel="PatientBehaviorConsequence" optionValue="ID"[filter]="true" filterBy="PatientBehaviorConsequence" [showClear]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" placeholder="Select..." (click)="setExtraBoxes()">
                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                    <div>{{ selectedOption.PatientBehaviorConsequence }}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <input class="form-control mt-2" type="text" *ngIf="isOtherConsequenceSelected" [(ngModel)]="editingABCData.PatientBehaviorConsequenceOther"  (ngModelChange)="changedABCData()"/>
                    </div>
                </div>
            </div>
            <div class="abc-item-row">
                <div class="abc-item" [style.background-color]="'#68a1b7'">
                    <div class="abc-item-name">
                        Severity</div>
                </div>
                <div class="abc-item-value">
                    <div>
                        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" appendTo="body"
                            [options]="patientBehaviorSeverities" [(ngModel)]="editingABCData.PatientBehaviorSeverityID"  (ngModelChange)="changedABCData()"
                            optionLabel="PatientBehaviorSeverity" optionValue="ID" [filter]="true" filterBy="PatientBehaviorSeverity" [showClear]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" placeholder="Select..." (click)="setExtraBoxes()">
                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                    <div>{{ selectedOption.PatientBehaviorSeverity }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="abc-item-row">
                <div class="abc-item" [style.background-color]="'#68a1b7'">
                    <div class="abc-item-name">
                        Response</div>
                </div>
                <div class="abc-item-value">
                    <div>
                        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" appendTo="body"
                            [options]="patientBehaviorResponses" [(ngModel)]="editingABCData.PatientBehaviorResponseID"  (ngModelChange)="changedABCData()"
                            optionLabel="PatientBehaviorResponse" optionValue="ID" [filter]="true" filterBy="PatientBehaviorResponse" [showClear]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" placeholder="Select..." (click)="setExtraBoxes()">
                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                    <div>{{ selectedOption.PatientBehaviorResponse }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="abc-item-row">
                <div class="abc-item" [style.background-color]="'#68a1b7'">
                    <div class="abc-item-name">
                        Location</div>
                </div>
                <div class="abc-item-value">
                    <div>
                        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" appendTo="body"
                            [options]="patientBehaviorLocations" [(ngModel)]="editingABCData.PatientBehaviorLocationID"  (ngModelChange)="changedABCData()"
                            optionLabel="PatientBehaviorLocation" optionValue="ID" [filter]="true" filterBy="PatientBehaviorLocation" [showClear]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="50" placeholder="Select..." (click)="setExtraBoxes()">
                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                    <div>{{ selectedOption.PatientBehaviorLocation }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="abc-item-row">
                <textarea class="form-control" placeholder="Notes" [(ngModel)]="editingABCData.Notes" (ngModelChange)="changedABCData()"></textarea>
            </div>
        </div>
    </div>
</div>