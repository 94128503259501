import { effect, Injectable, signal } from '@angular/core';
import {
  PatientBehaviorModel,
  PatientPlaylistItemModel,
  PatientPlaylistModel,
  PatientProgramModel,
  PatientReminderModel,
  PatientTargetModel,
  PromptCodeModel,
  PromptScheduleModel,
  SkillAreaModel,
} from '../core/api/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsHelperService {
  updateSignal = signal(0);

  promptCodeToEdit = signal<PromptCodeModel | null>(null);
  promptScheduleToEdit = signal<PromptScheduleModel | null>(null);
  showAddPromptCode = signal(false);
  showAddPromptSchedule = signal(false);
  constructor() {
    console.log('SettingsHelperService constructor');
    effect(() => {
      console.log('update signal triggered: ', this.updateSignal());
    });
  }

  addPromptCodeButtonClicked(promptCode: PromptCodeModel | null) {
    this.promptCodeToEdit.set(promptCode);
    this.showAddPromptCode.set(true);
    this.updateSignal.set(this.updateSignal() + 1);
  }

  onCloseAddPromptCode() {
    this.promptCodeToEdit.set(null);
    this.showAddPromptCode.set(false);
    this.updateSignal.set(this.updateSignal() + 1);
  }

  addPromptScheduleButtonClicked(promptSchedule: PromptScheduleModel | null) {
    this.promptScheduleToEdit.set(promptSchedule);
    this.showAddPromptSchedule.set(true);
    this.updateSignal.set(this.updateSignal() + 1);
  }

  onCloseAddPromptSchedule() {
    this.promptScheduleToEdit.set(null);
    this.showAddPromptSchedule.set(false);
    this.updateSignal.set(this.updateSignal() + 1);
  }
}
