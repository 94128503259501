@if (isCollapsed) {
<div class="container" (click)="toggleCollapsed()">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ reminder?.Name }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
      >
        {{ reminder?.Name }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    <div class="col-8">
      <div class="side-menu-buttons-horizontal">
        <button (click)="editReminder()" class="side-menu-button2">
          Edit Reminder
        </button>
        <button (click)="deleteReminder()" class="switch-mode-button">
          Delete Reminder
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <p-table [value]="[reminder]" [tableStyle]="{ 'min-width': '40rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th style="text-align: center">Reminder Name</th>
          <th style="text-align: center">Reminder Interval</th>
          <th style="text-align: center">Reminder Message</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-target>
        <tr>
          <td style="text-align: center">
            {{ reminder?.Name }}
          </td>
          <td style="text-align: center">
            {{ reminder?.Interval + " seconds" }}
          </td>
          <td style="text-align: center">
            {{ reminder?.Message }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No reminders found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
}
