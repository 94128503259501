import { Component, input, Input, OnInit } from '@angular/core';
import {
  PatientPlaylistModel,
  PatientProgramModel,
  PatientTargetModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { SupervisorBaseComponent } from '../../supervisor-base.component';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class PlaylistComponent extends SupervisorBaseComponent {
  patientId = input.required<string>();
  @Input() playlist: PatientPlaylistModel | null = null;

  isCollapsed = true;

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
  }

  programForID(programID: number) {
    return this.supervisorHelperService
      .programs()
      .find((p) => p.ID === programID);
  }

  targetForID(targetID: number) {
    return this.supervisorHelperService
      .targets()
      .find((t) => t.ID === targetID);
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  get playlistNameDisplayName() {
    return this.playlist?.PlaylistName || 'No Name';
  }

  get playlistItemsNames() {
    const itemsNames = this.playlist?.Items?.map((item) => {
      if (item.PatientProgramID != null) {
        return this.programForID(item.PatientProgramID)?.ProgramName;
      } else if (item.PatientTargetID != null) {
        return this.targetForID(item.PatientTargetID)?.TargetName;
      } else {
        return 'Item has no name';
      }
    });
    return itemsNames?.join(', ') || 'No Items';
  }

  editPlaylist() {
    console.log('editPlaylist clicked!');
    this.supervisorHelperService.additPlaylistButtonClicked(
      this.playlist ?? null
    );
  }

  deletePlaylist() {
    console.log('deletePlaylist clicked!');
    if (this.playlist?.ID != null) {
      this.supervisorApiService
        .DeletePatientPlaylist({ patientPlaylistID: this.playlist.ID })
        .subscribe(
          () => {
            this.alertService.success('Playlist deleted successfully');
            this.supervisorHelperService.onCloseAddPlaylist();
          },
          (error) => {
            console.error(error);
            this.alertService.error(Utils.getAjaxError(error));
          }
        );
    }
  }
}
