import { Injectable } from "@angular/core";
import {  ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../auth";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: 'root' })
// protects routes from unauthenticated users
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isSignedIn())
      return true;
    console.warn(`AuthGuard: '${state.url}'`, this.authService.tokenInfo, this.authService.userInfo);
    this.router.navigate(['/signin'], {
      queryParams: { returnUrl: state.url  }
    });
    return false;
  }
}
