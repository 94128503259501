import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { AlertService } from './shared/services/alert.service';
import { AuthService } from './auth';
import { UserModel, GeneralSettingsModel, JwTokenModel } from './core/api/models';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AccountApiService } from './core/api/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  public isSignedIn: boolean = false;
  private subscription!: Subscription;
  message: any = null;

  logoURL: string = '/assets/OPTO Logo.png';

  userInfo: UserModel | null = null;
  generalSettings: GeneralSettingsModel | null = null;
  constructor(private http: HttpClient,
    private swUpdate: SwUpdate,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private messageService: MessageService,
    private authService: AuthService,
    private accountApiService: AccountApiService) {

    console.debug('AppComponent.constructor');

  }

  ngOnInit() {




    this.authService.getSettings().subscribe(settings => {
      this.generalSettings = settings
      this.logoURL = settings?.BusinessLogoUrl || '/assets/OPTO Logo.png';
    });

    this.subscription = this.alertService.getMessage().subscribe(message => {
      this.message = message;
    });

    this.isSignedIn = this.authService.isSignedIn();


    this.router.errorHandler = (error: any) => {
      console.error(error);
      this.router.navigate(['/'], {
        queryParams: { errorMessage: error.message }
      });
      this.alertService.error(error.message);
    };
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let urlTree = this.router.parseUrl(event.urlAfterRedirects);
        let parameters = JSON.stringify(urlTree.queryParams);
        urlTree.queryParams = {};
        let cleanUrl = urlTree.toString();

        let context = JSON.stringify({
          UserID: this.authService.userInfo?.ID,
          TokenExpiresAt: this.authService.tokenInfo?.expires_at,
          RefreshExpiresAt: this.authService.tokenInfo?.refresh_expires_at
          //localStorage.getItem('currentUser');
        });

        this.accountApiService.LogNavigation({ body: { Url: cleanUrl, Parameters: parameters, Method: 'ROUTE', Context: context } })
          .forEach(() => { });
        (window as any).NavigationRouterState = 'NavigationEnd: ' + cleanUrl;
      } else if (event instanceof NavigationStart) {
        (window as any).NavigationRouterState = 'NavigationStart';
      }
      else if (event instanceof NavigationCancel) {
        (window as any).NavigationRouterState = 'NavigationCancel';
      }
      else if (event instanceof NavigationError) {
        (window as any).NavigationRouterState = 'NavigationError';
      }
      console.debug((window as any).NavigationRouterState);
    });









    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(v => {
        if (v) {
          this.alertService.confirm("Update", "You're using an old version of the ArkMed Spark. Want to update?").subscribe(result => {
            if (result)
              window.location.reload();
          });
        }
      });

    }

    /*
    var deferredPrompt: any;
    window.addEventListener('beforeinstallprompt', (e) => {
      deferredPrompt = e;
    });

    const installApp = document.getElementById('installApp');
    if (installApp)
      installApp.addEventListener('click', async () => {
        if (deferredPrompt !== null) {
          deferredPrompt.prompt();
          const { outcome } = await deferredPrompt.userChoice;
          if (outcome === 'accepted') {
            deferredPrompt = null;
          }
        }
      });
      */
    // https://spark.optomiser-development.com/
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();

        const deferredPrompt = e as any;

        const installButton = document.createElement('button');
        installButton.textContent = 'Install App';
        installButton.style.position = 'fixed';
        installButton.style.bottom = '10px';
        // installButton.style.right = '10px';
        installButton.style.left = '50%';
        installButton.style.transform = 'translateX(-50%)';
        installButton.style.zIndex = '9999';
        installButton.style.padding = '10px 20px';
        installButton.classList.add('btn');
        installButton.classList.add('btn-lg');
        installButton.classList.add('btn-primary');
        /*
        installButton.style.color = 'white';
        installButton.style.border = 'none';
        installButton.style.borderRadius = '5px';
        installButton.style.cursor = 'pointer';
    */
        installButton.addEventListener('click', () => {

          deferredPrompt.prompt();


          (deferredPrompt.userChoice as Promise<{
            outcome: 'accepted' | 'dismissed',
            platform: string
          }>).then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
              console.log('App installed');
            } else {
              console.log('App installation declined');
            }

            installButton.style.display = 'none';
          });
        });

        document.body.appendChild(installButton);
      });
    }




  }

  ngAfterViewInit(): void {



    console.debug('AppComponent.AfterViewInit');


  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onConfirm() {
    this.messageService.clear('confirmToat');

    this.alertService.$confirm.next(true);

    this.alertService.$confirm.complete();

  }
  onReject() {
    this.messageService.clear('confirmToat');
    this.alertService.$confirm.next(false);

    this.alertService.$confirm.complete();
  }
  clearMessage() {
    this.messageService.clear('confirmToat');
    this.message = null;
  }

}
