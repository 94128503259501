import { Component, Input } from '@angular/core';
import { PatientBehaviorModel } from '../../../core/api/models';
import { StaffApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-behavior',
  templateUrl: './behavior.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class BehaviorComponent {
  @Input() behavior: PatientBehaviorModel | undefined;

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  patientBehaviorTypeLabel(behaviorType: number | undefined) {
    if (!behaviorType) return 'Unspecified';
    return Utils.patientBehaviorTypeLabel(behaviorType);
  }

  editBehavior() {
    console.log('editBehavior clicked!');
    this.supervisorHelperService.addBehaviorButtonClicked(
      this.behavior ?? null
    );
  }
}
