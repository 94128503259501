import { Component, input, signal, ViewChild } from '@angular/core';
import {
  GraphDataModel,
  GraphFrequency,
  GraphType,
  PatientSessionDataModel,
  PatientSessionEntryModel,
  PatientSessionModel,
  PatientTargetModel,
  TargetType,
} from '../../core/api/models';
import { GraphApiService, SupervisorApiService } from '../../core/api/services';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexAnnotations,
  ApexLegend,
  ApexMarkers,
  ApexTooltip,
  ApexResponsive,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
} from 'ng-apexcharts';
import { SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Utils } from '../../core/utils';

type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  responsive: ApexResponsive[];
  plotOptions: ApexPlotOptions;
  colors: string[];
};

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrl: './targets.component.css',
})
export class TargetsComponent {
  patientId = input.required<string>();

  isLoading: boolean = false;

  selectableTargets = signal<SelectItem[]>([]);
  selectedTarget: PatientTargetModel | null = null;

  constructor(private supervisorApiService: SupervisorApiService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.supervisorApiService
      .GetPatientTargets({ patientID: Number(this.patientId()) })
      .subscribe(
        (targets) => {
          console.log(targets);
          this.selectableTargets.set(
            targets.map((target) => ({
              text: target.TargetName ?? 'No Name For Target',
              value: target,
              disabled:
                target.TargetType !== TargetType.DiscreteTrialTeaching &&
                target.TargetType !== TargetType.Echoic &&
                target.TargetType !== TargetType.Frequency &&
                target.TargetType !== TargetType.Duration &&
                target.TargetType !== TargetType.Quantity &&
                target.TargetType !== TargetType.FluencyRate,
            }))
          );
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
  }

  onTargetChange(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }
  }
}
