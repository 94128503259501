
<button (click)="addConsequenceButtonClicked()" class="side-menu-button2 w-100 ">
  + Consequence
</button>
<ul id="skillAreas">
  @if (loadingConsequences) {
  <app-loading-indicator />
  } @else { @for (consequence of consequences; track consequence.ID) {
  <app-consequence [consequence]="consequence"></app-consequence>
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no Consequences yet.
  </p>
  } }
</ul>
@if (showAddConsequence()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddConsequence()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-consequence [patientId]="patientId()" (close)="onCloseAddConsequence()" />
  </dialog>
  }