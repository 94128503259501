<h2 class="form-title">{{ titleName }}</h2>
<div *ngIf="loading">
  <app-loading-indicator></app-loading-indicator>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="control">
    <label for="name">Name</label>
    <input class="form-control" type="text" id="name" name="name" [(ngModel)]="name" />
  </div>
  <div class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit">{{ actionName }}</button>
  </div>
</form>
