import { Component, effect, input, signal, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ApexAnnotations,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexGrid,
  ApexStroke,
  ApexTitleSubtitle,
  ApexLegend,
  ApexMarkers,
  ApexTooltip,
  ApexResponsive,
  ApexPlotOptions,
  ChartComponent,
} from 'ng-apexcharts';
import {
  PatientTargetModel,
  GraphDataModel,
  GraphFrequency,
  GraphType,
  TargetType,
} from '../../../core/api/models';
import { DatePipe } from '@angular/common';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import {
  GraphApiService,
  SupervisorApiService,
} from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';

type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  responsive: ApexResponsive[];
  plotOptions: ApexPlotOptions;
  colors: string[];
};

@Component({
  selector: 'app-target-graph',
  templateUrl: './target-graph.component.html',
  styleUrl: './target-graph.component.css',
})
export class TargetGraphComponent {
  @ViewChild('chart', { static: false }) chart: ChartComponent | undefined;
  public chartOptions: Partial<ChartOptions> = {};

  patientId = input.required<string>();
  startDate = signal<Date>(this.dateInPastBy(90));
  endDate = signal<Date>(new Date());

  isLoading: boolean = false;

  targetId = input.required<number>();
  loadedTargets: PatientTargetModel[] = [];
  selectedTarget: PatientTargetModel | null = null;

  graphData = signal<GraphDataModel | null>(null);

  graphTypes: any[] = [];
  selectedGraphType: GraphType | null = null;

  dateOptions: any[] = [
    { value: this.dateInPastBy(1), text: '1 day' },
    { value: this.dateInPastBy(5), text: '5 days' },
    { value: this.dateInPastBy(14), text: '14 days' },
    {
      value: this.dateInPastBy(this.daysToBeginningOfMonth()),
      text: 'Month to Date',
    },
    { value: this.dateInPastBy(30), text: '1 month' },
    { value: this.dateInPastBy(90), text: '3 months' },
    { value: this.dateInPastBy(180), text: '6 months' },
    {
      value: this.dateInPastBy(this.daysToBeginningOfYear()),
      text: 'Year to Date',
    },
  ];
  dateOption: string[] = this.dateOptions[5].value;

  frequencyOptions: any[] = [
    { value: GraphFrequency.BySession, text: 'By Session' },
    { value: GraphFrequency.Daily, text: 'Daily' },
    { value: GraphFrequency.Weekly, text: 'Weekly' },
    { value: GraphFrequency.Monthly, text: 'Monthly' },
    { value: GraphFrequency.Yearly, text: 'Yearly' },
  ];
  frequencyOption: GraphFrequency = this.frequencyOptions[0].value;

  constructor(
    private graphApiService: GraphApiService,
    private supervisorApiService: SupervisorApiService,
    private datePipe: DatePipe,
    private alertService: AlertService
  ) {
    this.loadDefaultGraphOptions();
    effect(() => {
      console.log('targetId changed to:', this.targetId());
      this.selectedGraphType = null;
      this.loadData();
    });
  }

  loadDefaultGraphOptions() {
    this.chartOptions = {
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 300,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value + ' %';
          },
        },
      },
      title: {
        text: 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Dates',
        },
      },
      yaxis: {
        title: {
          text: 'Values',
        },
      },
    };
  }

  ngOnInit(): void {}

  loadData() {
    this.supervisorApiService
      .GetPatientTargets({ patientID: Number(this.patientId()) })
      .subscribe(
        (targets) => {
          console.log(targets);
          this.loadedTargets = targets;
          this.selectedTarget =
            targets.find((target) => target.ID == this.targetId()) ?? null;
          this.loadDataForGraph(this.targetId());
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
  }

  onGraphTypeChange(event: any) {
    this.selectedGraphType = event.value;
    if (this.selectedTarget) {
      if (this.selectedTarget?.ID != null) {
        this.loadDataForGraph(this.selectedTarget.ID);
      }
    } else {
      this.graphData.set(null);
      this.updateGraph();
    }
  }

  onTargetChange(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }
    this.loadDataForGraph(event.value.ID);
  }

  updateAvailableGraphTypes() {
    // set the available graph types based on the target type
    switch (this.selectedTarget?.TargetType) {
      case TargetType.DiscreteTrialTeaching:
      case TargetType.Echoic:
        this.graphTypes = [
          {
            text: '% Correct Responding',
            value: GraphType.PercentageCorrectResponding,
          },
          {
            text: 'Prompt % Distribution',
            value: GraphType.PromptPercentageDistribution,
          },
          { text: 'Prompt Counts', value: GraphType.PromptCounts },
          {
            text: 'Trials by Prompts',
            value: GraphType.TrialsByPrompts,
          },
          {
            text: 'Daily Prompt %',
            value: GraphType.DailyPromptPercentage,
          },
          { text: 'Time of Day', value: GraphType.TimeOfDay },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = GraphType.PercentageCorrectResponding;
        }
        break;
      case TargetType.Frequency:
        this.graphTypes = [
          {
            text: 'Frequency Simple',
            value: GraphType.FrequencySimple,
          },
        ];
        this.selectedGraphType = GraphType.FrequencySimple;
        break;
      case TargetType.Duration:
        this.graphTypes = [
          {
            text: 'Duration Sum',
            value: GraphType.DurationSum,
          },
          {
            text: 'Duration Count',
            value: GraphType.DurationCount,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = GraphType.DurationSum;
        }
        break;
      case TargetType.Quantity:
        this.graphTypes = [
          {
            text: 'Quantity Total',
            value: GraphType.QuantityTotal,
          },
          {
            text: 'Quantity Individual',
            value: GraphType.QuantityIndividual,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = GraphType.QuantityTotal;
        }
        break;
      case TargetType.FluencyRate:
        this.graphTypes = [
          {
            text: 'Fluency Average',
            value: GraphType.FluencyAverage,
          },
          {
            text: 'Fluency Individual',
            value: GraphType.FluencyIndividual,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = GraphType.FluencyAverage;
        }
        break;
      default:
        this.graphTypes = [];
        break;
    }
  }

  loadDataForGraph(targetID: number) {
    this.updateAvailableGraphTypes();

    this.isLoading = true;
    this.graphApiService
      .GetGraph({
        patientID: Number(this.patientId()),
        targetID: targetID,
        startTime: this.startDate().toServerString(),
        endTime: new Date().toServerString(),
        graphType: this.selectedGraphType!,
        graphFrequency: this.frequencyOption,
      })
      .subscribe(
        (data) => {
          console.log(data);
          this.graphData.set(data);
          this.updateGraph();
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.alertService.error('Error fetching data: ' + error.Message);
          this.isLoading = false;
        }
      );
  }

  shouldDisplayGraphTypeOptions(): boolean {
    return (
      this.selectedTarget?.TargetType === TargetType.DiscreteTrialTeaching ||
      this.selectedTarget?.TargetType === TargetType.Echoic ||
      this.selectedTarget?.TargetType === TargetType.Duration ||
      this.selectedTarget?.TargetType === TargetType.FluencyRate ||
      this.selectedTarget?.TargetType === TargetType.Quantity
    );
  }

  shouldDisplayFrequencyOptions(): boolean {
    return (
      this.selectedGraphType === GraphType.PercentageCorrectResponding ||
      this.selectedGraphType === GraphType.TrialsByPrompts
    );
  }

  shouldDisplayGraph(): boolean {
    return (
      this.isLoading === false &&
      this.selectedTarget != null &&
      !this.isGraphEmpty()
    );
  }

  isGraphEmpty(): boolean {
    // check if the graph data is empty
    if (this.graphData() == null) {
      return true;
    }

    // check if series is empty
    if (this.graphData()?.Series?.length == 0) {
      return true;
    }

    // for some graph types, check the lenght of the first series as well.
    switch (this.selectedGraphType) {
      case GraphType.PercentageCorrectResponding:
      case GraphType.TimeOfDay:
      case GraphType.FrequencySimple:
      case GraphType.DurationCount:
      case GraphType.DurationSum:
      case GraphType.QuantityTotal:
      case GraphType.QuantityIndividual:
      case GraphType.FluencyAverage:
      case GraphType.FluencyIndividual:
        return this.graphData()!.Series![0].DataPoints!.length == 0;

      default:
        return false;
    }
  }

  onDateOptionChange(event: any) {
    this.startDate.set(event.value);
    if (this.selectedTarget) {
      this.loadDataForGraph(this.selectedTarget!.ID!);
    }
  }

  onFrequencyOptionChange(event: any) {
    this.frequencyOption = event.value;
    if (this.selectedTarget) {
      this.loadDataForGraph(this.selectedTarget!.ID!);
    }
  }

  dateInPastBy(numberOfDays: number): Date {
    var date = new Date();
    date.setDate(date.getDate() - numberOfDays);
    return date;
  }

  daysToBeginningOfMonth() {
    let currentDate = new Date();

    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1)) /
        (1000 * 60 * 60 * 24)
    );
  }

  daysToBeginningOfYear() {
    let currentDate = new Date();

    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(currentDate.getFullYear(), 1, 1)) /
        (1000 * 60 * 60 * 24)
    );
  }

  updateGraph() {
    if (!this.graphData()) {
      // hide graph
      this.chartOptions.series = [];
      return;
    }
    switch (this.selectedGraphType) {
      case GraphType.PercentageCorrectResponding:
        this.loadPercentageCorrectRespondingGraphOptions();
        break;
      case GraphType.PromptPercentageDistribution:
        this.loadPromptPercentageDistributionGraphOptions();
        break;
      case GraphType.PromptCounts:
        this.loadPromptCountsGraphOptions();
        break;
      case GraphType.TrialsByPrompts:
        this.loadTrialsByPromptsGraphOptions();
        break;
      case GraphType.DailyPromptPercentage:
        this.loadDailyPromptPercentageGraphOptions();
        break;
      case GraphType.TimeOfDay:
        this.loadTimeOfDayGraphOptions();
        break;
      case GraphType.FrequencySimple:
        this.loadFrequencySimpleGraphOptions();
        break;
      case GraphType.DurationSum:
        this.loadDurationSumGraphOptions();
        break;
      case GraphType.DurationCount:
      case GraphType.QuantityTotal:
      case GraphType.QuantityIndividual:
      case GraphType.FluencyAverage:
      case GraphType.FluencyIndividual:
        this.loadDefaultLineGraphOptions();
        break;
      default:
        return;
    }

    // we need to call this so that the options are actually updated on the graph
    this.chart?.updateOptions(this.chartOptions);
  }

  loadPercentageCorrectRespondingGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value + ' %';
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value + ' %';
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadPromptPercentageDistributionGraphOptions() {
    this.chartOptions = {
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      series:
        this.graphData()?.Series!.map((series) => series.Value ?? 0) ?? [],
      chart: {
        type: 'pie',
      },
      colors:
        this.graphData()?.Series!.map((series) => series.Color ?? '#008FFB') ??
        [],
      labels:
        this.graphData()?.Series!.map((series) => series.Name ?? '') ?? [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  loadPromptCountsGraphOptions() {
    this.chartOptions = {
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      series: [
        {
          name: 'count',
          data:
            this.graphData()?.Series!.map((series) => series.Value ?? 0) ?? [],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors:
        this.graphData()?.Series!.map((series) => series.Color ?? '#008FFB') ??
        [],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories:
          this.graphData()?.Series!.map((series) => series.Name ?? '') ?? [],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  }

  loadTrialsByPromptsGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
        color: series.Color ?? '#008FFB',
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'circle',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadDailyPromptPercentageGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
        color: series.Color ?? '#008FFB',
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value + ' %';
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value + ' %';
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadTimeOfDayGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
        color: series.Color ?? '#008FFB',
      })),
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          type: 'xy',
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            // format time from seconds to HH:MM:SS
            return this.formatTime(value);
          },
        },
      },
    };
  }

  loadFrequencySimpleGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadDurationSumGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadDefaultLineGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secsStr = secs.toString().padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secsStr}`;
  }
}
