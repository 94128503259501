import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientBehaviorAntecedentModel, PatientBehaviorConsequenceModel, PatientBehaviorDataModel, PatientBehaviorLocationModel, PatientBehaviorModel, PatientBehaviorResponseModel, PatientBehaviorSeverityModel, PatientBehaviorType } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-abc-data-panel',
  templateUrl: './abc-data-panel.component.html',
})
export class ABCDataPanelComponent  {
  @Input() behaviorID: number = 0;
  @Input() sessionValue: number = 0;
  @Input() behaviorType: PatientBehaviorType = PatientBehaviorType.Frequency;
  behaviorData: PatientBehaviorDataModel[] = [];

  editingABCData?: PatientBehaviorDataModel;
  abcData: any[] = [];
  isOtherAntecedentSelected: boolean = false;
  isOtherConsequenceSelected: boolean = false;

  patientBehaviorLocations: PatientBehaviorLocationModel[] = [];
  patientBehaviorConsequences: PatientBehaviorConsequenceModel[] = [];
  patientBehaviorResponses: PatientBehaviorResponseModel[] = [];
  patientBehaviorSeverities: PatientBehaviorSeverityModel[] = [];
  patientBehaviorAntecedents: PatientBehaviorAntecedentModel[] = [];
  patientBehaviors: PatientBehaviorModel[] = [];

  @Output() setExtraBoxesEvent = new EventEmitter<any>();

  constructor(
    protected cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    effect(() => {
      const behaviorData = this.patientSessionService.geBehaviorData(this.behaviorID);
      if (behaviorData && behaviorData.length > 0) {
        this.behaviorData = behaviorData;
        this.abcData = behaviorData.filter(data => data.Notes != null && data.Notes != undefined);
        if (!this.editingABCData) {
          this.editingABCData = this.abcData[this.abcData.length - 1];
        }
        this.selectedEditingABCData();
        setTimeout(() => {
            //scroll tabs to end
            const tabs = document.querySelectorAll('.abc-tabs');
            tabs.forEach(tab => {
              tab.scrollLeft = tab.scrollWidth;
            });
          }, 100);
      }
      else {
        this.behaviorData = [];
      }
    });

    effect(() => {
      this.patientBehaviorLocations = this.patientSessionService.patientBehaviorLocations();
    });
    effect(() => {
      this.patientBehaviorResponses = this.patientSessionService.patientBehaviorResponses();
    });
    effect(() => {
      this.patientBehaviorSeverities = this.patientSessionService.patientBehaviorSeverities();
    });
    effect(() => {
      this.patientBehaviors = this.patientSessionService.patientBehaviors();
    });
    
    effect(() => {
      this.patientBehaviorConsequences = this.patientSessionService.patientBehaviorConsequences();
      if (!this.patientBehaviorConsequences.find(c => c.ID === -1)) {
        this.patientBehaviorConsequences.push({
          ID: -1,
          PatientBehaviorConsequence: 'Other'
        });
      }
    });
    
    effect(() => {
      this.patientBehaviorAntecedents = this.patientSessionService.patientBehaviorAntecedents();
      if (!this.patientBehaviorAntecedents.find(a => a.ID === -1)) {
      this.patientBehaviorAntecedents.push({
        ID: -1,
        PatientBehaviorAntecedent: 'Other'
        });
      }
    });
  }


  selectTab(data: PatientBehaviorDataModel): void {
    this.editingABCData = data;
    this.selectedEditingABCData();
  }

  selectedEditingABCData(){
    this.isOtherAntecedentSelected = !!this.editingABCData?.PatientBehaviorAntecedentOther || (!!this.editingABCData?.PatientBehaviorAntecedentIDs?.find(id => id === -1));
    this.isOtherConsequenceSelected = !!this.editingABCData?.PatientBehaviorConsequenceOther || (!!this.editingABCData?.PatientBehaviorConsequenceIDs?.find(id => id === -1));
    if (this.isOtherAntecedentSelected && this.editingABCData?.PatientBehaviorAntecedentIDs) {
      // add -1 to the list if it is not already there
      if (!this.editingABCData.PatientBehaviorAntecedentIDs.find(id => id === -1)) {
        this.editingABCData.PatientBehaviorAntecedentIDs.push(-1);
      }
    }
    if (this.isOtherConsequenceSelected &&  this.editingABCData && this.editingABCData.PatientBehaviorConsequenceIDs) {
      //add -1 to the list if it is not already there
      if (!this.editingABCData.PatientBehaviorConsequenceIDs.find(id => id === -1)) {
        this.editingABCData.PatientBehaviorConsequenceIDs.push(-1);
      }
    }

    if (this.editingABCData && !this.editingABCData.OtherPatientBehaviorIDs){
        this.editingABCData.OtherPatientBehaviorIDs = [];
    }
    if (this.editingABCData?.OtherPatientBehaviorIDs && !this.editingABCData.OtherPatientBehaviorIDs.find(id => id === this.behaviorID)) {
    this.editingABCData.OtherPatientBehaviorIDs.push(this.behaviorID);
    }
  }

  isDurationBehavior(): boolean {
    return this.behaviorType === PatientBehaviorType.Duration;
  }

  getDurationTimeRangeString(data: PatientBehaviorDataModel): string {
    //from date is the recorded date and to data is the recorded data plus the intVBalue in seconds
    const fromDate = new Date(data.RecordedDate ?? '');
    const toDate = new Date(fromDate.getTime() + (data.IntValue ?? 0) * 1000);
    return this.formatDate(fromDate) + ' - ' + this.formatDate(toDate);
  }

  formatDateFromString(date: string): string {
    return this.formatDate(new Date(date));
  }

  formatDate(date: Date): string {
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return 'at ' + date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).toLowerCase();
    } else {
      return 'on ' + date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
      });
    }
  }

  addTimeABCData(time: { ID?: number, Text: string, Duration: number, RecordedDate: string, FormattedDate: string }): void {
    let behaviorData: PatientBehaviorDataModel = {
        TextValue: time.Duration.toString(),
        IntValue: time.Duration,
        PatientSessionID: 0,
        PatientBehaviorID: this.behaviorID,
        RecordedDate: time.RecordedDate,
        OtherPatientBehaviorIDs: [this.behaviorID],
        PatientBehaviorAntecedentIDs: [],
        PatientBehaviorConsequenceIDs: [],
        PatientBehaviorResponseID: 0,
        PatientBehaviorSeverityID: 0,
        PatientBehaviorLocationID: 0,
        Notes: ' '
      };
      this.patientSessionService.saveBehaviorData(behaviorData);
  }

  addABCData(): void {
    let behaviorData: PatientBehaviorDataModel = {
      TextValue: this.sessionValue.toString(),
      IntValue: this.sessionValue,
      PatientSessionID: 0,
      PatientBehaviorID: this.behaviorID,
      RecordedDate: new Date().toServerString(),
      OtherPatientBehaviorIDs: [this.behaviorID],
      PatientBehaviorAntecedentIDs: [],
      PatientBehaviorConsequenceIDs: [],
      PatientBehaviorResponseID: 0,
      PatientBehaviorSeverityID: 0,
      PatientBehaviorLocationID: 0,
      Notes: ' '
    };
    this.patientSessionService.addBehaviorData(behaviorData);
  }

  changedBehaviors(): void {
    //Check if this behaviorID was excluded and include it again
    if (this.editingABCData?.OtherPatientBehaviorIDs && !this.editingABCData.OtherPatientBehaviorIDs.find(id => id === this.behaviorID)) {
      this.editingABCData.OtherPatientBehaviorIDs.push(this.behaviorID);
    }
    this.changedABCData();
  }

  changedAntecedent(): void {
    if (this.editingABCData?.PatientBehaviorAntecedentIDs && this.editingABCData.PatientBehaviorAntecedentIDs.find(id => id === -1)) {
      this.isOtherAntecedentSelected = true;
    } else {
      this.isOtherAntecedentSelected = false;
      this.editingABCData && (this.editingABCData.PatientBehaviorAntecedentOther = '');
    }
    this.changedABCData();
  }

  changedConsequences(): void {
    if (this.editingABCData?.PatientBehaviorConsequenceIDs && this.editingABCData.PatientBehaviorConsequenceIDs.find(id => id === -1)) {
      this.isOtherConsequenceSelected = true;
    } else {
      this.isOtherConsequenceSelected = false;
      this.editingABCData && (this.editingABCData.PatientBehaviorConsequenceOther = '');
    }
    this.changedABCData();
  }

  changedABCData(): void {
    if (this.editingABCData) {
      this.patientSessionService.saveBehaviorData(this.editingABCData);
    }
  }

  setExtraBoxes(): void {
    this.setExtraBoxesEvent.emit();
  }

  public setLastEditingABCData(): void {
    this.editingABCData = this.abcData[this.abcData.length - 1];
    setTimeout(() => {
        //scroll tabs to end
        const tabs = document.querySelectorAll('.abc-tabs');
        tabs.forEach(tab => {
          tab.scrollLeft = tab.scrollWidth;
        });
      }, 100);
  }

  public clearEditingABCData(): void {
    this.editingABCData = undefined;
  }

  public setEditingABCData(recordedDate: string): void {
    this.editingABCData = this.abcData.find(data => data.RecordedDate === recordedDate);
    this.selectedEditingABCData();
  }
}
