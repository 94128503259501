import { Component, effect, input } from '@angular/core';
import { PatientBehaviorAntecedentModel, PatientBehaviorConsequenceModel } from '../../../core/api/models';
import { StaffApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-consequences',
  templateUrl: './consequences.component.html',
})
export class ConsequencesComponent {
  patientId = input.required<number>();

  consequences: PatientBehaviorConsequenceModel[] = [];

  loadingConsequences: boolean = false;
  showAddConsequence = this.supervisorHelperService.showAddConsequence;

  constructor(
    private staffApiService: StaffApiService,
    private alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('ConsequencesComponent ngOnInit');
    this.loadData();
  }

  loadData() {
      this.loadingConsequences = true;
    this.staffApiService
      .GetPatientBehaviorConsequences({ patientID: Number(this.patientId()) })
      .subscribe(
        (consequences) => {
          this.loadingConsequences = false;
          this.consequences = consequences;
        },
        (err) => {
          this.loadingConsequences = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }
  
  addConsequenceButtonClicked() {
    console.log('addConsequenceButtonClicked');
    this.supervisorHelperService.addConsequenceButtonClicked(null);
  }

  onCloseAddConsequence() {
    this.supervisorHelperService.onCloseAddConsequence();
  }
}
