import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MenuModule } from 'primeng/menu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';

import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { AlertService } from './services/alert.service';

import { SecurePipe } from './pipes/secure.pipe';
import { StringJoinPipe } from './pipes/string-join.pipe';
import { HtmlTextPipe } from './pipes/plaintext.pipe';
import { DurationPipe } from './pipes/duration.pipe';

import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { AutoFocusModule } from 'primeng/autofocus';
import { AccordionModule } from 'primeng/accordion';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { CardModule } from 'primeng/card';
import { ClickOutsideDirective } from './directives/clickOutside';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from 'primeng/selectbutton';

const components = [
  BottomBarComponent,
  TopBarComponent,
  LoadingIndicatorComponent,
];

const pipes = [
  SecurePipe,
  StringJoinPipe,
  HtmlTextPipe,
  DurationPipe,
];

const services = [
  AlertService,
  ConfirmationService
];


const primeNgModules = [
  MenuModule,
  AutoCompleteModule,
  ToastModule,
  DropdownModule,
  CalendarModule,
  MultiSelectModule,
  DialogModule,
  DropdownModule,
  TableModule,
  FileUploadModule,
  AutoFocusModule,
  AccordionModule,
  InputTextareaModule,
  InputTextModule,
  CheckboxModule,
  ListboxModule,
  ColorPickerModule,
  CardModule,
  RadioButtonModule,
  TabViewModule,
  SelectButtonModule
];

const directives = [
  ClickOutsideDirective
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...primeNgModules
  ],
  declarations: [
    ...components,
    ...pipes,
    ...directives,
    LoadingIndicatorComponent
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ...primeNgModules,
    ...components,
    ...pipes,
    ...directives
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        ...services
      ]
    };
  }
}
