import { Component, Input } from '@angular/core';
import { PatientBehaviorAntecedentModel } from '../../../../core/api/models';
import { StaffApiService } from '../../../../core/api/services';
import { SupervisorHelperService } from '../../../supervisor.helper.service';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-antecedent',
  templateUrl: './antecedent.component.html',
})
export class AntecedentComponent {
  @Input() antecedent: PatientBehaviorAntecedentModel | undefined;

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editAntecedent() {
    console.log('editAntecedent clicked!');
    this.supervisorHelperService.addAntecedentButtonClicked(
      this.antecedent ?? null
    );
  }

  deleteAntecedent() {
    console.log('deleteAntecedent clicked!');

    this.alertService
      .confirm(
        'Delete Antecedent',
        'Are you sure you want to delete this antecedent?'
      )
      .subscribe((result) => {
        if (result) {
          this.deleteAntecedentConfirmed();
        }
      });
  }

  deleteAntecedentConfirmed() {
    if (this.antecedent?.ID != null) {
      this.staffApiService
        .DeleteAntecedent({
          id: this.antecedent.ID,
        })
        .subscribe(
          () => {
            this.alertService.success('Antecedent deleted successfully');
            this.supervisorHelperService.onCloseAddAntecedent();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }
}
