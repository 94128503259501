@if (showAddProgram()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddProgram()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-program [selectedPatientID]="patientIdAsNumber()" (close)="onCloseAddProgram()" />
  </dialog>
  }
<div class="container">
  <div class="row">
    <div class="col-6">
      <p>
        <p-multiSelect
          appendTo="body"
          autoWidth="false"
          [group]="true"
          [style]="{ width: '100%' }"
          [options]="possibleFilters()"
          name="selectedFilters"
          [(ngModel)]="selectedFilters"
          display="chip"
          [showClear]="true"
          defaultLabel="Select Filters"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="footer">
            <div class="py-2 px-3">
              <b>
                {{ selectedFilters().length }}
              </b>
              item{{ selectedFilters().length > 1 ? "s" : "" }}
              selected.
            </div>
          </ng-template>
        </p-multiSelect>
      </p>
    </div>
    <div class="col-6">
      <div class="horizontal-flex-list">
        <label class="checkmarks-label">Search</label>
        <input type="text" [(ngModel)]="searchKeyword" />
      </div>
    </div>
    
  <button (click)="addProgramButtonClicked()" class="side-menu-button2">
    + Program
  </button>
  </div>
</div>

<div>
  @if (isLoading) {
  <app-loading-indicator />
  } @else { @if (filteredPrograms()) { @for (program of filteredPrograms();
  track program.ID) {
  <app-program [patientId]="patientId()" [program]="program" />
  } @empty {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    There are no programs yet.
  </p>
  } } @else {
  <p
    style="
      text-align: center;
      margin-top: 20px;
      font-size: 1rem;
      color: #719eb6;
    "
  >
    Programs are not available.
  </p>
  } }
</div>
