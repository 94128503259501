<div id="maintanance-criteria" class="container">

  <div *ngIf="hasCustomMaintananceCriteria">
    <p>
      <label for="whatMaintananceLevelMastered">What maintance level to move after mastering from Acquisition</label>
      <p-dropdown id="whatMaintananceLevelMastered" name="whatMaintananceLevelMastered" 
      styleClass="w-100"
        [(ngModel)]="maintananceCriteriaSettings.MaintenanceLevelIDWhenFromAquisition" [options]="maintenanceLevels"
        optionLabel="MaintenanceLevel" optionValue="ID" />

    </p>


    <p>
      <label for="whatMaintananceLevelProbe">What maintance level to move after mastering from Probe</label>
      <p-dropdown id="whatMaintananceLevelProbe" name="whatMaintananceLevelProbe" 
      styleClass="w-100"
        [(ngModel)]="maintananceCriteriaSettings.MaintenanceLevelIDWhenFromProbe" [options]="maintenanceLevels"
        optionLabel="MaintenanceLevel" optionValue="ID" />

    </p>


    @if (loadingMaintananceCriteria) {
    <app-loading-indicator />
    } @else { @for (maintenanceCriteria of maintenanceCriterias; track maintenanceCriteria.ID) {
    <div class="row mb-3">
      <div class="col-12">
        <h5>{{getMaintananceLevelName(maintenanceCriteria.MaintenanceLevelID)}}</h5>
        <p>
          <label for="PromotionNeeded">Promotion:</label>
          <input type="number" id="PromotionNeeded" name="PromotionNeeded" class="form-control inline-input"
            [(ngModel)]="maintenanceCriteria.PromotionNeeded" />
          of
          <input type="number" id="PromotionTotal" name="PromotionTotal" class="form-control inline-input"
            [(ngModel)]="maintenanceCriteria.PromotionTotal" />
        </p>

        <p>
          <label for="TargetStatusIDWhenPromoting">Target Status When Promoting</label>
          <p-dropdown id="TargetStatusIDWhenPromoting" name="TargetStatusIDWhenPromoting" 
          styleClass="w-100"
            [(ngModel)]="maintenanceCriteria.TargetStatusIDWhenPromoting" [options]="targetStatuses"
            optionLabel="text" optionValue="value" />

        </p>
        <hr />
        <p>
          <label for="DemotionNeeded">Demotion: </label>
          <input type="number" id="DemotionNeeded" name="DemotionNeeded" class="form-control inline-input"
            [(ngModel)]="maintenanceCriteria.DemotionNeeded" />
          of
          <input type="number" id="DemotionTotal" name="DemotionTotal" class="form-control inline-input"
            [(ngModel)]="maintenanceCriteria.DemotionTotal" />
        </p>

        <p>
          <label for="TargetStatusIDWhenDemoting">Target Status When Demoting</label>
          <p-dropdown id="TargetStatusIDWhenDemoting" name="TargetStatusIDWhenDemoting" 
          styleClass="w-100"
            [(ngModel)]="maintenanceCriteria.TargetStatusIDWhenDemoting" [options]="targetStatuses"
            optionLabel="text" optionValue="value" />
        </p>
        <hr />
      </div>
    </div>
    }}


    <p class="actions">
      <button type="button" (click)="save()" class="btn btn-primary">Save</button>
    </p>
  </div>
  <div *ngIf="!hasCustomMaintananceCriteria">
    <p>
      Using general settings for maintance criteria.<br>
      <button (click)="enableCustomMaintananceCriteria()" class="btn btn-secondary">Enable Custom Maintance Criteria</button>
    </p>
  </div>
</div>