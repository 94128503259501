<h2 class="form-title">{{ titleName }}</h2>
<div *ngIf="loading">
  <app-loading-indicator></app-loading-indicator>
</div>
<form (ngSubmit)="onSubmit()" *ngIf="promptCodeToEdit">
  <div class="control">
    <label for="text">Text</label>
    <input class="form-control" type="text" id="text" name="text" [(ngModel)]="promptCodeToEdit!.Text" />
  </div>
  <div class="control">
    <label for="color">Color</label>
    <input class="form-control" type="color" id="color" name="color" [(ngModel)]="promptCodeToEdit!.Color" />
  </div>
  <div class="control">
    <label for="abbreviation">Abbreviation</label>
    <input class="form-control" type="text" id="abbreviation" name="abbreviation" [(ngModel)]="promptCodeToEdit!.Abbreviation" />
  </div>
  <div class="control">
    <label for="isIndependent">Is Independent</label>
    <input type="checkbox" id="isIndependent" name="isIndependent" [(ngModel)]="promptCodeToEdit!.IsIndependent" />
  </div>
  <div class="control">
    <label for="targetTypes">Target Types</label>
    <p-multiSelect [options]="targetTypes" [(ngModel)]="this.targetTypesSelected" optionLabel="label"
      [filter]="true" [showClear]="true" [placeholder]="'Select Target Types'" [filterBy]="'label'"
      [style]="{'width': '100%'}" [panelStyle]="{'width': '100%'}" appendTo="body"
      id="targetTypes" name="targetTypes">
    </p-multiSelect>
  </div>

  <div class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit">{{ actionName }}</button>
  </div>
</form>