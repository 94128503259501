/* tslint:disable */
/* eslint-disable */
export enum PatientTargetStatus {
  Unspecified = 0,
  Ready = 1,
  Probe = 2,
  Failed_Probe = 3,
  Aquisition = 4,
  Mastered = 5,
  Maintenance = 6,
  Failed_Maintenance = 7,
  Closed = 8
}
