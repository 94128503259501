<div #mainBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="fluencyrate-box pointer"
(clickOutside)="onClickOutside()"
[additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select" (mousedown)="startLongPress()" (mouseup)="endLongPress()"
        (mouseleave)="endLongPress()" (touchstart)="startLongPress()" (touchend)="endLongPress()"
        (touchcancel)="endLongPress()">

        <span class="badge badge-left badge-big badge-green" *ngIf="fluencies.length>0">{{lastFluency()}}</span>
        <div class="duration-container">
            <div class="duration-controls">
                <button (click)="startTimer()" class="duration-button start-button">{{timerString()}}</button>
                <button *ngIf="isStarted" (click)="stopTimer()" class="duration-button stop-button">
                    Stop
                </button>
            </div>
            <div class="flag-text">{{ text }}</div>
        </div>
        <span class="badge-count" [class.badge-count-green]="fluencies.length>=expectedAmount" [class.badge-count-red]="fluencies.length<expectedAmount">{{fluencies.length}}/{{expectedAmount}}</span>
       
        <!-- <button class="badge-bottom-left">?</button> -->
    </div>
</div>

<div class="extra-container" #extraBox [class.hide]="!extraOpened">
    <div class="extra-container-textbox">
    <div class="score-buttons">
        <button class="incorrect-button" (click)="incrementIncorrect()">
            <span class="button-count">{{ totalIncorrect }}</span>
            Incorrect
        </button>
        <button class="correct-button" (click)="incrementCorrect()">
            <span class="button-count">{{ totalCorrect }}</span>
            Correct
        </button>
    </div>
    </div>
</div>

<div class="extra-container" #extraBox2 [class.hide]="!extra2Opened">
    <div class="extra-close" (click)="closeExtra2()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let fluency of fluencies">
           {{fluency.rate.toFixed(0)}}% {{ fluency.date }}
           <div class="score-buttons m-0">
                <button class="incorrect-button" >
                    <span class="button-count">{{ fluency.totalIncorrect }}</span>
                    Incorrect
                </button>
                <button class="correct-button" >
                    <span class="button-count">{{ fluency.totalCorrect }}</span>
                    Correct
                </button>
            </div>
        </div>
    </div>
</div>