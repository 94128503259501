<div #mainBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="anecdotal-box"
(clickOutside)="onClickOutside()"
[additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select"  >
        {{text}}
        <button class="button-anecdotal" (click)="addOrOpenAnecdotal()">{{anecdotals.length>0?'View':'Add'}} Anecdotal Data</button>
        
        <span  class="badge-count" [class.badge-count-green]="anecdotals.length>=expectedAmount" [class.badge-count-red]="anecdotals.length<expectedAmount">{{anecdotals.length}}/{{expectedAmount}}</span>
        
    </div>
</div>

<div class="extra-container" #extraBox [class.hide]="!extraOpened">
    <div class="extra-close" (click)="closeExtra()">X</div>
    <div class="extra-container-textbox">
    <textarea 
      class="anecdotal-textarea"
      rows="8"
      placeholder="Enter anecdotal data here..."
      [ngModel]="currentAnecdotal().text" (ngModelChange)="saveAnecdotal($event)"
    ></textarea>
    <button class="button-anecdotal orange" (click)="addAnecdotal()">Add new Anecdotal</button>
    </div>

    <div class="anecdotal-list">
        <div *ngFor="let anecdotal of anecdotals; let i = index" class="anecdotal-item pointer" (click)="selectAnecdotal(i)">
            <div class="anecdotal-date">{{ anecdotal.date }}</div>
            <div class="anecdotal-text" title="{{ anecdotal.text }}">{{ anecdotal.text }}</div>
        </div>
    </div>
</div>